/*!
 * Studio v2.0.0 (https://seantheme.com/studio/)
 * Copyright 2020 SeanTheme
 */
/*!
 * Bootstrap v5.0.0-beta2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
    --bs-blue: #1f6bff;
    --bs-indigo: #640DF3;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #e6180d;
    --bs-orange: #FF9500;
    --bs-yellow: #FFCC00;
    --bs-green: #028824;
    --bs-teal: #20c997;
    --bs-cyan: #30beff;
    --bs-white: #FFFFFF;
    --bs-gray: #657eae;
    --bs-gray-dark: #3c4e71;
    --bs-primary: #1f6bff;
    --bs-secondary: #c9d2e3;
    --bs-warning: #FF9500;
    --bs-danger: #e6180d;
    --bs-success: #028824;
    --bs-info: #30beff;
    --bs-dark: #212837;
    --bs-black: #000000;
    --bs-white: #FFFFFF;
    --bs-purple: #6f42c1;
    --bs-indigo: #640DF3;
    --bs-pink: #d63384;
    --bs-yellow: #FFCC00;
    --bs-teal: #20c997;
    --bs-muted: #869ac0;
    --bs-light: #ebeef4;
    --bs-gray-100: #ebeef4;
    --bs-gray-200: #dae0ec;
    --bs-gray-300: #c9d2e3;
    --bs-gray-400: #a8b6d1;
    --bs-gray-500: #869ac0;
    --bs-gray-600: #657eae;
    --bs-gray-700: #4d6593;
    --bs-gray-800: #3c4e71;
    --bs-gray-900: #212837;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))
}

*,
::after,
::before {
    box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212837;
    background-color: #ebeef4;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25
}

hr:not([size]) {
    height: 1px
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 600;
    line-height: 1.2
}

.h1,
h1 {
    font-size: calc(1.34375rem + 1.125vw)
}

@media (min-width:1200px) {

    .h1,
    h1 {
        font-size: 2.1875rem
    }
}

.h2,
h2 {
    font-size: calc(1.3rem + .6vw)
}

@media (min-width:1200px) {

    .h2,
    h2 {
        font-size: 1.75rem
    }
}

.h3,
h3 {
    font-size: calc(1.27812rem + .3375vw)
}

@media (min-width:1200px) {

    .h3,
    h3 {
        font-size: 1.53125rem
    }
}

.h4,
h4 {
    font-size: calc(1.25625rem + .075vw)
}

@media (min-width:1200px) {

    .h4,
    h4 {
        font-size: 1.3125rem
    }
}

.h5,
h5 {
    font-size: 1.09375rem
}

.h6,
h6 {
    font-size: .875rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-bs-original-title],
abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,
ul {
    padding-left: 2rem
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

.small,
small {
    font-size: .875em
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

sub,
sup {
    position: relative;
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #1f6bff;
    text-decoration: underline
}

a:hover {
    color: #1956cc
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,
kbd,
pre,
samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr;
    unicode-bidi: bidi-override
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: .875em
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: .875em;
    color: #d63384;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: .875em;
    color: #fff;
    background-color: #212837;
    border-radius: 3px
}

kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700
}

figure {
    margin: 0 0 1rem
}

img,
svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: #869ac0;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

[list]::-webkit-calendar-picker-indicator {
    display: none
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + .3vw);
    line-height: inherit
}

@media (min-width:1200px) {
    legend {
        font-size: 1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::file-selector-button {
    font: inherit
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none !important
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-1 {
        font-size: 5rem
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-2 {
        font-size: 4.5rem
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-3 {
        font-size: 4rem
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-4 {
        font-size: 3.5rem
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-5 {
        font-size: 3rem
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2
}

@media (min-width:1200px) {
    .display-6 {
        font-size: 2.5rem
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: .875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: #657eae
}

.blockquote-footer::before {
    content: "\2014\00A0"
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #ebeef4;
    border: 1px solid #c9d2e3;
    border-radius: 6px;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: .875em;
    color: #657eae
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, 8px);
    padding-left: var(--bs-gutter-x, 8px);
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {

    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px
    }
}

@media (min-width:1660px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1320px
    }
}

.row {
    --bs-gutter-x: 16px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x)/ -2);
    margin-left: calc(var(--bs-gutter-x)/ -2)
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)/ 2);
    padding-left: calc(var(--bs-gutter-x)/ 2);
    margin-top: var(--bs-gutter-y)
}

.col {
    flex: 1 0 0%
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.33333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.66667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333%
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66667%
}

.col-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333%
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66667%
}

.col-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

.g-0,
.gx-0 {
    --bs-gutter-x: 0
}

.g-0,
.gy-0 {
    --bs-gutter-y: 0
}

.g-1,
.gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1,
.gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2,
.gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2,
.gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3,
.gx-3 {
    --bs-gutter-x: 1rem
}

.g-3,
.gy-3 {
    --bs-gutter-y: 1rem
}

.g-4,
.gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4,
.gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5,
.gx-5 {
    --bs-gutter-x: 3rem
}

.g-5,
.gy-5 {
    --bs-gutter-y: 3rem
}

@media (min-width:576px) {
    .col-sm {
        flex: 1 0 0%
    }

    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333%
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66667%
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333%
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66667%
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333%
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66667%
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333%
    }

    .offset-sm-2 {
        margin-left: 16.66667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333%
    }

    .offset-sm-5 {
        margin-left: 41.66667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333%
    }

    .offset-sm-8 {
        margin-left: 66.66667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333%
    }

    .offset-sm-11 {
        margin-left: 91.66667%
    }

    .g-sm-0,
    .gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0,
    .gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1,
    .gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1,
    .gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2,
    .gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2,
    .gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3,
    .gx-sm-3 {
        --bs-gutter-x: 1rem
    }

    .g-sm-3,
    .gy-sm-3 {
        --bs-gutter-y: 1rem
    }

    .g-sm-4,
    .gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-4,
    .gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-5,
    .gx-sm-5 {
        --bs-gutter-x: 3rem
    }

    .g-sm-5,
    .gy-sm-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:768px) {
    .col-md {
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333%
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66667%
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333%
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66667%
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333%
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66667%
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333%
    }

    .offset-md-2 {
        margin-left: 16.66667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333%
    }

    .offset-md-5 {
        margin-left: 41.66667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333%
    }

    .offset-md-8 {
        margin-left: 66.66667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333%
    }

    .offset-md-11 {
        margin-left: 91.66667%
    }

    .g-md-0,
    .gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0,
    .gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1,
    .gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1,
    .gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2,
    .gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2,
    .gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3,
    .gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3,
    .gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4,
    .gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4,
    .gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5,
    .gx-md-5 {
        --bs-gutter-x: 3rem
    }

    .g-md-5,
    .gy-md-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:992px) {
    .col-lg {
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333%
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66667%
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333%
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66667%
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333%
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66667%
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333%
    }

    .offset-lg-2 {
        margin-left: 16.66667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333%
    }

    .offset-lg-5 {
        margin-left: 41.66667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333%
    }

    .offset-lg-8 {
        margin-left: 66.66667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333%
    }

    .offset-lg-11 {
        margin-left: 91.66667%
    }

    .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333%
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66667%
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333%
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66667%
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333%
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66667%
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333%
    }

    .offset-xl-2 {
        margin-left: 16.66667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333%
    }

    .offset-xl-5 {
        margin-left: 41.66667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333%
    }

    .offset-xl-8 {
        margin-left: 66.66667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333%
    }

    .offset-xl-11 {
        margin-left: 91.66667%
    }

    .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1660px) {
    .col-xxl {
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333%
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66667%
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333%
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66667%
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333%
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66667%
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333%
    }

    .offset-xxl-2 {
        margin-left: 16.66667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333%
    }

    .offset-xxl-5 {
        margin-left: 41.66667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333%
    }

    .offset-xxl-8 {
        margin-left: 66.66667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333%
    }

    .offset-xxl-11 {
        margin-left: 91.66667%
    }

    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1900px) {
    .col-xxxl {
        flex: 1 0 0%
    }

    .row-cols-xxxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxxl-3>* {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .row-cols-xxxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxxl-6>* {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-xxxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxxl-1 {
        flex: 0 0 auto;
        width: 8.33333%
    }

    .col-xxxl-2 {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-xxxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxxl-4 {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .col-xxxl-5 {
        flex: 0 0 auto;
        width: 41.66667%
    }

    .col-xxxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxxl-7 {
        flex: 0 0 auto;
        width: 58.33333%
    }

    .col-xxxl-8 {
        flex: 0 0 auto;
        width: 66.66667%
    }

    .col-xxxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxxl-10 {
        flex: 0 0 auto;
        width: 83.33333%
    }

    .col-xxxl-11 {
        flex: 0 0 auto;
        width: 91.66667%
    }

    .col-xxxl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxxl-0 {
        margin-left: 0
    }

    .offset-xxxl-1 {
        margin-left: 8.33333%
    }

    .offset-xxxl-2 {
        margin-left: 16.66667%
    }

    .offset-xxxl-3 {
        margin-left: 25%
    }

    .offset-xxxl-4 {
        margin-left: 33.33333%
    }

    .offset-xxxl-5 {
        margin-left: 41.66667%
    }

    .offset-xxxl-6 {
        margin-left: 50%
    }

    .offset-xxxl-7 {
        margin-left: 58.33333%
    }

    .offset-xxxl-8 {
        margin-left: 66.66667%
    }

    .offset-xxxl-9 {
        margin-left: 75%
    }

    .offset-xxxl-10 {
        margin-left: 83.33333%
    }

    .offset-xxxl-11 {
        margin-left: 91.66667%
    }

    .g-xxxl-0,
    .gx-xxxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxxl-0,
    .gy-xxxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxxl-1,
    .gx-xxxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxxl-1,
    .gy-xxxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxxl-2,
    .gx-xxxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxxl-2,
    .gy-xxxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxxl-3,
    .gx-xxxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxxl-3,
    .gy-xxxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxxl-4,
    .gx-xxxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxxl-4,
    .gy-xxxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxxl-5,
    .gx-xxxl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xxxl-5,
    .gy-xxxl-5 {
        --bs-gutter-y: 3rem
    }
}

.table {
    --bs-table-bg: transparent;
    --bs-table-striped-color: #212837;
    --bs-table-striped-bg: rgba(77, 101, 147, 0.1);
    --bs-table-active-color: #212837;
    --bs-table-active-bg: #ebeef4;
    --bs-table-hover-color: #212837;
    --bs-table-hover-bg: #ebeef4;
    width: 100%;
    margin-bottom: 1rem;
    color: #212837;
    vertical-align: top;
    border-color: #dae0ec
}

.table>:not(caption)>*>* {
    padding: .5rem .5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: currentColor
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .25rem .25rem
}

.table-bordered>:not(caption)>* {
    border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}

.table-primary {
    --bs-table-bg: #d2e1ff;
    --bs-table-striped-bg: #bdcbe6;
    --bs-table-striped-color: #000000;
    --bs-table-active-bg: #bdcbe6;
    --bs-table-active-color: #000000;
    --bs-table-hover-bg: #c2d0ec;
    --bs-table-hover-color: #000000;
    color: #000;
    border-color: #bdcbe6
}

.table-secondary {
    --bs-table-bg: #f4f6f9;
    --bs-table-striped-bg: #dcdde0;
    --bs-table-striped-color: #000000;
    --bs-table-active-bg: #dcdde0;
    --bs-table-active-color: #000000;
    --bs-table-hover-bg: #e2e4e6;
    --bs-table-hover-color: #000000;
    color: #000;
    border-color: #dcdde0
}

.table-success {
    --bs-table-bg: #cce7d3;
    --bs-table-striped-bg: #b8d0be;
    --bs-table-striped-color: #000000;
    --bs-table-active-bg: #b8d0be;
    --bs-table-active-color: #000000;
    --bs-table-hover-bg: #bdd6c3;
    --bs-table-hover-color: #000000;
    color: #000;
    border-color: #b8d0be
}

.table-info {
    --bs-table-bg: #d6f2ff;
    --bs-table-striped-bg: #c1dae6;
    --bs-table-striped-color: #000000;
    --bs-table-active-bg: #c1dae6;
    --bs-table-active-color: #000000;
    --bs-table-hover-bg: #c6e0ec;
    --bs-table-hover-color: #000000;
    color: #000;
    border-color: #c1dae6
}

.table-warning {
    --bs-table-bg: #ffeacc;
    --bs-table-striped-bg: #e6d3b8;
    --bs-table-striped-color: #000000;
    --bs-table-active-bg: #e6d3b8;
    --bs-table-active-color: #000000;
    --bs-table-hover-bg: #ecd8bd;
    --bs-table-hover-color: #000000;
    color: #000;
    border-color: #e6d3b8
}

.table-danger {
    --bs-table-bg: #fad1cf;
    --bs-table-striped-bg: #e1bcba;
    --bs-table-striped-color: #000000;
    --bs-table-active-bg: #e1bcba;
    --bs-table-active-color: #000000;
    --bs-table-hover-bg: #e7c1bf;
    --bs-table-hover-color: #000000;
    color: #000;
    border-color: #e1bcba
}

.table-light {
    --bs-table-bg: #ebeef4;
    --bs-table-striped-bg: #d4d6dc;
    --bs-table-striped-color: #000000;
    --bs-table-active-bg: #d4d6dc;
    --bs-table-active-color: #000000;
    --bs-table-hover-bg: #d9dce2;
    --bs-table-hover-color: #000000;
    color: #000;
    border-color: #d4d6dc
}

.table-dark {
    --bs-table-bg: #212837;
    --bs-table-striped-bg: #373e4b;
    --bs-table-striped-color: #FFFFFF;
    --bs-table-active-bg: #373e4b;
    --bs-table-active-color: #FFFFFF;
    --bs-table-hover-bg: #323846;
    --bs-table-hover-color: #FFFFFF;
    color: #fff;
    border-color: #373e4b
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1659.98px) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width:1899.98px) {
    .table-responsive-xxxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: .5rem
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1.09375rem
}

.col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .76562rem
}

.form-text {
    margin-top: .25rem;
    font-size: .875em;
    color: #869ac0
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3c4e71;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c9d2e3;
    appearance: none;
    border-radius: 6px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    color: #3c4e71;
    background-color: #fff;
    border-color: #528dff;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(31, 107, 255, .25)
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::placeholder {
    color: #869ac0;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #dae0ec;
    opacity: 1
}

.form-control::file-selector-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    margin-inline-end: .75rem;
    color: #3c4e71;
    background-color: #dae0ec;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #cfd5e0
}

.form-control::-webkit-file-upload-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    margin-inline-end: .75rem;
    color: #3c4e71;
    background-color: #dae0ec;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #cfd5e0
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212837;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .76562rem;
    border-radius: 3px
}

.form-control-sm::file-selector-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    margin-inline-end: .5rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    margin-inline-end: .5rem
}

.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.09375rem;
    border-radius: 9px
}

.form-control-lg::file-selector-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    margin-inline-end: 1rem
}

.form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    margin-inline-end: 1rem
}

textarea.form-control {
    min-height: calc(1.5em + .75rem + 2px)
}

textarea.form-control-sm {
    min-height: calc(1.5em + .5rem + 2px)
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px)
}

.form-control-color {
    max-width: 3rem;
    height: auto;
    padding: .375rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 6px
}

.form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 6px
}

.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3c4e71;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%233c4e71' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #c9d2e3;
    border-radius: 6px;
    appearance: none
}

.form-select:focus {
    border-color: #528dff;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(31, 107, 255, .25)
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    padding-right: .75rem;
    background-image: none
}

.form-select:disabled {
    color: #657eae;
    background-color: #dae0ec
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #3c4e71
}

.form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .76562rem
}

.form-select-lg {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1.09375rem
}

.form-check {
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.5em;
    margin-bottom: .125rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em
}

.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, .25);
    appearance: none;
    color-adjust: exact
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: #528dff;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(31, 107, 255, .25)
}

.form-check-input:checked {
    background-color: #1f6bff;
    border-color: #1f6bff
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #1f6bff;
    border-color: #1f6bff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: .5
}

.form-switch {
    padding-left: 2.5em
}

.form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23528dff'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e")
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.btn-check:disabled+.btn,
.btn-check[disabled]+.btn {
    pointer-events: none;
    filter: none;
    opacity: .65
}

.form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #ebeef4, 0 0 0 .25rem rgba(31, 107, 255, .25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #ebeef4, 0 0 0 .25rem rgba(31, 107, 255, .25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #1f6bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #bcd3ff
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #c9d2e3;
    border-color: transparent;
    border-radius: 1rem
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #1f6bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #bcd3ff
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #c9d2e3;
    border-color: transparent;
    border-radius: 1rem
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #869ac0
}

.form-range:disabled::-moz-range-thumb {
    background-color: #869ac0
}

.form-floating {
    position: relative
}

.form-floating>.form-control,
.form-floating>.form-select {
    height: calc(3.5rem + 2px);
    padding: 1rem .75rem
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem .75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,
.input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
    z-index: 3
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 3
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3c4e71;
    text-align: center;
    white-space: nowrap;
    background-color: #dae0ec;
    border: 1px solid #c9d2e3;
    border-radius: 6px
}

.input-group-lg>.btn,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text {
    padding: .5rem 1rem;
    font-size: 1.09375rem;
    border-radius: 9px
}

.input-group-sm>.btn,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text {
    padding: .25rem .5rem;
    font-size: .76562rem;
    border-radius: 3px
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
    padding-right: 3rem
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #028824
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    color: #fff;
    background-color: rgba(2, 136, 36, .9);
    border-radius: 6px
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #028824;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23028824' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #028824;
    box-shadow: 0 0 0 .25rem rgba(2, 136, 36, .25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-select.is-valid,
.was-validated .form-select:valid {
    border-color: #028824;
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%233c4e71' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23028824' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
    border-color: #028824;
    box-shadow: 0 0 0 .25rem rgba(2, 136, 36, .25)
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
    border-color: #028824
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
    background-color: #028824
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 .25rem rgba(2, 136, 36, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #028824
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #e6180d
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .75rem;
    color: #fff;
    background-color: rgba(230, 24, 13, .9);
    border-radius: 6px
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #e6180d;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e6180d'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e6180d' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #e6180d;
    box-shadow: 0 0 0 .25rem rgba(230, 24, 13, .25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
    border-color: #e6180d;
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%233c4e71' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e6180d'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e6180d' stroke='none'/%3e%3c/svg%3e");
    background-position: right .75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
    border-color: #e6180d;
    box-shadow: 0 0 0 .25rem rgba(230, 24, 13, .25)
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
    border-color: #e6180d
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
    background-color: #e6180d
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 .25rem rgba(230, 24, 13, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #e6180d
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212837;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: .875rem;
    border-radius: 6px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #212837
}

.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(31, 107, 255, .25)
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    pointer-events: none;
    opacity: .65
}

.btn-primary {
    color: #fff;
    background-color: #1f6bff;
    border-color: #1f6bff
}

.btn-primary:hover {
    color: #fff;
    background-color: #1a5bd9;
    border-color: #1956cc
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: #1a5bd9;
    border-color: #1956cc;
    box-shadow: 0 0 0 .25rem rgba(65, 129, 255, .5)
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1956cc;
    border-color: #1750bf
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(65, 129, 255, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #1f6bff;
    border-color: #1f6bff
}

.btn-secondary {
    color: #000;
    background-color: #c9d2e3;
    border-color: #c9d2e3
}

.btn-secondary:hover {
    color: #000;
    background-color: #d1d9e7;
    border-color: #ced7e6
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
    color: #000;
    background-color: #d1d9e7;
    border-color: #ced7e6;
    box-shadow: 0 0 0 .25rem rgba(171, 179, 193, .5)
}

.btn-check:active+.btn-secondary,
.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show>.btn-secondary.dropdown-toggle {
    color: #000;
    background-color: #d4dbe9;
    border-color: #ced7e6
}

.btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(171, 179, 193, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #000;
    background-color: #c9d2e3;
    border-color: #c9d2e3
}

.btn-warning {
    color: #000;
    background-color: #ff9500;
    border-color: #ff9500
}

.btn-warning:hover {
    color: #000;
    background-color: #ffa526;
    border-color: #ffa01a
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
    color: #000;
    background-color: #ffa526;
    border-color: #ffa01a;
    box-shadow: 0 0 0 .25rem rgba(217, 127, 0, .5)
}

.btn-check:active+.btn-warning,
.btn-check:checked+.btn-warning,
.btn-warning.active,
.btn-warning:active,
.show>.btn-warning.dropdown-toggle {
    color: #000;
    background-color: #fa3;
    border-color: #ffa01a
}

.btn-check:active+.btn-warning:focus,
.btn-check:checked+.btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(217, 127, 0, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #000;
    background-color: #ff9500;
    border-color: #ff9500
}

.btn-danger {
    color: #fff;
    background-color: #e6180d;
    border-color: #e6180d
}

.btn-danger:hover {
    color: #fff;
    background-color: #c4140b;
    border-color: #b8130a
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
    color: #fff;
    background-color: #c4140b;
    border-color: #b8130a;
    box-shadow: 0 0 0 .25rem rgba(234, 59, 49, .5)
}

.btn-check:active+.btn-danger,
.btn-check:checked+.btn-danger,
.btn-danger.active,
.btn-danger:active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b8130a;
    border-color: #ad120a
}

.btn-check:active+.btn-danger:focus,
.btn-check:checked+.btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(234, 59, 49, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #e6180d;
    border-color: #e6180d
}

.btn-success {
    color: #fff;
    background-color: #028824;
    border-color: #028824
}

.btn-success:hover {
    color: #fff;
    background-color: #02741f;
    border-color: #026d1d
}

.btn-check:focus+.btn-success,
.btn-success:focus {
    color: #fff;
    background-color: #02741f;
    border-color: #026d1d;
    box-shadow: 0 0 0 .25rem rgba(40, 154, 69, .5)
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #026d1d;
    border-color: #02661b
}

.btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(40, 154, 69, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #028824;
    border-color: #028824
}

.btn-info {
    color: #000;
    background-color: #30beff;
    border-color: #30beff
}

.btn-info:hover {
    color: #000;
    background-color: #4fc8ff;
    border-color: #45c5ff
}

.btn-check:focus+.btn-info,
.btn-info:focus {
    color: #000;
    background-color: #4fc8ff;
    border-color: #45c5ff;
    box-shadow: 0 0 0 .25rem rgba(41, 162, 217, .5)
}

.btn-check:active+.btn-info,
.btn-check:checked+.btn-info,
.btn-info.active,
.btn-info:active,
.show>.btn-info.dropdown-toggle {
    color: #000;
    background-color: #59cbff;
    border-color: #45c5ff
}

.btn-check:active+.btn-info:focus,
.btn-check:checked+.btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(41, 162, 217, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #000;
    background-color: #30beff;
    border-color: #30beff
}

.btn-dark {
    color: #fff;
    background-color: #212837;
    border-color: #212837
}

.btn-dark:hover {
    color: #fff;
    background-color: #1c222f;
    border-color: #1a202c
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
    color: #fff;
    background-color: #1c222f;
    border-color: #1a202c;
    box-shadow: 0 0 0 .25rem rgba(66, 72, 85, .5)
}

.btn-check:active+.btn-dark,
.btn-check:checked+.btn-dark,
.btn-dark.active,
.btn-dark:active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a202c;
    border-color: #191e29
}

.btn-check:active+.btn-dark:focus,
.btn-check:checked+.btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(66, 72, 85, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #212837;
    border-color: #212837
}

.btn-black {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-black:hover {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-black:focus,
.btn-check:focus+.btn-black {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: 0 0 0 .25rem rgba(38, 38, 38, .5)
}

.btn-black.active,
.btn-black:active,
.btn-check:active+.btn-black,
.btn-check:checked+.btn-black,
.show>.btn-black.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-black.active:focus,
.btn-black:active:focus,
.btn-check:active+.btn-black:focus,
.btn-check:checked+.btn-black:focus,
.show>.btn-black.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(38, 38, 38, .5)
}

.btn-black.disabled,
.btn-black:disabled {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-white {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-white:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-check:focus+.btn-white,
.btn-white:focus {
    color: #000;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 .25rem rgba(217, 217, 217, .5)
}

.btn-check:active+.btn-white,
.btn-check:checked+.btn-white,
.btn-white.active,
.btn-white:active,
.show>.btn-white.dropdown-toggle {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-check:active+.btn-white:focus,
.btn-check:checked+.btn-white:focus,
.btn-white.active:focus,
.btn-white:active:focus,
.show>.btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(217, 217, 217, .5)
}

.btn-white.disabled,
.btn-white:disabled {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-purple {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1
}

.btn-purple:hover {
    color: #fff;
    background-color: #5e38a4;
    border-color: #59359a
}

.btn-check:focus+.btn-purple,
.btn-purple:focus {
    color: #fff;
    background-color: #5e38a4;
    border-color: #59359a;
    box-shadow: 0 0 0 .25rem rgba(133, 94, 202, .5)
}

.btn-check:active+.btn-purple,
.btn-check:checked+.btn-purple,
.btn-purple.active,
.btn-purple:active,
.show>.btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #59359a;
    border-color: #533291
}

.btn-check:active+.btn-purple:focus,
.btn-check:checked+.btn-purple:focus,
.btn-purple.active:focus,
.btn-purple:active:focus,
.show>.btn-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(133, 94, 202, .5)
}

.btn-purple.disabled,
.btn-purple:disabled {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1
}

.btn-indigo {
    color: #fff;
    background-color: #640df3;
    border-color: #640df3
}

.btn-indigo:hover {
    color: #fff;
    background-color: #550bcf;
    border-color: #500ac2
}

.btn-check:focus+.btn-indigo,
.btn-indigo:focus {
    color: #fff;
    background-color: #550bcf;
    border-color: #500ac2;
    box-shadow: 0 0 0 .25rem rgba(123, 49, 245, .5)
}

.btn-check:active+.btn-indigo,
.btn-check:checked+.btn-indigo,
.btn-indigo.active,
.btn-indigo:active,
.show>.btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #500ac2;
    border-color: #4b0ab6
}

.btn-check:active+.btn-indigo:focus,
.btn-check:checked+.btn-indigo:focus,
.btn-indigo.active:focus,
.btn-indigo:active:focus,
.show>.btn-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(123, 49, 245, .5)
}

.btn-indigo.disabled,
.btn-indigo:disabled {
    color: #fff;
    background-color: #640df3;
    border-color: #640df3
}

.btn-pink {
    color: #fff;
    background-color: #d63384;
    border-color: #d63384
}

.btn-pink:hover {
    color: #fff;
    background-color: #b62b70;
    border-color: #ab296a
}

.btn-check:focus+.btn-pink,
.btn-pink:focus {
    color: #fff;
    background-color: #b62b70;
    border-color: #ab296a;
    box-shadow: 0 0 0 .25rem rgba(220, 82, 150, .5)
}

.btn-check:active+.btn-pink,
.btn-check:checked+.btn-pink,
.btn-pink.active,
.btn-pink:active,
.show>.btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #ab296a;
    border-color: #a12663
}

.btn-check:active+.btn-pink:focus,
.btn-check:checked+.btn-pink:focus,
.btn-pink.active:focus,
.btn-pink:active:focus,
.show>.btn-pink.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(220, 82, 150, .5)
}

.btn-pink.disabled,
.btn-pink:disabled {
    color: #fff;
    background-color: #d63384;
    border-color: #d63384
}

.btn-yellow {
    color: #000;
    background-color: #fc0;
    border-color: #fc0
}

.btn-yellow:hover {
    color: #000;
    background-color: #ffd426;
    border-color: #ffd11a
}

.btn-check:focus+.btn-yellow,
.btn-yellow:focus {
    color: #000;
    background-color: #ffd426;
    border-color: #ffd11a;
    box-shadow: 0 0 0 .25rem rgba(217, 173, 0, .5)
}

.btn-check:active+.btn-yellow,
.btn-check:checked+.btn-yellow,
.btn-yellow.active,
.btn-yellow:active,
.show>.btn-yellow.dropdown-toggle {
    color: #000;
    background-color: #ffd633;
    border-color: #ffd11a
}

.btn-check:active+.btn-yellow:focus,
.btn-check:checked+.btn-yellow:focus,
.btn-yellow.active:focus,
.btn-yellow:active:focus,
.show>.btn-yellow.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(217, 173, 0, .5)
}

.btn-yellow.disabled,
.btn-yellow:disabled {
    color: #000;
    background-color: #fc0;
    border-color: #fc0
}

.btn-teal {
    color: #000;
    background-color: #20c997;
    border-color: #20c997
}

.btn-teal:hover {
    color: #000;
    background-color: #41d1a7;
    border-color: #36cea1
}

.btn-check:focus+.btn-teal,
.btn-teal:focus {
    color: #000;
    background-color: #41d1a7;
    border-color: #36cea1;
    box-shadow: 0 0 0 .25rem rgba(27, 171, 128, .5)
}

.btn-check:active+.btn-teal,
.btn-check:checked+.btn-teal,
.btn-teal.active,
.btn-teal:active,
.show>.btn-teal.dropdown-toggle {
    color: #000;
    background-color: #4dd4ac;
    border-color: #36cea1
}

.btn-check:active+.btn-teal:focus,
.btn-check:checked+.btn-teal:focus,
.btn-teal.active:focus,
.btn-teal:active:focus,
.show>.btn-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(27, 171, 128, .5)
}

.btn-teal.disabled,
.btn-teal:disabled {
    color: #000;
    background-color: #20c997;
    border-color: #20c997
}

.btn-muted {
    color: #000;
    background-color: #869ac0;
    border-color: #869ac0
}

.btn-muted:hover {
    color: #000;
    background-color: #98a9c9;
    border-color: #92a4c6
}

.btn-check:focus+.btn-muted,
.btn-muted:focus {
    color: #000;
    background-color: #98a9c9;
    border-color: #92a4c6;
    box-shadow: 0 0 0 .25rem rgba(114, 131, 163, .5)
}

.btn-check:active+.btn-muted,
.btn-check:checked+.btn-muted,
.btn-muted.active,
.btn-muted:active,
.show>.btn-muted.dropdown-toggle {
    color: #000;
    background-color: #9eaecd;
    border-color: #92a4c6
}

.btn-check:active+.btn-muted:focus,
.btn-check:checked+.btn-muted:focus,
.btn-muted.active:focus,
.btn-muted:active:focus,
.show>.btn-muted.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(114, 131, 163, .5)
}

.btn-muted.disabled,
.btn-muted:disabled {
    color: #000;
    background-color: #869ac0;
    border-color: #869ac0
}

.btn-light {
    color: #000;
    background-color: #ebeef4;
    border-color: #ebeef4
}

.btn-light:hover {
    color: #000;
    background-color: #eef1f6;
    border-color: #edf0f5
}

.btn-check:focus+.btn-light,
.btn-light:focus {
    color: #000;
    background-color: #eef1f6;
    border-color: #edf0f5;
    box-shadow: 0 0 0 .25rem rgba(200, 202, 207, .5)
}

.btn-check:active+.btn-light,
.btn-check:checked+.btn-light,
.btn-light.active,
.btn-light:active,
.show>.btn-light.dropdown-toggle {
    color: #000;
    background-color: #eff1f6;
    border-color: #edf0f5
}

.btn-check:active+.btn-light:focus,
.btn-check:checked+.btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(200, 202, 207, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    color: #000;
    background-color: #ebeef4;
    border-color: #ebeef4
}

.btn-gray-100 {
    color: #000;
    background-color: #ebeef4;
    border-color: #ebeef4
}

.btn-gray-100:hover {
    color: #000;
    background-color: #eef1f6;
    border-color: #edf0f5
}

.btn-check:focus+.btn-gray-100,
.btn-gray-100:focus {
    color: #000;
    background-color: #eef1f6;
    border-color: #edf0f5;
    box-shadow: 0 0 0 .25rem rgba(200, 202, 207, .5)
}

.btn-check:active+.btn-gray-100,
.btn-check:checked+.btn-gray-100,
.btn-gray-100.active,
.btn-gray-100:active,
.show>.btn-gray-100.dropdown-toggle {
    color: #000;
    background-color: #eff1f6;
    border-color: #edf0f5
}

.btn-check:active+.btn-gray-100:focus,
.btn-check:checked+.btn-gray-100:focus,
.btn-gray-100.active:focus,
.btn-gray-100:active:focus,
.show>.btn-gray-100.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(200, 202, 207, .5)
}

.btn-gray-100.disabled,
.btn-gray-100:disabled {
    color: #000;
    background-color: #ebeef4;
    border-color: #ebeef4
}

.btn-gray-200 {
    color: #000;
    background-color: #dae0ec;
    border-color: #dae0ec
}

.btn-gray-200:hover {
    color: #000;
    background-color: #e0e5ef;
    border-color: #dee3ee
}

.btn-check:focus+.btn-gray-200,
.btn-gray-200:focus {
    color: #000;
    background-color: #e0e5ef;
    border-color: #dee3ee;
    box-shadow: 0 0 0 .25rem rgba(185, 190, 201, .5)
}

.btn-check:active+.btn-gray-200,
.btn-check:checked+.btn-gray-200,
.btn-gray-200.active,
.btn-gray-200:active,
.show>.btn-gray-200.dropdown-toggle {
    color: #000;
    background-color: #e1e6f0;
    border-color: #dee3ee
}

.btn-check:active+.btn-gray-200:focus,
.btn-check:checked+.btn-gray-200:focus,
.btn-gray-200.active:focus,
.btn-gray-200:active:focus,
.show>.btn-gray-200.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(185, 190, 201, .5)
}

.btn-gray-200.disabled,
.btn-gray-200:disabled {
    color: #000;
    background-color: #dae0ec;
    border-color: #dae0ec
}

.btn-gray-300 {
    color: #000;
    background-color: #c9d2e3;
    border-color: #c9d2e3
}

.btn-gray-300:hover {
    color: #000;
    background-color: #d1d9e7;
    border-color: #ced7e6
}

.btn-check:focus+.btn-gray-300,
.btn-gray-300:focus {
    color: #000;
    background-color: #d1d9e7;
    border-color: #ced7e6;
    box-shadow: 0 0 0 .25rem rgba(171, 179, 193, .5)
}

.btn-check:active+.btn-gray-300,
.btn-check:checked+.btn-gray-300,
.btn-gray-300.active,
.btn-gray-300:active,
.show>.btn-gray-300.dropdown-toggle {
    color: #000;
    background-color: #d4dbe9;
    border-color: #ced7e6
}

.btn-check:active+.btn-gray-300:focus,
.btn-check:checked+.btn-gray-300:focus,
.btn-gray-300.active:focus,
.btn-gray-300:active:focus,
.show>.btn-gray-300.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(171, 179, 193, .5)
}

.btn-gray-300.disabled,
.btn-gray-300:disabled {
    color: #000;
    background-color: #c9d2e3;
    border-color: #c9d2e3
}

.btn-gray-400 {
    color: #000;
    background-color: #a8b6d1;
    border-color: #a8b6d1
}

.btn-gray-400:hover {
    color: #000;
    background-color: #b5c1d8;
    border-color: #b1bdd6
}

.btn-check:focus+.btn-gray-400,
.btn-gray-400:focus {
    color: #000;
    background-color: #b5c1d8;
    border-color: #b1bdd6;
    box-shadow: 0 0 0 .25rem rgba(143, 155, 178, .5)
}

.btn-check:active+.btn-gray-400,
.btn-check:checked+.btn-gray-400,
.btn-gray-400.active,
.btn-gray-400:active,
.show>.btn-gray-400.dropdown-toggle {
    color: #000;
    background-color: #b9c5da;
    border-color: #b1bdd6
}

.btn-check:active+.btn-gray-400:focus,
.btn-check:checked+.btn-gray-400:focus,
.btn-gray-400.active:focus,
.btn-gray-400:active:focus,
.show>.btn-gray-400.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(143, 155, 178, .5)
}

.btn-gray-400.disabled,
.btn-gray-400:disabled {
    color: #000;
    background-color: #a8b6d1;
    border-color: #a8b6d1
}

.btn-gray-500 {
    color: #000;
    background-color: #869ac0;
    border-color: #869ac0
}

.btn-gray-500:hover {
    color: #000;
    background-color: #98a9c9;
    border-color: #92a4c6
}

.btn-check:focus+.btn-gray-500,
.btn-gray-500:focus {
    color: #000;
    background-color: #98a9c9;
    border-color: #92a4c6;
    box-shadow: 0 0 0 .25rem rgba(114, 131, 163, .5)
}

.btn-check:active+.btn-gray-500,
.btn-check:checked+.btn-gray-500,
.btn-gray-500.active,
.btn-gray-500:active,
.show>.btn-gray-500.dropdown-toggle {
    color: #000;
    background-color: #9eaecd;
    border-color: #92a4c6
}

.btn-check:active+.btn-gray-500:focus,
.btn-check:checked+.btn-gray-500:focus,
.btn-gray-500.active:focus,
.btn-gray-500:active:focus,
.show>.btn-gray-500.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(114, 131, 163, .5)
}

.btn-gray-500.disabled,
.btn-gray-500:disabled {
    color: #000;
    background-color: #869ac0;
    border-color: #869ac0
}

.btn-gray-600 {
    color: #000;
    background-color: #657eae;
    border-color: #657eae
}

.btn-gray-600:hover {
    color: #000;
    background-color: #7c91ba;
    border-color: #748bb6
}

.btn-check:focus+.btn-gray-600,
.btn-gray-600:focus {
    color: #000;
    background-color: #7c91ba;
    border-color: #748bb6;
    box-shadow: 0 0 0 .25rem rgba(86, 107, 148, .5)
}

.btn-check:active+.btn-gray-600,
.btn-check:checked+.btn-gray-600,
.btn-gray-600.active,
.btn-gray-600:active,
.show>.btn-gray-600.dropdown-toggle {
    color: #000;
    background-color: #8498be;
    border-color: #748bb6
}

.btn-check:active+.btn-gray-600:focus,
.btn-check:checked+.btn-gray-600:focus,
.btn-gray-600.active:focus,
.btn-gray-600:active:focus,
.show>.btn-gray-600.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(86, 107, 148, .5)
}

.btn-gray-600.disabled,
.btn-gray-600:disabled {
    color: #000;
    background-color: #657eae;
    border-color: #657eae
}

.btn-gray-700 {
    color: #fff;
    background-color: #4d6593;
    border-color: #4d6593
}

.btn-gray-700:hover {
    color: #fff;
    background-color: #41567d;
    border-color: #3e5176
}

.btn-check:focus+.btn-gray-700,
.btn-gray-700:focus {
    color: #fff;
    background-color: #41567d;
    border-color: #3e5176;
    box-shadow: 0 0 0 .25rem rgba(104, 124, 163, .5)
}

.btn-check:active+.btn-gray-700,
.btn-check:checked+.btn-gray-700,
.btn-gray-700.active,
.btn-gray-700:active,
.show>.btn-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #3e5176;
    border-color: #3a4c6e
}

.btn-check:active+.btn-gray-700:focus,
.btn-check:checked+.btn-gray-700:focus,
.btn-gray-700.active:focus,
.btn-gray-700:active:focus,
.show>.btn-gray-700.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(104, 124, 163, .5)
}

.btn-gray-700.disabled,
.btn-gray-700:disabled {
    color: #fff;
    background-color: #4d6593;
    border-color: #4d6593
}

.btn-gray-800 {
    color: #fff;
    background-color: #3c4e71;
    border-color: #3c4e71
}

.btn-gray-800:hover {
    color: #fff;
    background-color: #334260;
    border-color: #303e5a
}

.btn-check:focus+.btn-gray-800,
.btn-gray-800:focus {
    color: #fff;
    background-color: #334260;
    border-color: #303e5a;
    box-shadow: 0 0 0 .25rem rgba(89, 105, 134, .5)
}

.btn-check:active+.btn-gray-800,
.btn-check:checked+.btn-gray-800,
.btn-gray-800.active,
.btn-gray-800:active,
.show>.btn-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #303e5a;
    border-color: #2d3b55
}

.btn-check:active+.btn-gray-800:focus,
.btn-check:checked+.btn-gray-800:focus,
.btn-gray-800.active:focus,
.btn-gray-800:active:focus,
.show>.btn-gray-800.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(89, 105, 134, .5)
}

.btn-gray-800.disabled,
.btn-gray-800:disabled {
    color: #fff;
    background-color: #3c4e71;
    border-color: #3c4e71
}

.btn-gray-900 {
    color: #fff;
    background-color: #212837;
    border-color: #212837
}

.btn-gray-900:hover {
    color: #fff;
    background-color: #1c222f;
    border-color: #1a202c
}

.btn-check:focus+.btn-gray-900,
.btn-gray-900:focus {
    color: #fff;
    background-color: #1c222f;
    border-color: #1a202c;
    box-shadow: 0 0 0 .25rem rgba(66, 72, 85, .5)
}

.btn-check:active+.btn-gray-900,
.btn-check:checked+.btn-gray-900,
.btn-gray-900.active,
.btn-gray-900:active,
.show>.btn-gray-900.dropdown-toggle {
    color: #fff;
    background-color: #1a202c;
    border-color: #191e29
}

.btn-check:active+.btn-gray-900:focus,
.btn-check:checked+.btn-gray-900:focus,
.btn-gray-900.active:focus,
.btn-gray-900:active:focus,
.show>.btn-gray-900.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(66, 72, 85, .5)
}

.btn-gray-900.disabled,
.btn-gray-900:disabled {
    color: #fff;
    background-color: #212837;
    border-color: #212837
}

.btn-outline-primary {
    color: #1f6bff;
    border-color: #1f6bff
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #1f6bff;
    border-color: #1f6bff
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .25rem rgba(31, 107, 255, .5)
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
    color: #fff;
    background-color: #1f6bff;
    border-color: #1f6bff
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
    box-shadow: 0 0 0 .25rem rgba(31, 107, 255, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #1f6bff;
    background-color: transparent
}

.btn-outline-secondary {
    color: #c9d2e3;
    border-color: #c9d2e3
}

.btn-outline-secondary:hover {
    color: #000;
    background-color: #c9d2e3;
    border-color: #c9d2e3
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .25rem rgba(201, 210, 227, .5)
}

.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
    color: #000;
    background-color: #c9d2e3;
    border-color: #c9d2e3
}

.btn-check:active+.btn-outline-secondary:focus,
.btn-check:checked+.btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
    box-shadow: 0 0 0 .25rem rgba(201, 210, 227, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #c9d2e3;
    background-color: transparent
}

.btn-outline-warning {
    color: #ff9500;
    border-color: #ff9500
}

.btn-outline-warning:hover {
    color: #000;
    background-color: #ff9500;
    border-color: #ff9500
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 149, 0, .5)
}

.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
    color: #000;
    background-color: #ff9500;
    border-color: #ff9500
}

.btn-check:active+.btn-outline-warning:focus,
.btn-check:checked+.btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 149, 0, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ff9500;
    background-color: transparent
}

.btn-outline-danger {
    color: #e6180d;
    border-color: #e6180d
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #e6180d;
    border-color: #e6180d
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .25rem rgba(230, 24, 13, .5)
}

.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
    color: #fff;
    background-color: #e6180d;
    border-color: #e6180d
}

.btn-check:active+.btn-outline-danger:focus,
.btn-check:checked+.btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
    box-shadow: 0 0 0 .25rem rgba(230, 24, 13, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #e6180d;
    background-color: transparent
}

.btn-outline-success {
    color: #028824;
    border-color: #028824
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #028824;
    border-color: #028824
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .25rem rgba(2, 136, 36, .5)
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
    color: #fff;
    background-color: #028824;
    border-color: #028824
}

.btn-check:active+.btn-outline-success:focus,
.btn-check:checked+.btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
    box-shadow: 0 0 0 .25rem rgba(2, 136, 36, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #028824;
    background-color: transparent
}

.btn-outline-info {
    color: #30beff;
    border-color: #30beff
}

.btn-outline-info:hover {
    color: #000;
    background-color: #30beff;
    border-color: #30beff
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .25rem rgba(48, 190, 255, .5)
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
    color: #000;
    background-color: #30beff;
    border-color: #30beff
}

.btn-check:active+.btn-outline-info:focus,
.btn-check:checked+.btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
    box-shadow: 0 0 0 .25rem rgba(48, 190, 255, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #30beff;
    background-color: transparent
}

.btn-outline-dark {
    color: #212837;
    border-color: #212837
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #212837;
    border-color: #212837
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 .25rem rgba(33, 40, 55, .5)
}

.btn-check:active+.btn-outline-dark,
.btn-check:checked+.btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
    color: #fff;
    background-color: #212837;
    border-color: #212837
}

.btn-check:active+.btn-outline-dark:focus,
.btn-check:checked+.btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
    box-shadow: 0 0 0 .25rem rgba(33, 40, 55, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #212837;
    background-color: transparent
}

.btn-outline-black {
    color: #000;
    border-color: #000
}

.btn-outline-black:hover {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-check:focus+.btn-outline-black,
.btn-outline-black:focus {
    box-shadow: 0 0 0 .25rem rgba(0, 0, 0, .5)
}

.btn-check:active+.btn-outline-black,
.btn-check:checked+.btn-outline-black,
.btn-outline-black.active,
.btn-outline-black.dropdown-toggle.show,
.btn-outline-black:active {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.btn-check:active+.btn-outline-black:focus,
.btn-check:checked+.btn-outline-black:focus,
.btn-outline-black.active:focus,
.btn-outline-black.dropdown-toggle.show:focus,
.btn-outline-black:active:focus {
    box-shadow: 0 0 0 .25rem rgba(0, 0, 0, .5)
}

.btn-outline-black.disabled,
.btn-outline-black:disabled {
    color: #000;
    background-color: transparent
}

.btn-outline-white {
    color: #fff;
    border-color: #fff
}

.btn-outline-white:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-check:focus+.btn-outline-white,
.btn-outline-white:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .5)
}

.btn-check:active+.btn-outline-white,
.btn-check:checked+.btn-outline-white,
.btn-outline-white.active,
.btn-outline-white.dropdown-toggle.show,
.btn-outline-white:active {
    color: #000;
    background-color: #fff;
    border-color: #fff
}

.btn-check:active+.btn-outline-white:focus,
.btn-check:checked+.btn-outline-white:focus,
.btn-outline-white.active:focus,
.btn-outline-white.dropdown-toggle.show:focus,
.btn-outline-white:active:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .5)
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
    color: #fff;
    background-color: transparent
}

.btn-outline-purple {
    color: #6f42c1;
    border-color: #6f42c1
}

.btn-outline-purple:hover {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1
}

.btn-check:focus+.btn-outline-purple,
.btn-outline-purple:focus {
    box-shadow: 0 0 0 .25rem rgba(111, 66, 193, .5)
}

.btn-check:active+.btn-outline-purple,
.btn-check:checked+.btn-outline-purple,
.btn-outline-purple.active,
.btn-outline-purple.dropdown-toggle.show,
.btn-outline-purple:active {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1
}

.btn-check:active+.btn-outline-purple:focus,
.btn-check:checked+.btn-outline-purple:focus,
.btn-outline-purple.active:focus,
.btn-outline-purple.dropdown-toggle.show:focus,
.btn-outline-purple:active:focus {
    box-shadow: 0 0 0 .25rem rgba(111, 66, 193, .5)
}

.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
    color: #6f42c1;
    background-color: transparent
}

.btn-outline-indigo {
    color: #640df3;
    border-color: #640df3
}

.btn-outline-indigo:hover {
    color: #fff;
    background-color: #640df3;
    border-color: #640df3
}

.btn-check:focus+.btn-outline-indigo,
.btn-outline-indigo:focus {
    box-shadow: 0 0 0 .25rem rgba(100, 13, 243, .5)
}

.btn-check:active+.btn-outline-indigo,
.btn-check:checked+.btn-outline-indigo,
.btn-outline-indigo.active,
.btn-outline-indigo.dropdown-toggle.show,
.btn-outline-indigo:active {
    color: #fff;
    background-color: #640df3;
    border-color: #640df3
}

.btn-check:active+.btn-outline-indigo:focus,
.btn-check:checked+.btn-outline-indigo:focus,
.btn-outline-indigo.active:focus,
.btn-outline-indigo.dropdown-toggle.show:focus,
.btn-outline-indigo:active:focus {
    box-shadow: 0 0 0 .25rem rgba(100, 13, 243, .5)
}

.btn-outline-indigo.disabled,
.btn-outline-indigo:disabled {
    color: #640df3;
    background-color: transparent
}

.btn-outline-pink {
    color: #d63384;
    border-color: #d63384
}

.btn-outline-pink:hover {
    color: #fff;
    background-color: #d63384;
    border-color: #d63384
}

.btn-check:focus+.btn-outline-pink,
.btn-outline-pink:focus {
    box-shadow: 0 0 0 .25rem rgba(214, 51, 132, .5)
}

.btn-check:active+.btn-outline-pink,
.btn-check:checked+.btn-outline-pink,
.btn-outline-pink.active,
.btn-outline-pink.dropdown-toggle.show,
.btn-outline-pink:active {
    color: #fff;
    background-color: #d63384;
    border-color: #d63384
}

.btn-check:active+.btn-outline-pink:focus,
.btn-check:checked+.btn-outline-pink:focus,
.btn-outline-pink.active:focus,
.btn-outline-pink.dropdown-toggle.show:focus,
.btn-outline-pink:active:focus {
    box-shadow: 0 0 0 .25rem rgba(214, 51, 132, .5)
}

.btn-outline-pink.disabled,
.btn-outline-pink:disabled {
    color: #d63384;
    background-color: transparent
}

.btn-outline-yellow {
    color: #fc0;
    border-color: #fc0
}

.btn-outline-yellow:hover {
    color: #000;
    background-color: #fc0;
    border-color: #fc0
}

.btn-check:focus+.btn-outline-yellow,
.btn-outline-yellow:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 204, 0, .5)
}

.btn-check:active+.btn-outline-yellow,
.btn-check:checked+.btn-outline-yellow,
.btn-outline-yellow.active,
.btn-outline-yellow.dropdown-toggle.show,
.btn-outline-yellow:active {
    color: #000;
    background-color: #fc0;
    border-color: #fc0
}

.btn-check:active+.btn-outline-yellow:focus,
.btn-check:checked+.btn-outline-yellow:focus,
.btn-outline-yellow.active:focus,
.btn-outline-yellow.dropdown-toggle.show:focus,
.btn-outline-yellow:active:focus {
    box-shadow: 0 0 0 .25rem rgba(255, 204, 0, .5)
}

.btn-outline-yellow.disabled,
.btn-outline-yellow:disabled {
    color: #fc0;
    background-color: transparent
}

.btn-outline-teal {
    color: #20c997;
    border-color: #20c997
}

.btn-outline-teal:hover {
    color: #000;
    background-color: #20c997;
    border-color: #20c997
}

.btn-check:focus+.btn-outline-teal,
.btn-outline-teal:focus {
    box-shadow: 0 0 0 .25rem rgba(32, 201, 151, .5)
}

.btn-check:active+.btn-outline-teal,
.btn-check:checked+.btn-outline-teal,
.btn-outline-teal.active,
.btn-outline-teal.dropdown-toggle.show,
.btn-outline-teal:active {
    color: #000;
    background-color: #20c997;
    border-color: #20c997
}

.btn-check:active+.btn-outline-teal:focus,
.btn-check:checked+.btn-outline-teal:focus,
.btn-outline-teal.active:focus,
.btn-outline-teal.dropdown-toggle.show:focus,
.btn-outline-teal:active:focus {
    box-shadow: 0 0 0 .25rem rgba(32, 201, 151, .5)
}

.btn-outline-teal.disabled,
.btn-outline-teal:disabled {
    color: #20c997;
    background-color: transparent
}

.btn-outline-muted {
    color: #869ac0;
    border-color: #869ac0
}

.btn-outline-muted:hover {
    color: #000;
    background-color: #869ac0;
    border-color: #869ac0
}

.btn-check:focus+.btn-outline-muted,
.btn-outline-muted:focus {
    box-shadow: 0 0 0 .25rem rgba(134, 154, 192, .5)
}

.btn-check:active+.btn-outline-muted,
.btn-check:checked+.btn-outline-muted,
.btn-outline-muted.active,
.btn-outline-muted.dropdown-toggle.show,
.btn-outline-muted:active {
    color: #000;
    background-color: #869ac0;
    border-color: #869ac0
}

.btn-check:active+.btn-outline-muted:focus,
.btn-check:checked+.btn-outline-muted:focus,
.btn-outline-muted.active:focus,
.btn-outline-muted.dropdown-toggle.show:focus,
.btn-outline-muted:active:focus {
    box-shadow: 0 0 0 .25rem rgba(134, 154, 192, .5)
}

.btn-outline-muted.disabled,
.btn-outline-muted:disabled {
    color: #869ac0;
    background-color: transparent
}

.btn-outline-light {
    color: #ebeef4;
    border-color: #ebeef4
}

.btn-outline-light:hover {
    color: #000;
    background-color: #ebeef4;
    border-color: #ebeef4
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
    box-shadow: 0 0 0 .25rem rgba(235, 238, 244, .5)
}

.btn-check:active+.btn-outline-light,
.btn-check:checked+.btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
    color: #000;
    background-color: #ebeef4;
    border-color: #ebeef4
}

.btn-check:active+.btn-outline-light:focus,
.btn-check:checked+.btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
    box-shadow: 0 0 0 .25rem rgba(235, 238, 244, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #ebeef4;
    background-color: transparent
}

.btn-outline-gray-100 {
    color: #ebeef4;
    border-color: #ebeef4
}

.btn-outline-gray-100:hover {
    color: #000;
    background-color: #ebeef4;
    border-color: #ebeef4
}

.btn-check:focus+.btn-outline-gray-100,
.btn-outline-gray-100:focus {
    box-shadow: 0 0 0 .25rem rgba(235, 238, 244, .5)
}

.btn-check:active+.btn-outline-gray-100,
.btn-check:checked+.btn-outline-gray-100,
.btn-outline-gray-100.active,
.btn-outline-gray-100.dropdown-toggle.show,
.btn-outline-gray-100:active {
    color: #000;
    background-color: #ebeef4;
    border-color: #ebeef4
}

.btn-check:active+.btn-outline-gray-100:focus,
.btn-check:checked+.btn-outline-gray-100:focus,
.btn-outline-gray-100.active:focus,
.btn-outline-gray-100.dropdown-toggle.show:focus,
.btn-outline-gray-100:active:focus {
    box-shadow: 0 0 0 .25rem rgba(235, 238, 244, .5)
}

.btn-outline-gray-100.disabled,
.btn-outline-gray-100:disabled {
    color: #ebeef4;
    background-color: transparent
}

.btn-outline-gray-200 {
    color: #dae0ec;
    border-color: #dae0ec
}

.btn-outline-gray-200:hover {
    color: #000;
    background-color: #dae0ec;
    border-color: #dae0ec
}

.btn-check:focus+.btn-outline-gray-200,
.btn-outline-gray-200:focus {
    box-shadow: 0 0 0 .25rem rgba(218, 224, 236, .5)
}

.btn-check:active+.btn-outline-gray-200,
.btn-check:checked+.btn-outline-gray-200,
.btn-outline-gray-200.active,
.btn-outline-gray-200.dropdown-toggle.show,
.btn-outline-gray-200:active {
    color: #000;
    background-color: #dae0ec;
    border-color: #dae0ec
}

.btn-check:active+.btn-outline-gray-200:focus,
.btn-check:checked+.btn-outline-gray-200:focus,
.btn-outline-gray-200.active:focus,
.btn-outline-gray-200.dropdown-toggle.show:focus,
.btn-outline-gray-200:active:focus {
    box-shadow: 0 0 0 .25rem rgba(218, 224, 236, .5)
}

.btn-outline-gray-200.disabled,
.btn-outline-gray-200:disabled {
    color: #dae0ec;
    background-color: transparent
}

.btn-outline-gray-300 {
    color: #c9d2e3;
    border-color: #c9d2e3
}

.btn-outline-gray-300:hover {
    color: #000;
    background-color: #c9d2e3;
    border-color: #c9d2e3
}

.btn-check:focus+.btn-outline-gray-300,
.btn-outline-gray-300:focus {
    box-shadow: 0 0 0 .25rem rgba(201, 210, 227, .5)
}

.btn-check:active+.btn-outline-gray-300,
.btn-check:checked+.btn-outline-gray-300,
.btn-outline-gray-300.active,
.btn-outline-gray-300.dropdown-toggle.show,
.btn-outline-gray-300:active {
    color: #000;
    background-color: #c9d2e3;
    border-color: #c9d2e3
}

.btn-check:active+.btn-outline-gray-300:focus,
.btn-check:checked+.btn-outline-gray-300:focus,
.btn-outline-gray-300.active:focus,
.btn-outline-gray-300.dropdown-toggle.show:focus,
.btn-outline-gray-300:active:focus {
    box-shadow: 0 0 0 .25rem rgba(201, 210, 227, .5)
}

.btn-outline-gray-300.disabled,
.btn-outline-gray-300:disabled {
    color: #c9d2e3;
    background-color: transparent
}

.btn-outline-gray-400 {
    color: #a8b6d1;
    border-color: #a8b6d1
}

.btn-outline-gray-400:hover {
    color: #000;
    background-color: #a8b6d1;
    border-color: #a8b6d1
}

.btn-check:focus+.btn-outline-gray-400,
.btn-outline-gray-400:focus {
    box-shadow: 0 0 0 .25rem rgba(168, 182, 209, .5)
}

.btn-check:active+.btn-outline-gray-400,
.btn-check:checked+.btn-outline-gray-400,
.btn-outline-gray-400.active,
.btn-outline-gray-400.dropdown-toggle.show,
.btn-outline-gray-400:active {
    color: #000;
    background-color: #a8b6d1;
    border-color: #a8b6d1
}

.btn-check:active+.btn-outline-gray-400:focus,
.btn-check:checked+.btn-outline-gray-400:focus,
.btn-outline-gray-400.active:focus,
.btn-outline-gray-400.dropdown-toggle.show:focus,
.btn-outline-gray-400:active:focus {
    box-shadow: 0 0 0 .25rem rgba(168, 182, 209, .5)
}

.btn-outline-gray-400.disabled,
.btn-outline-gray-400:disabled {
    color: #a8b6d1;
    background-color: transparent
}

.btn-outline-gray-500 {
    color: #869ac0;
    border-color: #869ac0
}

.btn-outline-gray-500:hover {
    color: #000;
    background-color: #869ac0;
    border-color: #869ac0
}

.btn-check:focus+.btn-outline-gray-500,
.btn-outline-gray-500:focus {
    box-shadow: 0 0 0 .25rem rgba(134, 154, 192, .5)
}

.btn-check:active+.btn-outline-gray-500,
.btn-check:checked+.btn-outline-gray-500,
.btn-outline-gray-500.active,
.btn-outline-gray-500.dropdown-toggle.show,
.btn-outline-gray-500:active {
    color: #000;
    background-color: #869ac0;
    border-color: #869ac0
}

.btn-check:active+.btn-outline-gray-500:focus,
.btn-check:checked+.btn-outline-gray-500:focus,
.btn-outline-gray-500.active:focus,
.btn-outline-gray-500.dropdown-toggle.show:focus,
.btn-outline-gray-500:active:focus {
    box-shadow: 0 0 0 .25rem rgba(134, 154, 192, .5)
}

.btn-outline-gray-500.disabled,
.btn-outline-gray-500:disabled {
    color: #869ac0;
    background-color: transparent
}

.btn-outline-gray-600 {
    color: #657eae;
    border-color: #657eae
}

.btn-outline-gray-600:hover {
    color: #000;
    background-color: #657eae;
    border-color: #657eae
}

.btn-check:focus+.btn-outline-gray-600,
.btn-outline-gray-600:focus {
    box-shadow: 0 0 0 .25rem rgba(101, 126, 174, .5)
}

.btn-check:active+.btn-outline-gray-600,
.btn-check:checked+.btn-outline-gray-600,
.btn-outline-gray-600.active,
.btn-outline-gray-600.dropdown-toggle.show,
.btn-outline-gray-600:active {
    color: #000;
    background-color: #657eae;
    border-color: #657eae
}

.btn-check:active+.btn-outline-gray-600:focus,
.btn-check:checked+.btn-outline-gray-600:focus,
.btn-outline-gray-600.active:focus,
.btn-outline-gray-600.dropdown-toggle.show:focus,
.btn-outline-gray-600:active:focus {
    box-shadow: 0 0 0 .25rem rgba(101, 126, 174, .5)
}

.btn-outline-gray-600.disabled,
.btn-outline-gray-600:disabled {
    color: #657eae;
    background-color: transparent
}

.btn-outline-gray-700 {
    color: #4d6593;
    border-color: #4d6593
}

.btn-outline-gray-700:hover {
    color: #fff;
    background-color: #4d6593;
    border-color: #4d6593
}

.btn-check:focus+.btn-outline-gray-700,
.btn-outline-gray-700:focus {
    box-shadow: 0 0 0 .25rem rgba(77, 101, 147, .5)
}

.btn-check:active+.btn-outline-gray-700,
.btn-check:checked+.btn-outline-gray-700,
.btn-outline-gray-700.active,
.btn-outline-gray-700.dropdown-toggle.show,
.btn-outline-gray-700:active {
    color: #fff;
    background-color: #4d6593;
    border-color: #4d6593
}

.btn-check:active+.btn-outline-gray-700:focus,
.btn-check:checked+.btn-outline-gray-700:focus,
.btn-outline-gray-700.active:focus,
.btn-outline-gray-700.dropdown-toggle.show:focus,
.btn-outline-gray-700:active:focus {
    box-shadow: 0 0 0 .25rem rgba(77, 101, 147, .5)
}

.btn-outline-gray-700.disabled,
.btn-outline-gray-700:disabled {
    color: #4d6593;
    background-color: transparent
}

.btn-outline-gray-800 {
    color: #3c4e71;
    border-color: #3c4e71
}

.btn-outline-gray-800:hover {
    color: #fff;
    background-color: #3c4e71;
    border-color: #3c4e71
}

.btn-check:focus+.btn-outline-gray-800,
.btn-outline-gray-800:focus {
    box-shadow: 0 0 0 .25rem rgba(60, 78, 113, .5)
}

.btn-check:active+.btn-outline-gray-800,
.btn-check:checked+.btn-outline-gray-800,
.btn-outline-gray-800.active,
.btn-outline-gray-800.dropdown-toggle.show,
.btn-outline-gray-800:active {
    color: #fff;
    background-color: #3c4e71;
    border-color: #3c4e71
}

.btn-check:active+.btn-outline-gray-800:focus,
.btn-check:checked+.btn-outline-gray-800:focus,
.btn-outline-gray-800.active:focus,
.btn-outline-gray-800.dropdown-toggle.show:focus,
.btn-outline-gray-800:active:focus {
    box-shadow: 0 0 0 .25rem rgba(60, 78, 113, .5)
}

.btn-outline-gray-800.disabled,
.btn-outline-gray-800:disabled {
    color: #3c4e71;
    background-color: transparent
}

.btn-outline-gray-900 {
    color: #212837;
    border-color: #212837
}

.btn-outline-gray-900:hover {
    color: #fff;
    background-color: #212837;
    border-color: #212837
}

.btn-check:focus+.btn-outline-gray-900,
.btn-outline-gray-900:focus {
    box-shadow: 0 0 0 .25rem rgba(33, 40, 55, .5)
}

.btn-check:active+.btn-outline-gray-900,
.btn-check:checked+.btn-outline-gray-900,
.btn-outline-gray-900.active,
.btn-outline-gray-900.dropdown-toggle.show,
.btn-outline-gray-900:active {
    color: #fff;
    background-color: #212837;
    border-color: #212837
}

.btn-check:active+.btn-outline-gray-900:focus,
.btn-check:checked+.btn-outline-gray-900:focus,
.btn-outline-gray-900.active:focus,
.btn-outline-gray-900.dropdown-toggle.show:focus,
.btn-outline-gray-900:active:focus {
    box-shadow: 0 0 0 .25rem rgba(33, 40, 55, .5)
}

.btn-outline-gray-900.disabled,
.btn-outline-gray-900:disabled {
    color: #212837;
    background-color: transparent
}

.btn-link {
    font-weight: 400;
    color: #1f6bff;
    text-decoration: underline
}

.btn-link:hover {
    color: #1956cc
}

.btn-link.disabled,
.btn-link:disabled {
    color: #657eae
}

.btn-group-lg>.btn,
.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.09375rem;
    border-radius: 9px
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .25rem .5rem;
    font-size: .76562rem;
    border-radius: 3px
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.dropdown,
.dropend,
.dropstart,
.dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: .875rem;
    color: #212837;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, .15);
    border-radius: 6px
}

.dropdown-menu[data-bs-popper] {
    left: 0;
    margin-top: .125rem
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media (min-width:576px) {
    .dropdown-menu-sm-start {
        --bs-position: start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-start {
        --bs-position: start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-start {
        --bs-position: start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1660px) {
    .dropdown-menu-xxl-start {
        --bs-position: start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width:1900px) {
    .dropdown-menu-xxxl-start {
        --bs-position: start
    }

    .dropdown-menu-xxxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%
}

.dropup .dropdown-menu[data-bs-popper] {
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%
}

.dropend .dropdown-menu[data-bs-popper] {
    margin-top: 0;
    margin-left: .125rem
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-toggle::after {
    vertical-align: 0
}

.dropstart .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto
}

.dropstart .dropdown-menu[data-bs-popper] {
    margin-top: 0;
    margin-right: .125rem
}

.dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropstart .dropdown-toggle::after {
    display: none
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, .15)
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 6px 20px;
    clear: both;
    font-weight: 400;
    color: #212837;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #1e2432;
    background-color: #f0f2f6
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #1f6bff
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #869ac0;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 20px;
    margin-bottom: 0;
    font-size: .76562rem;
    color: #657eae;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: 6px 20px;
    color: #212837
}

.dropdown-menu-dark {
    color: #c9d2e3;
    background-color: #3c4e71;
    border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item {
    color: #c9d2e3
}

.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, .15)
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
    color: #fff;
    background-color: #1f6bff
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
    color: #869ac0
}

.dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item-text {
    color: #c9d2e3
}

.dropdown-menu-dark .dropdown-header {
    color: #869ac0
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn~.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link.disabled {
    color: #657eae;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 1px solid #c9d2e3
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #dae0ec #dae0ec #c9d2e3;
    isolation: isolate
}

.nav-tabs .nav-link.disabled {
    color: #657eae;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #4d6593;
    background-color: #ebeef4;
    border-color: #c9d2e3 #c9d2e3 #ebeef4
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 6px
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #1f6bff
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    padding-top: .33594rem;
    padding-bottom: .33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    text-decoration: none;
    white-space: nowrap;
    width: 65px
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
    transition: box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .navbar-toggler {
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 .25rem
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

@media (min-width:1660px) {
    .navbar-expand-xxl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }
}

@media (min-width:1900px) {
    .navbar-expand-xxxl {
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xxxl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xxxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxxl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xxxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xxxl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .55);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .55);
    border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(101, 126, 174, .25);
    border-radius: 9px
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem
}

.card-title {
    margin-bottom: .5rem
}

.card-subtitle {
    margin-top: -.25rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1rem
}

.card-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(218, 224, 236, .25);
    border-bottom: 1px solid rgba(101, 126, 174, .25)
}

.card-header:first-child {
    border-radius: 8px 8px 0 0
}

.card-footer {
    padding: .5rem 1rem;
    background-color: rgba(218, 224, 236, .25);
    border-top: 1px solid rgba(101, 126, 174, .25)
}

.card-footer:last-child {
    border-radius: 0 0 8px 8px
}

.card-header-tabs {
    margin-right: -.5rem;
    margin-bottom: -.5rem;
    margin-left: -.5rem;
    border-bottom: 0
}

.card-header-tabs .nav-link.active {
    background-color: #fff;
    border-bottom-color: #fff
}

.card-header-pills {
    margin-right: -.5rem;
    margin-left: -.5rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: 8px
}

.card-img,
.card-img-bottom,
.card-img-top {
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px
}

.card-group>.card {
    margin-bottom: 8px
}

@media (min-width:576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: .75rem 1rem;
    font-size: .875rem;
    color: #212837;
    text-align: left;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button.collapsed {
    border-bottom-width: 0
}

.accordion-button:not(.collapsed) {
    color: #1c60e6;
    background-color: #e9f0ff
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231c60e6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(180deg)
}

.accordion-button::after {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212837'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1rem;
    transition: transform .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button::after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: #528dff;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(31, 107, 255, .25)
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 1px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-width: 1px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
}

.accordion-collapse {
    border: solid rgba(0, 0, 0, .125);
    border-width: 0 1px
}

.accordion-body {
    padding: .75rem 1rem
}

.accordion-flush .accordion-button {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item:first-of-type .accordion-button {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #657eae;
    content: var(--bs-breadcrumb-divider, "https://seantheme.com/")
}

.breadcrumb-item.active {
    color: #657eae
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    color: #212837;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #c9d2e3;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: #212837;
    background-color: #ebeef4;
    border-color: #c9d2e3
}

.page-link:focus {
    z-index: 3;
    color: #1956cc;
    background-color: #dae0ec;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(31, 107, 255, .25)
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #1f6bff;
    border-color: #1f6bff
}

.page-item.disabled .page-link {
    color: #657eae;
    pointer-events: none;
    background-color: #fff;
    border-color: #c9d2e3
}

.page-link {
    padding: .375rem .75rem
}

.page-item:first-child .page-link {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px
}

.page-item:last-child .page-link {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.09375rem
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .76562rem
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

.badge {
    display: inline-block;
    padding: .35em .65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 6px
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 6px
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}

.alert-primary {
    color: #134099;
    background-color: #d2e1ff;
    border-color: #bcd3ff
}

.alert-primary .alert-link {
    color: #0f337a
}

.alert-secondary {
    color: #50545b;
    background-color: #f4f6f9;
    border-color: #eff2f7
}

.alert-secondary .alert-link {
    color: #404349
}

.alert-warning {
    color: #995900;
    background-color: #ffeacc;
    border-color: #ffdfb3
}

.alert-warning .alert-link {
    color: #7a4700
}

.alert-danger {
    color: #8a0e08;
    background-color: #fad1cf;
    border-color: #f8bab6
}

.alert-danger .alert-link {
    color: #6e0b06
}

.alert-success {
    color: #015216;
    background-color: #cce7d3;
    border-color: #b3dbbd
}

.alert-success .alert-link {
    color: #014212
}

.alert-info {
    color: #1d7299;
    background-color: #d6f2ff;
    border-color: #c1ecff
}

.alert-info .alert-link {
    color: #175b7a
}

.alert-dark {
    color: #141821;
    background-color: #d3d4d7;
    border-color: #bcbfc3
}

.alert-dark .alert-link {
    color: #10131a
}

.alert-black {
    color: #000;
    background-color: #ccc;
    border-color: #b3b3b3
}

.alert-black .alert-link {
    color: #000
}

.alert-white {
    color: #666;
    background-color: #fff;
    border-color: #fff
}

.alert-white .alert-link {
    color: #525252
}

.alert-purple {
    color: #432874;
    background-color: #e2d9f3;
    border-color: #d4c6ec
}

.alert-purple .alert-link {
    color: #36205d
}

.alert-indigo {
    color: #3c0892;
    background-color: #e0cffd;
    border-color: #d1b6fb
}

.alert-indigo .alert-link {
    color: #300675
}

.alert-pink {
    color: #801f4f;
    background-color: #f7d6e6;
    border-color: #f3c2da
}

.alert-pink .alert-link {
    color: #66193f
}

.alert-yellow {
    color: #665200;
    background-color: #fff5cc;
    border-color: #fff0b3
}

.alert-yellow .alert-link {
    color: #524200
}

.alert-teal {
    color: #13795b;
    background-color: #d2f4ea;
    border-color: #bcefe0
}

.alert-teal .alert-link {
    color: #0f6149
}

.alert-muted {
    color: #505c73;
    background-color: #e7ebf2;
    border-color: #dbe1ec
}

.alert-muted .alert-link {
    color: #404a5c
}

.alert-light {
    color: #5e5f62;
    background-color: #fbfcfd;
    border-color: #f9fafc
}

.alert-light .alert-link {
    color: #4b4c4e
}

.alert-gray-100 {
    color: #5e5f62;
    background-color: #fbfcfd;
    border-color: #f9fafc
}

.alert-gray-100 .alert-link {
    color: #4b4c4e
}

.alert-gray-200 {
    color: #575a5e;
    background-color: #f8f9fb;
    border-color: #f4f6f9
}

.alert-gray-200 .alert-link {
    color: #46484b
}

.alert-gray-300 {
    color: #50545b;
    background-color: #f4f6f9;
    border-color: #eff2f7
}

.alert-gray-300 .alert-link {
    color: #404349
}

.alert-gray-400 {
    color: #656d7d;
    background-color: #eef0f6;
    border-color: #e5e9f1
}

.alert-gray-400 .alert-link {
    color: #515764
}

.alert-gray-500 {
    color: #505c73;
    background-color: #e7ebf2;
    border-color: #dbe1ec
}

.alert-gray-500 .alert-link {
    color: #404a5c
}

.alert-gray-600 {
    color: #3d4c68;
    background-color: #e0e5ef;
    border-color: #d1d8e7
}

.alert-gray-600 .alert-link {
    color: #313d53
}

.alert-gray-700 {
    color: #2e3d58;
    background-color: #dbe0e9;
    border-color: #cad1df
}

.alert-gray-700 .alert-link {
    color: #253146
}

.alert-gray-800 {
    color: #242f44;
    background-color: #d8dce3;
    border-color: #c5cad4
}

.alert-gray-800 .alert-link {
    color: #1d2636
}

.alert-gray-900 {
    color: #141821;
    background-color: #d3d4d7;
    border-color: #bcbfc3
}

.alert-gray-900 .alert-link {
    color: #10131a
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .65625rem;
    background-color: #dae0ec;
    border-radius: 6px
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #1f6bff;
    transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        animation: none
    }
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 6px
}

.list-group-item-action {
    width: 100%;
    color: #4d6593;
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: #4d6593;
    text-decoration: none;
    background-color: #ebeef4
}

.list-group-item-action:active {
    color: #212837;
    background-color: #dae0ec
}

.list-group-item {
    position: relative;
    display: block;
    padding: .5rem 1rem;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(77, 101, 147, .2)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #657eae;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #1f6bff;
    border-color: #1f6bff
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: 6px;
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media (min-width:576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:1660px) {
    .list-group-horizontal-xxl {
        flex-direction: row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:1900px) {
    .list-group-horizontal-xxxl {
        flex-direction: row
    }

    .list-group-horizontal-xxxl>.list-group-item:first-child {
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxxl>.list-group-item:last-child {
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxxl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xxxl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #134099;
    background-color: #d2e1ff
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #134099;
    background-color: #bdcbe6
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #134099;
    border-color: #134099
}

.list-group-item-secondary {
    color: #50545b;
    background-color: #f4f6f9
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #50545b;
    background-color: #dcdde0
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #50545b;
    border-color: #50545b
}

.list-group-item-warning {
    color: #995900;
    background-color: #ffeacc
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #995900;
    background-color: #e6d3b8
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #995900;
    border-color: #995900
}

.list-group-item-danger {
    color: #8a0e08;
    background-color: #fad1cf
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #8a0e08;
    background-color: #e1bcba
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #8a0e08;
    border-color: #8a0e08
}

.list-group-item-success {
    color: #015216;
    background-color: #cce7d3
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #015216;
    background-color: #b8d0be
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #015216;
    border-color: #015216
}

.list-group-item-info {
    color: #1d7299;
    background-color: #d6f2ff
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #1d7299;
    background-color: #c1dae6
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #1d7299;
    border-color: #1d7299
}

.list-group-item-dark {
    color: #141821;
    background-color: #d3d4d7
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #141821;
    background-color: #bebfc2
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141821;
    border-color: #141821
}

.list-group-item-black {
    color: #000;
    background-color: #ccc
}

.list-group-item-black.list-group-item-action:focus,
.list-group-item-black.list-group-item-action:hover {
    color: #000;
    background-color: #b8b8b8
}

.list-group-item-black.list-group-item-action.active {
    color: #fff;
    background-color: #000;
    border-color: #000
}

.list-group-item-white {
    color: #666;
    background-color: #fff
}

.list-group-item-white.list-group-item-action:focus,
.list-group-item-white.list-group-item-action:hover {
    color: #666;
    background-color: #e6e6e6
}

.list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #666;
    border-color: #666
}

.list-group-item-purple {
    color: #432874;
    background-color: #e2d9f3
}

.list-group-item-purple.list-group-item-action:focus,
.list-group-item-purple.list-group-item-action:hover {
    color: #432874;
    background-color: #cbc3db
}

.list-group-item-purple.list-group-item-action.active {
    color: #fff;
    background-color: #432874;
    border-color: #432874
}

.list-group-item-indigo {
    color: #3c0892;
    background-color: #e0cffd
}

.list-group-item-indigo.list-group-item-action:focus,
.list-group-item-indigo.list-group-item-action:hover {
    color: #3c0892;
    background-color: #cabae4
}

.list-group-item-indigo.list-group-item-action.active {
    color: #fff;
    background-color: #3c0892;
    border-color: #3c0892
}

.list-group-item-pink {
    color: #801f4f;
    background-color: #f7d6e6
}

.list-group-item-pink.list-group-item-action:focus,
.list-group-item-pink.list-group-item-action:hover {
    color: #801f4f;
    background-color: #dec1cf
}

.list-group-item-pink.list-group-item-action.active {
    color: #fff;
    background-color: #801f4f;
    border-color: #801f4f
}

.list-group-item-yellow {
    color: #665200;
    background-color: #fff5cc
}

.list-group-item-yellow.list-group-item-action:focus,
.list-group-item-yellow.list-group-item-action:hover {
    color: #665200;
    background-color: #e6ddb8
}

.list-group-item-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #665200;
    border-color: #665200
}

.list-group-item-teal {
    color: #13795b;
    background-color: #d2f4ea
}

.list-group-item-teal.list-group-item-action:focus,
.list-group-item-teal.list-group-item-action:hover {
    color: #13795b;
    background-color: #bddcd3
}

.list-group-item-teal.list-group-item-action.active {
    color: #fff;
    background-color: #13795b;
    border-color: #13795b
}

.list-group-item-muted {
    color: #505c73;
    background-color: #e7ebf2
}

.list-group-item-muted.list-group-item-action:focus,
.list-group-item-muted.list-group-item-action:hover {
    color: #505c73;
    background-color: #d0d4da
}

.list-group-item-muted.list-group-item-action.active {
    color: #fff;
    background-color: #505c73;
    border-color: #505c73
}

.list-group-item-light {
    color: #5e5f62;
    background-color: #fbfcfd
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #5e5f62;
    background-color: #e2e3e4
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #5e5f62;
    border-color: #5e5f62
}

.list-group-item-gray-100 {
    color: #5e5f62;
    background-color: #fbfcfd
}

.list-group-item-gray-100.list-group-item-action:focus,
.list-group-item-gray-100.list-group-item-action:hover {
    color: #5e5f62;
    background-color: #e2e3e4
}

.list-group-item-gray-100.list-group-item-action.active {
    color: #fff;
    background-color: #5e5f62;
    border-color: #5e5f62
}

.list-group-item-gray-200 {
    color: #575a5e;
    background-color: #f8f9fb
}

.list-group-item-gray-200.list-group-item-action:focus,
.list-group-item-gray-200.list-group-item-action:hover {
    color: #575a5e;
    background-color: #dfe0e2
}

.list-group-item-gray-200.list-group-item-action.active {
    color: #fff;
    background-color: #575a5e;
    border-color: #575a5e
}

.list-group-item-gray-300 {
    color: #50545b;
    background-color: #f4f6f9
}

.list-group-item-gray-300.list-group-item-action:focus,
.list-group-item-gray-300.list-group-item-action:hover {
    color: #50545b;
    background-color: #dcdde0
}

.list-group-item-gray-300.list-group-item-action.active {
    color: #fff;
    background-color: #50545b;
    border-color: #50545b
}

.list-group-item-gray-400 {
    color: #656d7d;
    background-color: #eef0f6
}

.list-group-item-gray-400.list-group-item-action:focus,
.list-group-item-gray-400.list-group-item-action:hover {
    color: #656d7d;
    background-color: #d6d8dd
}

.list-group-item-gray-400.list-group-item-action.active {
    color: #fff;
    background-color: #656d7d;
    border-color: #656d7d
}

.list-group-item-gray-500 {
    color: #505c73;
    background-color: #e7ebf2
}

.list-group-item-gray-500.list-group-item-action:focus,
.list-group-item-gray-500.list-group-item-action:hover {
    color: #505c73;
    background-color: #d0d4da
}

.list-group-item-gray-500.list-group-item-action.active {
    color: #fff;
    background-color: #505c73;
    border-color: #505c73
}

.list-group-item-gray-600 {
    color: #3d4c68;
    background-color: #e0e5ef
}

.list-group-item-gray-600.list-group-item-action:focus,
.list-group-item-gray-600.list-group-item-action:hover {
    color: #3d4c68;
    background-color: #caced7
}

.list-group-item-gray-600.list-group-item-action.active {
    color: #fff;
    background-color: #3d4c68;
    border-color: #3d4c68
}

.list-group-item-gray-700 {
    color: #2e3d58;
    background-color: #dbe0e9
}

.list-group-item-gray-700.list-group-item-action:focus,
.list-group-item-gray-700.list-group-item-action:hover {
    color: #2e3d58;
    background-color: #c5cad2
}

.list-group-item-gray-700.list-group-item-action.active {
    color: #fff;
    background-color: #2e3d58;
    border-color: #2e3d58
}

.list-group-item-gray-800 {
    color: #242f44;
    background-color: #d8dce3
}

.list-group-item-gray-800.list-group-item-action:focus,
.list-group-item-gray-800.list-group-item-action:hover {
    color: #242f44;
    background-color: #c2c6cc
}

.list-group-item-gray-800.list-group-item-action.active {
    color: #fff;
    background-color: #242f44;
    border-color: #242f44
}

.list-group-item-gray-900 {
    color: #141821;
    background-color: #d3d4d7
}

.list-group-item-gray-900.list-group-item-action:focus,
.list-group-item-gray-900.list-group-item-action:hover {
    color: #141821;
    background-color: #bebfc2
}

.list-group-item-gray-900.list-group-item-action.active {
    color: #fff;
    background-color: #141821;
    border-color: #141821
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: 6px;
    opacity: .5
}

.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75
}

.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(31, 107, 255, .25);
    opacity: 1
}

.btn-close.disabled,
.btn-close:disabled {
    pointer-events: none;
    user-select: none;
    opacity: .25
}

.btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
    width: 350px;
    max-width: 100%;
    font-size: .875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border: 1px solid rgba(60, 78, 113, .15);
    box-shadow: 0 .25rem .75rem rgba(60, 78, 113, .1);
    border-radius: .25rem
}

.toast:not(.showing):not(.show) {
    opacity: 0
}

.toast.hide {
    display: none
}

.toast-container {
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: 8px
}

.toast-header {
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    color: #212837;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(60, 78, 113, .15);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.toast-header .btn-close {
    margin-right: -.375rem;
    margin-left: .75rem
}

.toast-body {
    padding: .75rem;
    word-wrap: break-word
}

.modal-open {
    overflow: hidden
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(77, 101, 147, .25);
    border-radius: 9px;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #212837
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dae0ec;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px
}

.modal-header .btn-close {
    padding: .5rem .5rem;
    margin: -.5rem -.5rem -.5rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dae0ec;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px
}

.modal-footer>* {
    margin: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-sm {
        max-width: 300px
    }
}

@media (min-width:992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px
    }
}

@media (min-width:1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

.modal-fullscreen .modal-footer {
    border-radius: 0
}

@media (max-width:575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:1659.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:1899.98px) {
    .modal-fullscreen-xxxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxxl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xxxl-down .modal-footer {
        border-radius: 0
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .75rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[data-popper-placement^=top],
.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
    bottom: 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-auto[data-popper-placement^=right],
.bs-tooltip-end {
    padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
    right: -1px;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-auto[data-popper-placement^=bottom],
.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
    top: 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-auto[data-popper-placement^=left],
.bs-tooltip-start {
    padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    left: -1px;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 6px
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .75rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(33, 40, 55, .1);
    border-radius: 9px
}

.popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow,
.bs-popover-top>.popover-arrow {
    bottom: calc(-.5rem - 1px)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(33, 40, 55, .15)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.bs-popover-top>.popover-arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow,
.bs-popover-end>.popover-arrow {
    left: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(33, 40, 55, .15)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.bs-popover-end>.popover-arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow,
.bs-popover-bottom>.popover-arrow {
    top: calc(-.5rem - 1px)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(33, 40, 55, .15)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::after {
    top: 1px;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f0f0f0
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow,
.bs-popover-start>.popover-arrow {
    right: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(33, 40, 55, .15)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.bs-popover-start>.popover-arrow::after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: .875rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid #d8d8d8;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: 1rem 1rem;
    color: #212837
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
    transform: translateX(100%)
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {

    .carousel-fade .active.carousel-item-end,
    .carousel-fade .active.carousel-item-start {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {

    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: .75s linear infinite spinner-grow
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

@media (prefers-reduced-motion:reduce) {

    .spinner-border,
    .spinner-grow {
        animation-duration: 1.5s
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.link-primary {
    color: #1f6bff
}

.link-primary:focus,
.link-primary:hover {
    color: #1956cc
}

.link-secondary {
    color: #c9d2e3
}

.link-secondary:focus,
.link-secondary:hover {
    color: #d4dbe9
}

.link-warning {
    color: #ff9500
}

.link-warning:focus,
.link-warning:hover {
    color: #fa3
}

.link-danger {
    color: #e6180d
}

.link-danger:focus,
.link-danger:hover {
    color: #b8130a
}

.link-success {
    color: #028824
}

.link-success:focus,
.link-success:hover {
    color: #026d1d
}

.link-info {
    color: #30beff
}

.link-info:focus,
.link-info:hover {
    color: #59cbff
}

.link-dark {
    color: #212837
}

.link-dark:focus,
.link-dark:hover {
    color: #1a202c
}

.link-black {
    color: #000
}

.link-black:focus,
.link-black:hover {
    color: #000
}

.link-white {
    color: #fff
}

.link-white:focus,
.link-white:hover {
    color: #fff
}

.link-purple {
    color: #6f42c1
}

.link-purple:focus,
.link-purple:hover {
    color: #59359a
}

.link-indigo {
    color: #640df3
}

.link-indigo:focus,
.link-indigo:hover {
    color: #500ac2
}

.link-pink {
    color: #d63384
}

.link-pink:focus,
.link-pink:hover {
    color: #ab296a
}

.link-yellow {
    color: #fc0
}

.link-yellow:focus,
.link-yellow:hover {
    color: #ffd633
}

.link-teal {
    color: #20c997
}

.link-teal:focus,
.link-teal:hover {
    color: #4dd4ac
}

.link-muted {
    color: #869ac0
}

.link-muted:focus,
.link-muted:hover {
    color: #9eaecd
}

.link-light {
    color: #ebeef4
}

.link-light:focus,
.link-light:hover {
    color: #eff1f6
}

.link-gray-100 {
    color: #ebeef4
}

.link-gray-100:focus,
.link-gray-100:hover {
    color: #eff1f6
}

.link-gray-200 {
    color: #dae0ec
}

.link-gray-200:focus,
.link-gray-200:hover {
    color: #e1e6f0
}

.link-gray-300 {
    color: #c9d2e3
}

.link-gray-300:focus,
.link-gray-300:hover {
    color: #d4dbe9
}

.link-gray-400 {
    color: #a8b6d1
}

.link-gray-400:focus,
.link-gray-400:hover {
    color: #b9c5da
}

.link-gray-500 {
    color: #869ac0
}

.link-gray-500:focus,
.link-gray-500:hover {
    color: #9eaecd
}

.link-gray-600 {
    color: #657eae
}

.link-gray-600:focus,
.link-gray-600:hover {
    color: #8498be
}

.link-gray-700 {
    color: #4d6593
}

.link-gray-700:focus,
.link-gray-700:hover {
    color: #3e5176
}

.link-gray-800 {
    color: #3c4e71
}

.link-gray-800:focus,
.link-gray-800:hover {
    color: #303e5a
}

.link-gray-900 {
    color: #212837
}

.link-gray-900:focus,
.link-gray-900:hover {
    color: #1a202c
}

.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: calc(3 / 4 * 100%)
}

.ratio-16x9 {
    --bs-aspect-ratio: calc(9 / 16 * 100%)
}

.ratio-21x9 {
    --bs-aspect-ratio: calc(9 / 21 * 100%)
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
}

@media (min-width:576px) {
    .sticky-sm-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:768px) {
    .sticky-md-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:992px) {
    .sticky-lg-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:1200px) {
    .sticky-xl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:1660px) {
    .sticky-xxl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width:1900px) {
    .sticky-xxxl-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.float-start {
    float: left !important
}

.float-end {
    float: right !important
}

.float-none {
    float: none !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.overflow-visible {
    overflow: visible !important
}

.overflow-scroll {
    overflow: scroll !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-grid {
    display: grid !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

.d-none {
    display: none !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: sticky !important
}

.top-0 {
    top: 0 !important
}

.top-50 {
    top: 50% !important
}

.top-100 {
    top: 100% !important
}

.bottom-0 {
    bottom: 0 !important
}

.bottom-50 {
    bottom: 50% !important
}

.bottom-100 {
    bottom: 100% !important
}

.start-0 {
    left: 0 !important
}

.start-50 {
    left: 50% !important
}

.start-100 {
    left: 100% !important
}

.end-0 {
    right: 0 !important
}

.end-50 {
    right: 50% !important
}

.end-100 {
    right: 100% !important
}

.translate-middle {
    transform: translate(-50%, -50%) !important
}

.translate-middle-x {
    transform: translateX(-50%) !important
}

.translate-middle-y {
    transform: translateY(-50%) !important
}

.border {
    border: 1px solid #dae0ec !important
}

.border-0 {
    border: 0 !important
}

.border-top {
    border-top: 1px solid #dae0ec !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-end {
    border-right: 1px solid #dae0ec !important
}

.border-end-0 {
    border-right: 0 !important
}

.border-bottom {
    border-bottom: 1px solid #dae0ec !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-start {
    border-left: 1px solid #dae0ec !important
}

.border-start-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #1f6bff !important
}

.border-secondary {
    border-color: #c9d2e3 !important
}

.border-warning {
    border-color: #ff9500 !important
}

.border-danger {
    border-color: #e6180d !important
}

.border-success {
    border-color: #028824 !important
}

.border-info {
    border-color: #30beff !important
}

.border-dark {
    border-color: #212837 !important
}

.border-black {
    border-color: #000 !important
}

.border-white {
    border-color: #fff !important
}

.border-purple {
    border-color: #6f42c1 !important
}

.border-indigo {
    border-color: #640df3 !important
}

.border-pink {
    border-color: #d63384 !important
}

.border-yellow {
    border-color: #fc0 !important
}

.border-teal {
    border-color: #20c997 !important
}

.border-muted {
    border-color: #869ac0 !important
}

.border-light {
    border-color: #ebeef4 !important
}

.border-gray-100 {
    border-color: #ebeef4 !important
}

.border-gray-200 {
    border-color: #dae0ec !important
}

.border-gray-300 {
    border-color: #c9d2e3 !important
}

.border-gray-400 {
    border-color: #a8b6d1 !important
}

.border-gray-500 {
    border-color: #869ac0 !important
}

.border-gray-600 {
    border-color: #657eae !important
}

.border-gray-700 {
    border-color: #4d6593 !important
}

.border-gray-800 {
    border-color: #3c4e71 !important
}

.border-gray-900 {
    border-color: #212837 !important
}

.border-0 {
    border-width: 0 !important
}

.border-1 {
    border-width: 1px !important
}

.border-2 {
    border-width: 2px !important
}

.border-3 {
    border-width: 3px !important
}

.border-4 {
    border-width: 4px !important
}

.border-5 {
    border-width: 5px !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.vw-100 {
    width: 100vw !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mh-100 {
    max-height: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.gap-0 {
    gap: 0 !important
}

.gap-1 {
    gap: .25rem !important
}

.gap-2 {
    gap: .5rem !important
}

.gap-3 {
    gap: 1rem !important
}

.gap-4 {
    gap: 1.5rem !important
}

.gap-5 {
    gap: 3rem !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.justify-content-evenly {
    justify-content: space-evenly !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

.order-first {
    order: -1 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.order-3 {
    order: 3 !important
}

.order-4 {
    order: 4 !important
}

.order-5 {
    order: 5 !important
}

.order-last {
    order: 6 !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 3rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 3rem !important
}

.ms-auto {
    margin-left: auto !important
}

.m-n1 {
    margin: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.m-n3 {
    margin: -1rem !important
}

.m-n4 {
    margin: -1.5rem !important
}

.m-n5 {
    margin: -3rem !important
}

.mx-n1 {
    margin-right: -.25rem !important;
    margin-left: -.25rem !important
}

.mx-n2 {
    margin-right: -.5rem !important;
    margin-left: -.5rem !important
}

.mx-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important
}

.mx-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important
}

.mx-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important
}

.my-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important
}

.my-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important
}

.my-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important
}

.my-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important
}

.my-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important
}

.mt-n1 {
    margin-top: -.25rem !important
}

.mt-n2 {
    margin-top: -.5rem !important
}

.mt-n3 {
    margin-top: -1rem !important
}

.mt-n4 {
    margin-top: -1.5rem !important
}

.mt-n5 {
    margin-top: -3rem !important
}

.me-n1 {
    margin-right: -.25rem !important
}

.me-n2 {
    margin-right: -.5rem !important
}

.me-n3 {
    margin-right: -1rem !important
}

.me-n4 {
    margin-right: -1.5rem !important
}

.me-n5 {
    margin-right: -3rem !important
}

.mb-n1 {
    margin-bottom: -.25rem !important
}

.mb-n2 {
    margin-bottom: -.5rem !important
}

.mb-n3 {
    margin-bottom: -1rem !important
}

.mb-n4 {
    margin-bottom: -1.5rem !important
}

.mb-n5 {
    margin-bottom: -3rem !important
}

.ms-n1 {
    margin-left: -.25rem !important
}

.ms-n2 {
    margin-left: -.5rem !important
}

.ms-n3 {
    margin-left: -1rem !important
}

.ms-n4 {
    margin-left: -1.5rem !important
}

.ms-n5 {
    margin-left: -3rem !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 3rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 3rem !important
}

.fs-1 {
    font-size: calc(1.34375rem + 1.125vw) !important
}

.fs-2 {
    font-size: calc(1.3rem + .6vw) !important
}

.fs-3 {
    font-size: calc(1.27812rem + .3375vw) !important
}

.fs-4 {
    font-size: calc(1.25625rem + .075vw) !important
}

.fs-5 {
    font-size: 1.09375rem !important
}

.fs-6 {
    font-size: .875rem !important
}

.fst-italic {
    font-style: italic !important
}

.fst-normal {
    font-style: normal !important
}

.fw-light {
    font-weight: 300 !important
}

.fw-lighter {
    font-weight: lighter !important
}

.fw-normal {
    font-weight: 400 !important
}

.fw-bold {
    font-weight: 700 !important
}

.fw-bolder {
    font-weight: bolder !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-primary {
    color: #1f6bff !important
}

.text-secondary {
    color: #c9d2e3 !important
}

.text-warning {
    color: #ff9500 !important
}

.text-danger {
    color: #e6180d !important
}

.text-success {
    color: #028824 !important
}

.text-info {
    color: #30beff !important
}

.text-dark {
    color: #212837 !important
}

.text-black {
    color: #000 !important
}

.text-white {
    color: #fff !important
}

.text-purple {
    color: #6f42c1 !important
}

.text-indigo {
    color: #640df3 !important
}

.text-pink {
    color: #d63384 !important
}

.text-yellow {
    color: #fc0 !important
}

.text-teal {
    color: #20c997 !important
}

.text-muted {
    color: #869ac0 !important
}

.text-light {
    color: #ebeef4 !important
}

.text-gray-100 {
    color: #ebeef4 !important
}

.text-gray-200 {
    color: #dae0ec !important
}

.text-gray-300 {
    color: #c9d2e3 !important
}

.text-gray-400 {
    color: #a8b6d1 !important
}

.text-gray-500 {
    color: #869ac0 !important
}

.text-gray-600 {
    color: #657eae !important
}

.text-gray-700 {
    color: #4d6593 !important
}

.text-gray-800 {
    color: #3c4e71 !important
}

.text-gray-900 {
    color: #212837 !important
}

.text-body {
    color: #212837 !important
}

.text-black-50 {
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    color: rgba(255, 255, 255, .5) !important
}

.text-reset {
    color: inherit !important
}

.lh-1 {
    line-height: 1 !important
}

.lh-sm {
    line-height: 1.25 !important
}

.lh-base {
    line-height: 1.5 !important
}

.lh-lg {
    line-height: 2 !important
}

.bg-primary {
    background-color: #1f6bff !important
}

.bg-secondary {
    background-color: #c9d2e3 !important
}

.bg-warning {
    background-color: #ff9500 !important
}

.bg-danger {
    background-color: #e6180d !important
}

.bg-success {
    background-color: #028824 !important
}

.bg-info {
    background-color: #30beff !important
}

.bg-dark {
    background-color: #212837 !important
}

.bg-black {
    background-color: #000 !important
}

.bg-white {
    background-color: #fff !important
}

.bg-purple {
    background-color: #6f42c1 !important
}

.bg-indigo {
    background-color: #640df3 !important
}

.bg-pink {
    background-color: #d63384 !important
}

.bg-yellow {
    background-color: #fc0 !important
}

.bg-teal {
    background-color: #20c997 !important
}

.bg-muted {
    background-color: #869ac0 !important
}

.bg-light {
    background-color: #ebeef4 !important
}

.bg-gray-100 {
    background-color: #ebeef4 !important
}

.bg-gray-200 {
    background-color: #dae0ec !important
}

.bg-gray-300 {
    background-color: #c9d2e3 !important
}

.bg-gray-400 {
    background-color: #a8b6d1 !important
}

.bg-gray-500 {
    background-color: #869ac0 !important
}

.bg-gray-600 {
    background-color: #657eae !important
}

.bg-gray-700 {
    background-color: #4d6593 !important
}

.bg-gray-800 {
    background-color: #3c4e71 !important
}

.bg-gray-900 {
    background-color: #212837 !important
}

.bg-body {
    background-color: #ebeef4 !important
}

.bg-transparent {
    background-color: transparent !important
}

.bg-gradient {
    background-image: var(--bs-gradient) !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.font-monospace {
    font-family: var(--bs-font-monospace) !important
}

.user-select-all {
    user-select: all !important
}

.user-select-auto {
    user-select: auto !important
}

.user-select-none {
    user-select: none !important
}

.pe-none {
    pointer-events: none !important
}

.pe-auto {
    pointer-events: auto !important
}

.rounded {
    border-radius: 6px !important
}

.rounded-0 {
    border-radius: 0 !important
}

.rounded-1 {
    border-radius: 3px !important
}

.rounded-2 {
    border-radius: 6px !important
}

.rounded-3 {
    border-radius: 9px !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-top {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important
}

.rounded-end {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important
}

.rounded-bottom {
    border-bottom-right-radius: 6px !important;
    border-bottom-left-radius: 6px !important
}

.rounded-start {
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media (min-width:576px) {
    .float-sm-start {
        float: left !important
    }

    .float-sm-end {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-grid {
        display: grid !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }

    .d-sm-none {
        display: none !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-sm-0 {
        gap: 0 !important
    }

    .gap-sm-1 {
        gap: .25rem !important
    }

    .gap-sm-2 {
        gap: .5rem !important
    }

    .gap-sm-3 {
        gap: 1rem !important
    }

    .gap-sm-4 {
        gap: 1.5rem !important
    }

    .gap-sm-5 {
        gap: 3rem !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .justify-content-sm-evenly {
        justify-content: space-evenly !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }

    .order-sm-first {
        order: -1 !important
    }

    .order-sm-0 {
        order: 0 !important
    }

    .order-sm-1 {
        order: 1 !important
    }

    .order-sm-2 {
        order: 2 !important
    }

    .order-sm-3 {
        order: 3 !important
    }

    .order-sm-4 {
        order: 4 !important
    }

    .order-sm-5 {
        order: 5 !important
    }

    .order-sm-last {
        order: 6 !important
    }

    .m-sm-0 {
        margin: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-sm-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-sm-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-sm-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-sm-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-sm-0 {
        margin-top: 0 !important
    }

    .mt-sm-1 {
        margin-top: .25rem !important
    }

    .mt-sm-2 {
        margin-top: .5rem !important
    }

    .mt-sm-3 {
        margin-top: 1rem !important
    }

    .mt-sm-4 {
        margin-top: 1.5rem !important
    }

    .mt-sm-5 {
        margin-top: 3rem !important
    }

    .mt-sm-auto {
        margin-top: auto !important
    }

    .me-sm-0 {
        margin-right: 0 !important
    }

    .me-sm-1 {
        margin-right: .25rem !important
    }

    .me-sm-2 {
        margin-right: .5rem !important
    }

    .me-sm-3 {
        margin-right: 1rem !important
    }

    .me-sm-4 {
        margin-right: 1.5rem !important
    }

    .me-sm-5 {
        margin-right: 3rem !important
    }

    .me-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-0 {
        margin-bottom: 0 !important
    }

    .mb-sm-1 {
        margin-bottom: .25rem !important
    }

    .mb-sm-2 {
        margin-bottom: .5rem !important
    }

    .mb-sm-3 {
        margin-bottom: 1rem !important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-sm-5 {
        margin-bottom: 3rem !important
    }

    .mb-sm-auto {
        margin-bottom: auto !important
    }

    .ms-sm-0 {
        margin-left: 0 !important
    }

    .ms-sm-1 {
        margin-left: .25rem !important
    }

    .ms-sm-2 {
        margin-left: .5rem !important
    }

    .ms-sm-3 {
        margin-left: 1rem !important
    }

    .ms-sm-4 {
        margin-left: 1.5rem !important
    }

    .ms-sm-5 {
        margin-left: 3rem !important
    }

    .ms-sm-auto {
        margin-left: auto !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .m-sm-n3 {
        margin: -1rem !important
    }

    .m-sm-n4 {
        margin: -1.5rem !important
    }

    .m-sm-n5 {
        margin: -3rem !important
    }

    .mx-sm-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-sm-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-sm-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-sm-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-sm-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-sm-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-sm-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-sm-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-sm-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-sm-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-sm-n1 {
        margin-top: -.25rem !important
    }

    .mt-sm-n2 {
        margin-top: -.5rem !important
    }

    .mt-sm-n3 {
        margin-top: -1rem !important
    }

    .mt-sm-n4 {
        margin-top: -1.5rem !important
    }

    .mt-sm-n5 {
        margin-top: -3rem !important
    }

    .me-sm-n1 {
        margin-right: -.25rem !important
    }

    .me-sm-n2 {
        margin-right: -.5rem !important
    }

    .me-sm-n3 {
        margin-right: -1rem !important
    }

    .me-sm-n4 {
        margin-right: -1.5rem !important
    }

    .me-sm-n5 {
        margin-right: -3rem !important
    }

    .mb-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-sm-n3 {
        margin-bottom: -1rem !important
    }

    .mb-sm-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-sm-n5 {
        margin-bottom: -3rem !important
    }

    .ms-sm-n1 {
        margin-left: -.25rem !important
    }

    .ms-sm-n2 {
        margin-left: -.5rem !important
    }

    .ms-sm-n3 {
        margin-left: -1rem !important
    }

    .ms-sm-n4 {
        margin-left: -1.5rem !important
    }

    .ms-sm-n5 {
        margin-left: -3rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-sm-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-sm-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-sm-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-sm-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-sm-0 {
        padding-top: 0 !important
    }

    .pt-sm-1 {
        padding-top: .25rem !important
    }

    .pt-sm-2 {
        padding-top: .5rem !important
    }

    .pt-sm-3 {
        padding-top: 1rem !important
    }

    .pt-sm-4 {
        padding-top: 1.5rem !important
    }

    .pt-sm-5 {
        padding-top: 3rem !important
    }

    .pe-sm-0 {
        padding-right: 0 !important
    }

    .pe-sm-1 {
        padding-right: .25rem !important
    }

    .pe-sm-2 {
        padding-right: .5rem !important
    }

    .pe-sm-3 {
        padding-right: 1rem !important
    }

    .pe-sm-4 {
        padding-right: 1.5rem !important
    }

    .pe-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-0 {
        padding-bottom: 0 !important
    }

    .pb-sm-1 {
        padding-bottom: .25rem !important
    }

    .pb-sm-2 {
        padding-bottom: .5rem !important
    }

    .pb-sm-3 {
        padding-bottom: 1rem !important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-sm-5 {
        padding-bottom: 3rem !important
    }

    .ps-sm-0 {
        padding-left: 0 !important
    }

    .ps-sm-1 {
        padding-left: .25rem !important
    }

    .ps-sm-2 {
        padding-left: .5rem !important
    }

    .ps-sm-3 {
        padding-left: 1rem !important
    }

    .ps-sm-4 {
        padding-left: 1.5rem !important
    }

    .ps-sm-5 {
        padding-left: 3rem !important
    }

    .text-sm-start {
        text-align: left !important
    }

    .text-sm-end {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .float-md-start {
        float: left !important
    }

    .float-md-end {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-grid {
        display: grid !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }

    .d-md-none {
        display: none !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-md-0 {
        gap: 0 !important
    }

    .gap-md-1 {
        gap: .25rem !important
    }

    .gap-md-2 {
        gap: .5rem !important
    }

    .gap-md-3 {
        gap: 1rem !important
    }

    .gap-md-4 {
        gap: 1.5rem !important
    }

    .gap-md-5 {
        gap: 3rem !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .justify-content-md-evenly {
        justify-content: space-evenly !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }

    .order-md-first {
        order: -1 !important
    }

    .order-md-0 {
        order: 0 !important
    }

    .order-md-1 {
        order: 1 !important
    }

    .order-md-2 {
        order: 2 !important
    }

    .order-md-3 {
        order: 3 !important
    }

    .order-md-4 {
        order: 4 !important
    }

    .order-md-5 {
        order: 5 !important
    }

    .order-md-last {
        order: 6 !important
    }

    .m-md-0 {
        margin: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-md-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-md-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-md-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-md-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-md-0 {
        margin-top: 0 !important
    }

    .mt-md-1 {
        margin-top: .25rem !important
    }

    .mt-md-2 {
        margin-top: .5rem !important
    }

    .mt-md-3 {
        margin-top: 1rem !important
    }

    .mt-md-4 {
        margin-top: 1.5rem !important
    }

    .mt-md-5 {
        margin-top: 3rem !important
    }

    .mt-md-auto {
        margin-top: auto !important
    }

    .me-md-0 {
        margin-right: 0 !important
    }

    .me-md-1 {
        margin-right: .25rem !important
    }

    .me-md-2 {
        margin-right: .5rem !important
    }

    .me-md-3 {
        margin-right: 1rem !important
    }

    .me-md-4 {
        margin-right: 1.5rem !important
    }

    .me-md-5 {
        margin-right: 3rem !important
    }

    .me-md-auto {
        margin-right: auto !important
    }

    .mb-md-0 {
        margin-bottom: 0 !important
    }

    .mb-md-1 {
        margin-bottom: .25rem !important
    }

    .mb-md-2 {
        margin-bottom: .5rem !important
    }

    .mb-md-3 {
        margin-bottom: 1rem !important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-md-5 {
        margin-bottom: 3rem !important
    }

    .mb-md-auto {
        margin-bottom: auto !important
    }

    .ms-md-0 {
        margin-left: 0 !important
    }

    .ms-md-1 {
        margin-left: .25rem !important
    }

    .ms-md-2 {
        margin-left: .5rem !important
    }

    .ms-md-3 {
        margin-left: 1rem !important
    }

    .ms-md-4 {
        margin-left: 1.5rem !important
    }

    .ms-md-5 {
        margin-left: 3rem !important
    }

    .ms-md-auto {
        margin-left: auto !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .m-md-n3 {
        margin: -1rem !important
    }

    .m-md-n4 {
        margin: -1.5rem !important
    }

    .m-md-n5 {
        margin: -3rem !important
    }

    .mx-md-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-md-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-md-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-md-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-md-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-md-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-md-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-md-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-md-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-md-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-md-n1 {
        margin-top: -.25rem !important
    }

    .mt-md-n2 {
        margin-top: -.5rem !important
    }

    .mt-md-n3 {
        margin-top: -1rem !important
    }

    .mt-md-n4 {
        margin-top: -1.5rem !important
    }

    .mt-md-n5 {
        margin-top: -3rem !important
    }

    .me-md-n1 {
        margin-right: -.25rem !important
    }

    .me-md-n2 {
        margin-right: -.5rem !important
    }

    .me-md-n3 {
        margin-right: -1rem !important
    }

    .me-md-n4 {
        margin-right: -1.5rem !important
    }

    .me-md-n5 {
        margin-right: -3rem !important
    }

    .mb-md-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-md-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-md-n3 {
        margin-bottom: -1rem !important
    }

    .mb-md-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-md-n5 {
        margin-bottom: -3rem !important
    }

    .ms-md-n1 {
        margin-left: -.25rem !important
    }

    .ms-md-n2 {
        margin-left: -.5rem !important
    }

    .ms-md-n3 {
        margin-left: -1rem !important
    }

    .ms-md-n4 {
        margin-left: -1.5rem !important
    }

    .ms-md-n5 {
        margin-left: -3rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-md-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-md-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-md-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-md-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-md-0 {
        padding-top: 0 !important
    }

    .pt-md-1 {
        padding-top: .25rem !important
    }

    .pt-md-2 {
        padding-top: .5rem !important
    }

    .pt-md-3 {
        padding-top: 1rem !important
    }

    .pt-md-4 {
        padding-top: 1.5rem !important
    }

    .pt-md-5 {
        padding-top: 3rem !important
    }

    .pe-md-0 {
        padding-right: 0 !important
    }

    .pe-md-1 {
        padding-right: .25rem !important
    }

    .pe-md-2 {
        padding-right: .5rem !important
    }

    .pe-md-3 {
        padding-right: 1rem !important
    }

    .pe-md-4 {
        padding-right: 1.5rem !important
    }

    .pe-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-0 {
        padding-bottom: 0 !important
    }

    .pb-md-1 {
        padding-bottom: .25rem !important
    }

    .pb-md-2 {
        padding-bottom: .5rem !important
    }

    .pb-md-3 {
        padding-bottom: 1rem !important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-md-5 {
        padding-bottom: 3rem !important
    }

    .ps-md-0 {
        padding-left: 0 !important
    }

    .ps-md-1 {
        padding-left: .25rem !important
    }

    .ps-md-2 {
        padding-left: .5rem !important
    }

    .ps-md-3 {
        padding-left: 1rem !important
    }

    .ps-md-4 {
        padding-left: 1.5rem !important
    }

    .ps-md-5 {
        padding-left: 3rem !important
    }

    .text-md-start {
        text-align: left !important
    }

    .text-md-end {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:992px) {
    .float-lg-start {
        float: left !important
    }

    .float-lg-end {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-grid {
        display: grid !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }

    .d-lg-none {
        display: none !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-lg-0 {
        gap: 0 !important
    }

    .gap-lg-1 {
        gap: .25rem !important
    }

    .gap-lg-2 {
        gap: .5rem !important
    }

    .gap-lg-3 {
        gap: 1rem !important
    }

    .gap-lg-4 {
        gap: 1.5rem !important
    }

    .gap-lg-5 {
        gap: 3rem !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .justify-content-lg-evenly {
        justify-content: space-evenly !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }

    .order-lg-first {
        order: -1 !important
    }

    .order-lg-0 {
        order: 0 !important
    }

    .order-lg-1 {
        order: 1 !important
    }

    .order-lg-2 {
        order: 2 !important
    }

    .order-lg-3 {
        order: 3 !important
    }

    .order-lg-4 {
        order: 4 !important
    }

    .order-lg-5 {
        order: 5 !important
    }

    .order-lg-last {
        order: 6 !important
    }

    .m-lg-0 {
        margin: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-lg-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-lg-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-lg-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-lg-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-lg-0 {
        margin-top: 0 !important
    }

    .mt-lg-1 {
        margin-top: .25rem !important
    }

    .mt-lg-2 {
        margin-top: .5rem !important
    }

    .mt-lg-3 {
        margin-top: 1rem !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mt-lg-5 {
        margin-top: 3rem !important
    }

    .mt-lg-auto {
        margin-top: auto !important
    }

    .me-lg-0 {
        margin-right: 0 !important
    }

    .me-lg-1 {
        margin-right: .25rem !important
    }

    .me-lg-2 {
        margin-right: .5rem !important
    }

    .me-lg-3 {
        margin-right: 1rem !important
    }

    .me-lg-4 {
        margin-right: 1.5rem !important
    }

    .me-lg-5 {
        margin-right: 3rem !important
    }

    .me-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mb-lg-1 {
        margin-bottom: .25rem !important
    }

    .mb-lg-2 {
        margin-bottom: .5rem !important
    }

    .mb-lg-3 {
        margin-bottom: 1rem !important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-lg-5 {
        margin-bottom: 3rem !important
    }

    .mb-lg-auto {
        margin-bottom: auto !important
    }

    .ms-lg-0 {
        margin-left: 0 !important
    }

    .ms-lg-1 {
        margin-left: .25rem !important
    }

    .ms-lg-2 {
        margin-left: .5rem !important
    }

    .ms-lg-3 {
        margin-left: 1rem !important
    }

    .ms-lg-4 {
        margin-left: 1.5rem !important
    }

    .ms-lg-5 {
        margin-left: 3rem !important
    }

    .ms-lg-auto {
        margin-left: auto !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .m-lg-n3 {
        margin: -1rem !important
    }

    .m-lg-n4 {
        margin: -1.5rem !important
    }

    .m-lg-n5 {
        margin: -3rem !important
    }

    .mx-lg-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-lg-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-lg-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-lg-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-lg-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-lg-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-lg-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-lg-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-lg-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-lg-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-lg-n1 {
        margin-top: -.25rem !important
    }

    .mt-lg-n2 {
        margin-top: -.5rem !important
    }

    .mt-lg-n3 {
        margin-top: -1rem !important
    }

    .mt-lg-n4 {
        margin-top: -1.5rem !important
    }

    .mt-lg-n5 {
        margin-top: -3rem !important
    }

    .me-lg-n1 {
        margin-right: -.25rem !important
    }

    .me-lg-n2 {
        margin-right: -.5rem !important
    }

    .me-lg-n3 {
        margin-right: -1rem !important
    }

    .me-lg-n4 {
        margin-right: -1.5rem !important
    }

    .me-lg-n5 {
        margin-right: -3rem !important
    }

    .mb-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-lg-n3 {
        margin-bottom: -1rem !important
    }

    .mb-lg-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-lg-n5 {
        margin-bottom: -3rem !important
    }

    .ms-lg-n1 {
        margin-left: -.25rem !important
    }

    .ms-lg-n2 {
        margin-left: -.5rem !important
    }

    .ms-lg-n3 {
        margin-left: -1rem !important
    }

    .ms-lg-n4 {
        margin-left: -1.5rem !important
    }

    .ms-lg-n5 {
        margin-left: -3rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-lg-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-lg-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-lg-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-lg-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-lg-0 {
        padding-top: 0 !important
    }

    .pt-lg-1 {
        padding-top: .25rem !important
    }

    .pt-lg-2 {
        padding-top: .5rem !important
    }

    .pt-lg-3 {
        padding-top: 1rem !important
    }

    .pt-lg-4 {
        padding-top: 1.5rem !important
    }

    .pt-lg-5 {
        padding-top: 3rem !important
    }

    .pe-lg-0 {
        padding-right: 0 !important
    }

    .pe-lg-1 {
        padding-right: .25rem !important
    }

    .pe-lg-2 {
        padding-right: .5rem !important
    }

    .pe-lg-3 {
        padding-right: 1rem !important
    }

    .pe-lg-4 {
        padding-right: 1.5rem !important
    }

    .pe-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-0 {
        padding-bottom: 0 !important
    }

    .pb-lg-1 {
        padding-bottom: .25rem !important
    }

    .pb-lg-2 {
        padding-bottom: .5rem !important
    }

    .pb-lg-3 {
        padding-bottom: 1rem !important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-lg-5 {
        padding-bottom: 3rem !important
    }

    .ps-lg-0 {
        padding-left: 0 !important
    }

    .ps-lg-1 {
        padding-left: .25rem !important
    }

    .ps-lg-2 {
        padding-left: .5rem !important
    }

    .ps-lg-3 {
        padding-left: 1rem !important
    }

    .ps-lg-4 {
        padding-left: 1.5rem !important
    }

    .ps-lg-5 {
        padding-left: 3rem !important
    }

    .text-lg-start {
        text-align: left !important
    }

    .text-lg-end {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .float-xl-start {
        float: left !important
    }

    .float-xl-end {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-grid {
        display: grid !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }

    .d-xl-none {
        display: none !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-xl-0 {
        gap: 0 !important
    }

    .gap-xl-1 {
        gap: .25rem !important
    }

    .gap-xl-2 {
        gap: .5rem !important
    }

    .gap-xl-3 {
        gap: 1rem !important
    }

    .gap-xl-4 {
        gap: 1.5rem !important
    }

    .gap-xl-5 {
        gap: 3rem !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .justify-content-xl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }

    .order-xl-first {
        order: -1 !important
    }

    .order-xl-0 {
        order: 0 !important
    }

    .order-xl-1 {
        order: 1 !important
    }

    .order-xl-2 {
        order: 2 !important
    }

    .order-xl-3 {
        order: 3 !important
    }

    .order-xl-4 {
        order: 4 !important
    }

    .order-xl-5 {
        order: 5 !important
    }

    .order-xl-last {
        order: 6 !important
    }

    .m-xl-0 {
        margin: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xl-0 {
        margin-top: 0 !important
    }

    .mt-xl-1 {
        margin-top: .25rem !important
    }

    .mt-xl-2 {
        margin-top: .5rem !important
    }

    .mt-xl-3 {
        margin-top: 1rem !important
    }

    .mt-xl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xl-5 {
        margin-top: 3rem !important
    }

    .mt-xl-auto {
        margin-top: auto !important
    }

    .me-xl-0 {
        margin-right: 0 !important
    }

    .me-xl-1 {
        margin-right: .25rem !important
    }

    .me-xl-2 {
        margin-right: .5rem !important
    }

    .me-xl-3 {
        margin-right: 1rem !important
    }

    .me-xl-4 {
        margin-right: 1.5rem !important
    }

    .me-xl-5 {
        margin-right: 3rem !important
    }

    .me-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-0 {
        margin-bottom: 0 !important
    }

    .mb-xl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xl-auto {
        margin-bottom: auto !important
    }

    .ms-xl-0 {
        margin-left: 0 !important
    }

    .ms-xl-1 {
        margin-left: .25rem !important
    }

    .ms-xl-2 {
        margin-left: .5rem !important
    }

    .ms-xl-3 {
        margin-left: 1rem !important
    }

    .ms-xl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xl-5 {
        margin-left: 3rem !important
    }

    .ms-xl-auto {
        margin-left: auto !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .m-xl-n3 {
        margin: -1rem !important
    }

    .m-xl-n4 {
        margin: -1.5rem !important
    }

    .m-xl-n5 {
        margin: -3rem !important
    }

    .mx-xl-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-xl-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-xl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-xl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-xl-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-xl-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-xl-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-xl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-xl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-xl-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-xl-n1 {
        margin-top: -.25rem !important
    }

    .mt-xl-n2 {
        margin-top: -.5rem !important
    }

    .mt-xl-n3 {
        margin-top: -1rem !important
    }

    .mt-xl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xl-n5 {
        margin-top: -3rem !important
    }

    .me-xl-n1 {
        margin-right: -.25rem !important
    }

    .me-xl-n2 {
        margin-right: -.5rem !important
    }

    .me-xl-n3 {
        margin-right: -1rem !important
    }

    .me-xl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xl-n5 {
        margin-right: -3rem !important
    }

    .mb-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-xl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xl-n5 {
        margin-bottom: -3rem !important
    }

    .ms-xl-n1 {
        margin-left: -.25rem !important
    }

    .ms-xl-n2 {
        margin-left: -.5rem !important
    }

    .ms-xl-n3 {
        margin-left: -1rem !important
    }

    .ms-xl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xl-n5 {
        margin-left: -3rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xl-0 {
        padding-top: 0 !important
    }

    .pt-xl-1 {
        padding-top: .25rem !important
    }

    .pt-xl-2 {
        padding-top: .5rem !important
    }

    .pt-xl-3 {
        padding-top: 1rem !important
    }

    .pt-xl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xl-5 {
        padding-top: 3rem !important
    }

    .pe-xl-0 {
        padding-right: 0 !important
    }

    .pe-xl-1 {
        padding-right: .25rem !important
    }

    .pe-xl-2 {
        padding-right: .5rem !important
    }

    .pe-xl-3 {
        padding-right: 1rem !important
    }

    .pe-xl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-0 {
        padding-bottom: 0 !important
    }

    .pb-xl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xl-0 {
        padding-left: 0 !important
    }

    .ps-xl-1 {
        padding-left: .25rem !important
    }

    .ps-xl-2 {
        padding-left: .5rem !important
    }

    .ps-xl-3 {
        padding-left: 1rem !important
    }

    .ps-xl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xl-5 {
        padding-left: 3rem !important
    }

    .text-xl-start {
        text-align: left !important
    }

    .text-xl-end {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

@media (min-width:1660px) {
    .float-xxl-start {
        float: left !important
    }

    .float-xxl-end {
        float: right !important
    }

    .float-xxl-none {
        float: none !important
    }

    .d-xxl-inline {
        display: inline !important
    }

    .d-xxl-inline-block {
        display: inline-block !important
    }

    .d-xxl-block {
        display: block !important
    }

    .d-xxl-grid {
        display: grid !important
    }

    .d-xxl-table {
        display: table !important
    }

    .d-xxl-table-row {
        display: table-row !important
    }

    .d-xxl-table-cell {
        display: table-cell !important
    }

    .d-xxl-flex {
        display: flex !important
    }

    .d-xxl-inline-flex {
        display: inline-flex !important
    }

    .d-xxl-none {
        display: none !important
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important
    }

    .flex-xxl-row {
        flex-direction: row !important
    }

    .flex-xxl-column {
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-xxl-0 {
        gap: 0 !important
    }

    .gap-xxl-1 {
        gap: .25rem !important
    }

    .gap-xxl-2 {
        gap: .5rem !important
    }

    .gap-xxl-3 {
        gap: 1rem !important
    }

    .gap-xxl-4 {
        gap: 1.5rem !important
    }

    .gap-xxl-5 {
        gap: 3rem !important
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        justify-content: center !important
    }

    .justify-content-xxl-between {
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        justify-content: space-around !important
    }

    .justify-content-xxl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xxl-start {
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        align-items: center !important
    }

    .align-items-xxl-baseline {
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        align-items: stretch !important
    }

    .align-content-xxl-start {
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        align-content: center !important
    }

    .align-content-xxl-between {
        align-content: space-between !important
    }

    .align-content-xxl-around {
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        align-self: auto !important
    }

    .align-self-xxl-start {
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        align-self: center !important
    }

    .align-self-xxl-baseline {
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        align-self: stretch !important
    }

    .order-xxl-first {
        order: -1 !important
    }

    .order-xxl-0 {
        order: 0 !important
    }

    .order-xxl-1 {
        order: 1 !important
    }

    .order-xxl-2 {
        order: 2 !important
    }

    .order-xxl-3 {
        order: 3 !important
    }

    .order-xxl-4 {
        order: 4 !important
    }

    .order-xxl-5 {
        order: 5 !important
    }

    .order-xxl-last {
        order: 6 !important
    }

    .m-xxl-0 {
        margin: 0 !important
    }

    .m-xxl-1 {
        margin: .25rem !important
    }

    .m-xxl-2 {
        margin: .5rem !important
    }

    .m-xxl-3 {
        margin: 1rem !important
    }

    .m-xxl-4 {
        margin: 1.5rem !important
    }

    .m-xxl-5 {
        margin: 3rem !important
    }

    .m-xxl-auto {
        margin: auto !important
    }

    .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xxl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xxl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xxl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xxl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xxl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xxl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xxl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xxl-0 {
        margin-top: 0 !important
    }

    .mt-xxl-1 {
        margin-top: .25rem !important
    }

    .mt-xxl-2 {
        margin-top: .5rem !important
    }

    .mt-xxl-3 {
        margin-top: 1rem !important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xxl-5 {
        margin-top: 3rem !important
    }

    .mt-xxl-auto {
        margin-top: auto !important
    }

    .me-xxl-0 {
        margin-right: 0 !important
    }

    .me-xxl-1 {
        margin-right: .25rem !important
    }

    .me-xxl-2 {
        margin-right: .5rem !important
    }

    .me-xxl-3 {
        margin-right: 1rem !important
    }

    .me-xxl-4 {
        margin-right: 1.5rem !important
    }

    .me-xxl-5 {
        margin-right: 3rem !important
    }

    .me-xxl-auto {
        margin-right: auto !important
    }

    .mb-xxl-0 {
        margin-bottom: 0 !important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xxl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xxl-auto {
        margin-bottom: auto !important
    }

    .ms-xxl-0 {
        margin-left: 0 !important
    }

    .ms-xxl-1 {
        margin-left: .25rem !important
    }

    .ms-xxl-2 {
        margin-left: .5rem !important
    }

    .ms-xxl-3 {
        margin-left: 1rem !important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xxl-5 {
        margin-left: 3rem !important
    }

    .ms-xxl-auto {
        margin-left: auto !important
    }

    .m-xxl-n1 {
        margin: -.25rem !important
    }

    .m-xxl-n2 {
        margin: -.5rem !important
    }

    .m-xxl-n3 {
        margin: -1rem !important
    }

    .m-xxl-n4 {
        margin: -1.5rem !important
    }

    .m-xxl-n5 {
        margin: -3rem !important
    }

    .mx-xxl-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-xxl-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-xxl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-xxl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-xxl-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-xxl-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-xxl-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-xxl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-xxl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-xxl-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-xxl-n1 {
        margin-top: -.25rem !important
    }

    .mt-xxl-n2 {
        margin-top: -.5rem !important
    }

    .mt-xxl-n3 {
        margin-top: -1rem !important
    }

    .mt-xxl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xxl-n5 {
        margin-top: -3rem !important
    }

    .me-xxl-n1 {
        margin-right: -.25rem !important
    }

    .me-xxl-n2 {
        margin-right: -.5rem !important
    }

    .me-xxl-n3 {
        margin-right: -1rem !important
    }

    .me-xxl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xxl-n5 {
        margin-right: -3rem !important
    }

    .mb-xxl-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-xxl-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-xxl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xxl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xxl-n5 {
        margin-bottom: -3rem !important
    }

    .ms-xxl-n1 {
        margin-left: -.25rem !important
    }

    .ms-xxl-n2 {
        margin-left: -.5rem !important
    }

    .ms-xxl-n3 {
        margin-left: -1rem !important
    }

    .ms-xxl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xxl-n5 {
        margin-left: -3rem !important
    }

    .p-xxl-0 {
        padding: 0 !important
    }

    .p-xxl-1 {
        padding: .25rem !important
    }

    .p-xxl-2 {
        padding: .5rem !important
    }

    .p-xxl-3 {
        padding: 1rem !important
    }

    .p-xxl-4 {
        padding: 1.5rem !important
    }

    .p-xxl-5 {
        padding: 3rem !important
    }

    .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xxl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xxl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xxl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xxl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xxl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xxl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xxl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xxl-0 {
        padding-top: 0 !important
    }

    .pt-xxl-1 {
        padding-top: .25rem !important
    }

    .pt-xxl-2 {
        padding-top: .5rem !important
    }

    .pt-xxl-3 {
        padding-top: 1rem !important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xxl-5 {
        padding-top: 3rem !important
    }

    .pe-xxl-0 {
        padding-right: 0 !important
    }

    .pe-xxl-1 {
        padding-right: .25rem !important
    }

    .pe-xxl-2 {
        padding-right: .5rem !important
    }

    .pe-xxl-3 {
        padding-right: 1rem !important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xxl-5 {
        padding-right: 3rem !important
    }

    .pb-xxl-0 {
        padding-bottom: 0 !important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xxl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xxl-0 {
        padding-left: 0 !important
    }

    .ps-xxl-1 {
        padding-left: .25rem !important
    }

    .ps-xxl-2 {
        padding-left: .5rem !important
    }

    .ps-xxl-3 {
        padding-left: 1rem !important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xxl-5 {
        padding-left: 3rem !important
    }

    .text-xxl-start {
        text-align: left !important
    }

    .text-xxl-end {
        text-align: right !important
    }

    .text-xxl-center {
        text-align: center !important
    }
}

@media (min-width:1900px) {
    .float-xxxl-start {
        float: left !important
    }

    .float-xxxl-end {
        float: right !important
    }

    .float-xxxl-none {
        float: none !important
    }

    .d-xxxl-inline {
        display: inline !important
    }

    .d-xxxl-inline-block {
        display: inline-block !important
    }

    .d-xxxl-block {
        display: block !important
    }

    .d-xxxl-grid {
        display: grid !important
    }

    .d-xxxl-table {
        display: table !important
    }

    .d-xxxl-table-row {
        display: table-row !important
    }

    .d-xxxl-table-cell {
        display: table-cell !important
    }

    .d-xxxl-flex {
        display: flex !important
    }

    .d-xxxl-inline-flex {
        display: inline-flex !important
    }

    .d-xxxl-none {
        display: none !important
    }

    .flex-xxxl-fill {
        flex: 1 1 auto !important
    }

    .flex-xxxl-row {
        flex-direction: row !important
    }

    .flex-xxxl-column {
        flex-direction: column !important
    }

    .flex-xxxl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xxxl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xxxl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xxxl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xxxl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xxxl-shrink-1 {
        flex-shrink: 1 !important
    }

    .flex-xxxl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xxxl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xxxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .gap-xxxl-0 {
        gap: 0 !important
    }

    .gap-xxxl-1 {
        gap: .25rem !important
    }

    .gap-xxxl-2 {
        gap: .5rem !important
    }

    .gap-xxxl-3 {
        gap: 1rem !important
    }

    .gap-xxxl-4 {
        gap: 1.5rem !important
    }

    .gap-xxxl-5 {
        gap: 3rem !important
    }

    .justify-content-xxxl-start {
        justify-content: flex-start !important
    }

    .justify-content-xxxl-end {
        justify-content: flex-end !important
    }

    .justify-content-xxxl-center {
        justify-content: center !important
    }

    .justify-content-xxxl-between {
        justify-content: space-between !important
    }

    .justify-content-xxxl-around {
        justify-content: space-around !important
    }

    .justify-content-xxxl-evenly {
        justify-content: space-evenly !important
    }

    .align-items-xxxl-start {
        align-items: flex-start !important
    }

    .align-items-xxxl-end {
        align-items: flex-end !important
    }

    .align-items-xxxl-center {
        align-items: center !important
    }

    .align-items-xxxl-baseline {
        align-items: baseline !important
    }

    .align-items-xxxl-stretch {
        align-items: stretch !important
    }

    .align-content-xxxl-start {
        align-content: flex-start !important
    }

    .align-content-xxxl-end {
        align-content: flex-end !important
    }

    .align-content-xxxl-center {
        align-content: center !important
    }

    .align-content-xxxl-between {
        align-content: space-between !important
    }

    .align-content-xxxl-around {
        align-content: space-around !important
    }

    .align-content-xxxl-stretch {
        align-content: stretch !important
    }

    .align-self-xxxl-auto {
        align-self: auto !important
    }

    .align-self-xxxl-start {
        align-self: flex-start !important
    }

    .align-self-xxxl-end {
        align-self: flex-end !important
    }

    .align-self-xxxl-center {
        align-self: center !important
    }

    .align-self-xxxl-baseline {
        align-self: baseline !important
    }

    .align-self-xxxl-stretch {
        align-self: stretch !important
    }

    .order-xxxl-first {
        order: -1 !important
    }

    .order-xxxl-0 {
        order: 0 !important
    }

    .order-xxxl-1 {
        order: 1 !important
    }

    .order-xxxl-2 {
        order: 2 !important
    }

    .order-xxxl-3 {
        order: 3 !important
    }

    .order-xxxl-4 {
        order: 4 !important
    }

    .order-xxxl-5 {
        order: 5 !important
    }

    .order-xxxl-last {
        order: 6 !important
    }

    .m-xxxl-0 {
        margin: 0 !important
    }

    .m-xxxl-1 {
        margin: .25rem !important
    }

    .m-xxxl-2 {
        margin: .5rem !important
    }

    .m-xxxl-3 {
        margin: 1rem !important
    }

    .m-xxxl-4 {
        margin: 1.5rem !important
    }

    .m-xxxl-5 {
        margin: 3rem !important
    }

    .m-xxxl-auto {
        margin: auto !important
    }

    .mx-xxxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .mx-xxxl-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }

    .mx-xxxl-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }

    .mx-xxxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }

    .mx-xxxl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }

    .mx-xxxl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }

    .mx-xxxl-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }

    .my-xxxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .my-xxxl-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }

    .my-xxxl-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }

    .my-xxxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }

    .my-xxxl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }

    .my-xxxl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }

    .my-xxxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .mt-xxxl-0 {
        margin-top: 0 !important
    }

    .mt-xxxl-1 {
        margin-top: .25rem !important
    }

    .mt-xxxl-2 {
        margin-top: .5rem !important
    }

    .mt-xxxl-3 {
        margin-top: 1rem !important
    }

    .mt-xxxl-4 {
        margin-top: 1.5rem !important
    }

    .mt-xxxl-5 {
        margin-top: 3rem !important
    }

    .mt-xxxl-auto {
        margin-top: auto !important
    }

    .me-xxxl-0 {
        margin-right: 0 !important
    }

    .me-xxxl-1 {
        margin-right: .25rem !important
    }

    .me-xxxl-2 {
        margin-right: .5rem !important
    }

    .me-xxxl-3 {
        margin-right: 1rem !important
    }

    .me-xxxl-4 {
        margin-right: 1.5rem !important
    }

    .me-xxxl-5 {
        margin-right: 3rem !important
    }

    .me-xxxl-auto {
        margin-right: auto !important
    }

    .mb-xxxl-0 {
        margin-bottom: 0 !important
    }

    .mb-xxxl-1 {
        margin-bottom: .25rem !important
    }

    .mb-xxxl-2 {
        margin-bottom: .5rem !important
    }

    .mb-xxxl-3 {
        margin-bottom: 1rem !important
    }

    .mb-xxxl-4 {
        margin-bottom: 1.5rem !important
    }

    .mb-xxxl-5 {
        margin-bottom: 3rem !important
    }

    .mb-xxxl-auto {
        margin-bottom: auto !important
    }

    .ms-xxxl-0 {
        margin-left: 0 !important
    }

    .ms-xxxl-1 {
        margin-left: .25rem !important
    }

    .ms-xxxl-2 {
        margin-left: .5rem !important
    }

    .ms-xxxl-3 {
        margin-left: 1rem !important
    }

    .ms-xxxl-4 {
        margin-left: 1.5rem !important
    }

    .ms-xxxl-5 {
        margin-left: 3rem !important
    }

    .ms-xxxl-auto {
        margin-left: auto !important
    }

    .m-xxxl-n1 {
        margin: -.25rem !important
    }

    .m-xxxl-n2 {
        margin: -.5rem !important
    }

    .m-xxxl-n3 {
        margin: -1rem !important
    }

    .m-xxxl-n4 {
        margin: -1.5rem !important
    }

    .m-xxxl-n5 {
        margin: -3rem !important
    }

    .mx-xxxl-n1 {
        margin-right: -.25rem !important;
        margin-left: -.25rem !important
    }

    .mx-xxxl-n2 {
        margin-right: -.5rem !important;
        margin-left: -.5rem !important
    }

    .mx-xxxl-n3 {
        margin-right: -1rem !important;
        margin-left: -1rem !important
    }

    .mx-xxxl-n4 {
        margin-right: -1.5rem !important;
        margin-left: -1.5rem !important
    }

    .mx-xxxl-n5 {
        margin-right: -3rem !important;
        margin-left: -3rem !important
    }

    .my-xxxl-n1 {
        margin-top: -.25rem !important;
        margin-bottom: -.25rem !important
    }

    .my-xxxl-n2 {
        margin-top: -.5rem !important;
        margin-bottom: -.5rem !important
    }

    .my-xxxl-n3 {
        margin-top: -1rem !important;
        margin-bottom: -1rem !important
    }

    .my-xxxl-n4 {
        margin-top: -1.5rem !important;
        margin-bottom: -1.5rem !important
    }

    .my-xxxl-n5 {
        margin-top: -3rem !important;
        margin-bottom: -3rem !important
    }

    .mt-xxxl-n1 {
        margin-top: -.25rem !important
    }

    .mt-xxxl-n2 {
        margin-top: -.5rem !important
    }

    .mt-xxxl-n3 {
        margin-top: -1rem !important
    }

    .mt-xxxl-n4 {
        margin-top: -1.5rem !important
    }

    .mt-xxxl-n5 {
        margin-top: -3rem !important
    }

    .me-xxxl-n1 {
        margin-right: -.25rem !important
    }

    .me-xxxl-n2 {
        margin-right: -.5rem !important
    }

    .me-xxxl-n3 {
        margin-right: -1rem !important
    }

    .me-xxxl-n4 {
        margin-right: -1.5rem !important
    }

    .me-xxxl-n5 {
        margin-right: -3rem !important
    }

    .mb-xxxl-n1 {
        margin-bottom: -.25rem !important
    }

    .mb-xxxl-n2 {
        margin-bottom: -.5rem !important
    }

    .mb-xxxl-n3 {
        margin-bottom: -1rem !important
    }

    .mb-xxxl-n4 {
        margin-bottom: -1.5rem !important
    }

    .mb-xxxl-n5 {
        margin-bottom: -3rem !important
    }

    .ms-xxxl-n1 {
        margin-left: -.25rem !important
    }

    .ms-xxxl-n2 {
        margin-left: -.5rem !important
    }

    .ms-xxxl-n3 {
        margin-left: -1rem !important
    }

    .ms-xxxl-n4 {
        margin-left: -1.5rem !important
    }

    .ms-xxxl-n5 {
        margin-left: -3rem !important
    }

    .p-xxxl-0 {
        padding: 0 !important
    }

    .p-xxxl-1 {
        padding: .25rem !important
    }

    .p-xxxl-2 {
        padding: .5rem !important
    }

    .p-xxxl-3 {
        padding: 1rem !important
    }

    .p-xxxl-4 {
        padding: 1.5rem !important
    }

    .p-xxxl-5 {
        padding: 3rem !important
    }

    .px-xxxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }

    .px-xxxl-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }

    .px-xxxl-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-xxxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }

    .px-xxxl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }

    .px-xxxl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }

    .py-xxxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .py-xxxl-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }

    .py-xxxl-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }

    .py-xxxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }

    .py-xxxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }

    .py-xxxl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }

    .pt-xxxl-0 {
        padding-top: 0 !important
    }

    .pt-xxxl-1 {
        padding-top: .25rem !important
    }

    .pt-xxxl-2 {
        padding-top: .5rem !important
    }

    .pt-xxxl-3 {
        padding-top: 1rem !important
    }

    .pt-xxxl-4 {
        padding-top: 1.5rem !important
    }

    .pt-xxxl-5 {
        padding-top: 3rem !important
    }

    .pe-xxxl-0 {
        padding-right: 0 !important
    }

    .pe-xxxl-1 {
        padding-right: .25rem !important
    }

    .pe-xxxl-2 {
        padding-right: .5rem !important
    }

    .pe-xxxl-3 {
        padding-right: 1rem !important
    }

    .pe-xxxl-4 {
        padding-right: 1.5rem !important
    }

    .pe-xxxl-5 {
        padding-right: 3rem !important
    }

    .pb-xxxl-0 {
        padding-bottom: 0 !important
    }

    .pb-xxxl-1 {
        padding-bottom: .25rem !important
    }

    .pb-xxxl-2 {
        padding-bottom: .5rem !important
    }

    .pb-xxxl-3 {
        padding-bottom: 1rem !important
    }

    .pb-xxxl-4 {
        padding-bottom: 1.5rem !important
    }

    .pb-xxxl-5 {
        padding-bottom: 3rem !important
    }

    .ps-xxxl-0 {
        padding-left: 0 !important
    }

    .ps-xxxl-1 {
        padding-left: .25rem !important
    }

    .ps-xxxl-2 {
        padding-left: .5rem !important
    }

    .ps-xxxl-3 {
        padding-left: 1rem !important
    }

    .ps-xxxl-4 {
        padding-left: 1.5rem !important
    }

    .ps-xxxl-5 {
        padding-left: 3rem !important
    }

    .text-xxxl-start {
        text-align: left !important
    }

    .text-xxxl-end {
        text-align: right !important
    }

    .text-xxxl-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .fs-1 {
        font-size: 2.1875rem !important
    }

    .fs-2 {
        font-size: 1.75rem !important
    }

    .fs-3 {
        font-size: 1.53125rem !important
    }

    .fs-4 {
        font-size: 1.3125rem !important
    }
}

@media print {
    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-grid {
        display: grid !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }

    .d-print-none {
        display: none !important
    }
}

.row.row-space-0 {
    margin-left: 0;
    margin-right: 0
}

.row.row-space-0>[class*=col-] {
    padding-left: 0;
    padding-right: 0
}

.row.row-space-2 {
    margin-left: -1px;
    margin-right: -1px
}

.row.row-space-2>[class*=col-] {
    padding-left: 1px;
    padding-right: 1px
}

.row.row-space-4 {
    margin-left: -2px;
    margin-right: -2px
}

.row.row-space-4>[class*=col-] {
    padding-left: 2px;
    padding-right: 2px
}

.row.row-space-6 {
    margin-left: -3px;
    margin-right: -3px
}

.row.row-space-6>[class*=col-] {
    padding-left: 3px;
    padding-right: 3px
}

.row.row-space-8 {
    margin-left: -4px;
    margin-right: -4px
}

.row.row-space-8>[class*=col-] {
    padding-left: 4px;
    padding-right: 4px
}

.row.row-space-10 {
    margin-left: -5px;
    margin-right: -5px
}

.row.row-space-10>[class*=col-] {
    padding-left: 5px;
    padding-right: 5px
}

.row.row-space-12 {
    margin-left: -6px;
    margin-right: -6px
}

.row.row-space-12>[class*=col-] {
    padding-left: 6px;
    padding-right: 6px
}

.row.row-space-14 {
    margin-left: -7px;
    margin-right: -7px
}

.row.row-space-14>[class*=col-] {
    padding-left: 7px;
    padding-right: 7px
}

.row.row-space-16 {
    margin-left: -8px;
    margin-right: -8px
}

.row.row-space-16>[class*=col-] {
    padding-left: 8px;
    padding-right: 8px
}

.row.row-space-18 {
    margin-left: -9px;
    margin-right: -9px
}

.row.row-space-18>[class*=col-] {
    padding-left: 9px;
    padding-right: 9px
}

.row.row-space-20 {
    margin-left: -10px;
    margin-right: -10px
}

.row.row-space-20>[class*=col-] {
    padding-left: 10px;
    padding-right: 10px
}

.row.row-space-22 {
    margin-left: -11px;
    margin-right: -11px
}

.row.row-space-22>[class*=col-] {
    padding-left: 11px;
    padding-right: 11px
}

.row.row-space-24 {
    margin-left: -12px;
    margin-right: -12px
}

.row.row-space-24>[class*=col-] {
    padding-left: 12px;
    padding-right: 12px
}

.row.row-space-26 {
    margin-left: -13px;
    margin-right: -13px
}

.row.row-space-26>[class*=col-] {
    padding-left: 13px;
    padding-right: 13px
}

.row.row-space-28 {
    margin-left: -14px;
    margin-right: -14px
}

.row.row-space-28>[class*=col-] {
    padding-left: 14px;
    padding-right: 14px
}

.row.row-space-30 {
    margin-left: -15px;
    margin-right: -15px
}

.row.row-space-30>[class*=col-] {
    padding-left: 15px;
    padding-right: 15px
}

.mt-1px {
    margin-top: 1px !important
}

.mt-2px {
    margin-top: 2px !important
}

.mt-3px {
    margin-top: 3px !important
}

.mt-4px {
    margin-top: 4px !important
}

.mt-5px {
    margin-top: 5px !important
}

.mt-6px {
    margin-top: 6px !important
}

.mt-7px {
    margin-top: 7px !important
}

.mt-8px {
    margin-top: 8px !important
}

.mt-9px {
    margin-top: 9px !important
}

.mt-10px {
    margin-top: 10px !important
}

.mt-5px {
    margin-top: 5px !important
}

.mt-10px {
    margin-top: 10px !important
}

.mt-15px {
    margin-top: 15px !important
}

.mt-20px {
    margin-top: 20px !important
}

.mt-25px {
    margin-top: 25px !important
}

.mt-30px {
    margin-top: 30px !important
}

.mt-35px {
    margin-top: 35px !important
}

.mt-40px {
    margin-top: 40px !important
}

.mt-45px {
    margin-top: 45px !important
}

.mt-50px {
    margin-top: 50px !important
}

.me-1px {
    margin-right: 1px !important
}

.me-2px {
    margin-right: 2px !important
}

.me-3px {
    margin-right: 3px !important
}

.me-4px {
    margin-right: 4px !important
}

.me-5px {
    margin-right: 5px !important
}

.me-6px {
    margin-right: 6px !important
}

.me-7px {
    margin-right: 7px !important
}

.me-8px {
    margin-right: 8px !important
}

.me-9px {
    margin-right: 9px !important
}

.me-10px {
    margin-right: 10px !important
}

.me-5px {
    margin-right: 5px !important
}

.me-10px {
    margin-right: 10px !important
}

.me-15px {
    margin-right: 15px !important
}

.me-20px {
    margin-right: 20px !important
}

.me-25px {
    margin-right: 25px !important
}

.me-30px {
    margin-right: 30px !important
}

.me-35px {
    margin-right: 35px !important
}

.me-40px {
    margin-right: 40px !important
}

.me-45px {
    margin-right: 45px !important
}

.me-50px {
    margin-right: 50px !important
}

.mb-1px {
    margin-bottom: 1px !important
}

.mb-2px {
    margin-bottom: 2px !important
}

.mb-3px {
    margin-bottom: 3px !important
}

.mb-4px {
    margin-bottom: 4px !important
}

.mb-5px {
    margin-bottom: 5px !important
}

.mb-6px {
    margin-bottom: 6px !important
}

.mb-7px {
    margin-bottom: 7px !important
}

.mb-8px {
    margin-bottom: 8px !important
}

.mb-9px {
    margin-bottom: 9px !important
}

.mb-10px {
    margin-bottom: 10px !important
}

.mb-5px {
    margin-bottom: 5px !important
}

.mb-10px {
    margin-bottom: 10px !important
}

.mb-15px {
    margin-bottom: 15px !important
}

.mb-20px {
    margin-bottom: 20px !important
}

.mb-25px {
    margin-bottom: 25px !important
}

.mb-30px {
    margin-bottom: 30px !important
}

.mb-35px {
    margin-bottom: 35px !important
}

.mb-40px {
    margin-bottom: 40px !important
}

.mb-45px {
    margin-bottom: 45px !important
}

.mb-50px {
    margin-bottom: 50px !important
}

.ms-1px {
    margin-left: 1px !important
}

.ms-2px {
    margin-left: 2px !important
}

.ms-3px {
    margin-left: 3px !important
}

.ms-4px {
    margin-left: 4px !important
}

.ms-5px {
    margin-left: 5px !important
}

.ms-6px {
    margin-left: 6px !important
}

.ms-7px {
    margin-left: 7px !important
}

.ms-8px {
    margin-left: 8px !important
}

.ms-9px {
    margin-left: 9px !important
}

.ms-10px {
    margin-left: 10px !important
}

.ms-5px {
    margin-left: 5px !important
}

.ms-10px {
    margin-left: 10px !important
}

.ms-15px {
    margin-left: 15px !important
}

.ms-20px {
    margin-left: 20px !important
}

.ms-25px {
    margin-left: 25px !important
}

.ms-30px {
    margin-left: 30px !important
}

.ms-35px {
    margin-left: 35px !important
}

.ms-40px {
    margin-left: 40px !important
}

.ms-45px {
    margin-left: 45px !important
}

.ms-50px {
    margin-left: 50px !important
}

.pt-1px {
    padding-top: 1px !important
}

.pt-2px {
    padding-top: 2px !important
}

.pt-3px {
    padding-top: 3px !important
}

.pt-4px {
    padding-top: 4px !important
}

.pt-5px {
    padding-top: 5px !important
}

.pt-6px {
    padding-top: 6px !important
}

.pt-7px {
    padding-top: 7px !important
}

.pt-8px {
    padding-top: 8px !important
}

.pt-9px {
    padding-top: 9px !important
}

.pt-10px {
    padding-top: 10px !important
}

.pt-5px {
    padding-top: 5px !important
}

.pt-10px {
    padding-top: 10px !important
}

.pt-15px {
    padding-top: 15px !important
}

.pt-20px {
    padding-top: 20px !important
}

.pt-25px {
    padding-top: 25px !important
}

.pt-30px {
    padding-top: 30px !important
}

.pt-35px {
    padding-top: 35px !important
}

.pt-40px {
    padding-top: 40px !important
}

.pt-45px {
    padding-top: 45px !important
}

.pt-50px {
    padding-top: 50px !important
}

.pe-1px {
    padding-right: 1px !important
}

.pe-2px {
    padding-right: 2px !important
}

.pe-3px {
    padding-right: 3px !important
}

.pe-4px {
    padding-right: 4px !important
}

.pe-5px {
    padding-right: 5px !important
}

.pe-6px {
    padding-right: 6px !important
}

.pe-7px {
    padding-right: 7px !important
}

.pe-8px {
    padding-right: 8px !important
}

.pe-9px {
    padding-right: 9px !important
}

.pe-10px {
    padding-right: 10px !important
}

.pe-5px {
    padding-right: 5px !important
}

.pe-10px {
    padding-right: 10px !important
}

.pe-15px {
    padding-right: 15px !important
}

.pe-20px {
    padding-right: 20px !important
}

.pe-25px {
    padding-right: 25px !important
}

.pe-30px {
    padding-right: 30px !important
}

.pe-35px {
    padding-right: 35px !important
}

.pe-40px {
    padding-right: 40px !important
}

.pe-45px {
    padding-right: 45px !important
}

.pe-50px {
    padding-right: 50px !important
}

.pb-1px {
    padding-bottom: 1px !important
}

.pb-2px {
    padding-bottom: 2px !important
}

.pb-3px {
    padding-bottom: 3px !important
}

.pb-4px {
    padding-bottom: 4px !important
}

.pb-5px {
    padding-bottom: 5px !important
}

.pb-6px {
    padding-bottom: 6px !important
}

.pb-7px {
    padding-bottom: 7px !important
}

.pb-8px {
    padding-bottom: 8px !important
}

.pb-9px {
    padding-bottom: 9px !important
}

.pb-10px {
    padding-bottom: 10px !important
}

.pb-5px {
    padding-bottom: 5px !important
}

.pb-10px {
    padding-bottom: 10px !important
}

.pb-15px {
    padding-bottom: 15px !important
}

.pb-20px {
    padding-bottom: 20px !important
}

.pb-25px {
    padding-bottom: 25px !important
}

.pb-30px {
    padding-bottom: 30px !important
}

.pb-35px {
    padding-bottom: 35px !important
}

.pb-40px {
    padding-bottom: 40px !important
}

.pb-45px {
    padding-bottom: 45px !important
}

.pb-50px {
    padding-bottom: 50px !important
}

.ps-1px {
    padding-left: 1px !important
}

.ps-2px {
    padding-left: 2px !important
}

.ps-3px {
    padding-left: 3px !important
}

.ps-4px {
    padding-left: 4px !important
}

.ps-5px {
    padding-left: 5px !important
}

.ps-6px {
    padding-left: 6px !important
}

.ps-7px {
    padding-left: 7px !important
}

.ps-8px {
    padding-left: 8px !important
}

.ps-9px {
    padding-left: 9px !important
}

.ps-10px {
    padding-left: 10px !important
}

.ps-5px {
    padding-left: 5px !important
}

.ps-10px {
    padding-left: 10px !important
}

.ps-15px {
    padding-left: 15px !important
}

.ps-20px {
    padding-left: 20px !important
}

.ps-25px {
    padding-left: 25px !important
}

.ps-30px {
    padding-left: 30px !important
}

.ps-35px {
    padding-left: 35px !important
}

.ps-40px {
    padding-left: 40px !important
}

.ps-45px {
    padding-left: 45px !important
}

.ps-50px {
    padding-left: 50px !important
}

.fs-8px {
    font-size: 8px !important
}

.fs-9px {
    font-size: 9px !important
}

.fs-10px {
    font-size: 10px !important
}

.fs-11px {
    font-size: 11px !important
}

.fs-12px {
    font-size: 12px !important
}

.fs-13px {
    font-size: 13px !important
}

.fs-14px {
    font-size: 14px !important
}

.fs-15px {
    font-size: 15px !important
}

.fs-16px {
    font-size: 16px !important
}

.fs-17px {
    font-size: 17px !important
}

.fs-18px {
    font-size: 18px !important
}

.fs-19px {
    font-size: 19px !important
}

.fs-20px {
    font-size: 20px !important
}

.fs-21px {
    font-size: 21px !important
}

.fs-22px {
    font-size: 22px !important
}

.fs-23px {
    font-size: 23px !important
}

.fs-24px {
    font-size: 24px !important
}

.fs-25px {
    font-size: 25px !important
}

.fs-26px {
    font-size: 26px !important
}

.fs-27px {
    font-size: 27px !important
}

.fs-28px {
    font-size: 28px !important
}

.fs-29px {
    font-size: 29px !important
}

.fs-30px {
    font-size: 30px !important
}

.fs-31px {
    font-size: 31px !important
}

.fs-32px {
    font-size: 32px !important
}

.fs-33px {
    font-size: 33px !important
}

.fs-34px {
    font-size: 34px !important
}

.fs-35px {
    font-size: 35px !important
}

.fs-36px {
    font-size: 36px !important
}

.fs-37px {
    font-size: 37px !important
}

.fs-38px {
    font-size: 38px !important
}

.fs-39px {
    font-size: 39px !important
}

.fs-40px {
    font-size: 40px !important
}

.fs-41px {
    font-size: 41px !important
}

.fs-42px {
    font-size: 42px !important
}

.fs-43px {
    font-size: 43px !important
}

.fs-44px {
    font-size: 44px !important
}

.fs-45px {
    font-size: 45px !important
}

.fs-46px {
    font-size: 46px !important
}

.fs-47px {
    font-size: 47px !important
}

.fs-48px {
    font-size: 48px !important
}

.fs-49px {
    font-size: 49px !important
}

.fs-50px {
    font-size: 50px !important
}

.fs-51px {
    font-size: 51px !important
}

.fs-52px {
    font-size: 52px !important
}

.fs-53px {
    font-size: 53px !important
}

.fs-54px {
    font-size: 54px !important
}

.fs-55px {
    font-size: 55px !important
}

.fs-56px {
    font-size: 56px !important
}

.fs-57px {
    font-size: 57px !important
}

.fs-58px {
    font-size: 58px !important
}

.fs-59px {
    font-size: 59px !important
}

.fs-60px {
    font-size: 60px !important
}

.fs-61px {
    font-size: 61px !important
}

.fs-62px {
    font-size: 62px !important
}

.fs-63px {
    font-size: 63px !important
}

.fs-64px {
    font-size: 64px !important
}

.fs-65px {
    font-size: 65px !important
}

.fs-66px {
    font-size: 66px !important
}

.fs-67px {
    font-size: 67px !important
}

.fs-68px {
    font-size: 68px !important
}

.fs-69px {
    font-size: 69px !important
}

.fs-70px {
    font-size: 70px !important
}

.fs-71px {
    font-size: 71px !important
}

.fs-72px {
    font-size: 72px !important
}

.fs-73px {
    font-size: 73px !important
}

.fs-74px {
    font-size: 74px !important
}

.fs-75px {
    font-size: 75px !important
}

.fs-76px {
    font-size: 76px !important
}

.fs-77px {
    font-size: 77px !important
}

.fs-78px {
    font-size: 78px !important
}

.fs-79px {
    font-size: 79px !important
}

.fs-80px {
    font-size: 80px !important
}

.font-weight-100 {
    font-weight: 100 !important
}

.font-weight-200 {
    font-weight: 200 !important
}

.font-weight-300 {
    font-weight: 300 !important
}

.font-weight-400 {
    font-weight: 400 !important
}

.font-weight-500 {
    font-weight: 500 !important
}

.font-weight-600 {
    font-weight: 600 !important
}

.font-weight-700 {
    font-weight: 700 !important
}

.font-weight-800 {
    font-weight: 800 !important
}

.d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.d-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.align-items-start {
    -ms-flex-align: start;
    align-items: start
}

.align-items-end {
    -ms-flex-align: end;
    align-items: end
}

.align-items-center {
    -ms-flex-align: center;
    align-items: center
}

.align-items-baseline {
    -ms-flex-align: baseline;
    align-items: baseline
}

.align-items-stretch {
    -ms-flex-align: stretch;
    align-items: stretch
}

.justify-content-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start
}

.justify-content-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end
}

.justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.justify-content-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: justify
}

.justify-content-around {
    -webkit-box-pack: distribute;
    -ms-flex-pack: distribute;
    justify-content: distribute
}

.top-0 {
    top: 0 !important
}

.bottom-0 {
    bottom: 0 !important
}

.start-0 {
    left: 0 !important
}

.end-0 {
    right: 0 !important
}

.top-auto {
    top: auto !important
}

.bottom-auto {
    bottom: auto !important
}

.start-auto {
    left: auto !important
}

.end-auto {
    right: auto !important
}

.text-underline {
    text-decoration: underline !important
}

.text-gradient {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important
}

.line-height-1 {
    line-height: 1 !important
}

.rounded-top-0 {
    -webkit-border-top-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    -webkit-border-top-right-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.rounded-top-start-0 {
    -webkit-border-top-left-radius: 0 !important;
    border-top-left-radius: 0 !important
}

.rounded-top-end-0 {
    -webkit-border-top-right-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.rounded-bottom-0 {
    -webkit-border-bottom-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    -webkit-border-bottom-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.rounded-bottom-start-0 {
    -webkit-border-bottom-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important
}

.rounded-bottom-end-0 {
    -webkit-border-bottom-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.width-full {
    width: 100%
}

.width-10 {
    width: 10px !important
}

.width-20 {
    width: 20px !important
}

.width-30 {
    width: 30px !important
}

.width-40 {
    width: 40px !important
}

.width-50 {
    width: 50px !important
}

.width-60 {
    width: 60px !important
}

.width-70 {
    width: 70px !important
}

.width-80 {
    width: 80px !important
}

.width-90 {
    width: 90px !important
}

.width-100 {
    width: 100px !important
}

.width-150 {
    width: 150px !important
}

.width-200 {
    width: 200px !important
}

.width-250 {
    width: 250px !important
}

.width-300 {
    width: 300px !important
}

.width-350 {
    width: 350px !important
}

.width-400 {
    width: 400px !important
}

.width-450 {
    width: 450px !important
}

.width-500 {
    width: 500px !important
}

.width-550 {
    width: 550px !important
}

.width-600 {
    width: 600px !important
}

.height-full {
    height: 100%
}

.height-10 {
    height: 10px !important
}

.height-20 {
    height: 20px !important
}

.height-30 {
    height: 30px !important
}

.height-40 {
    height: 40px !important
}

.height-50 {
    height: 50px !important
}

.height-60 {
    height: 60px !important
}

.height-70 {
    height: 70px !important
}

.height-80 {
    height: 80px !important
}

.height-90 {
    height: 90px !important
}

.height-100 {
    height: 100px !important
}

.height-150 {
    height: 150px !important
}

.height-200 {
    height: 200px !important
}

.height-250 {
    height: 250px !important
}

.height-300 {
    height: 300px !important
}

.height-350 {
    height: 350px !important
}

.height-400 {
    height: 400px !important
}

.height-450 {
    height: 450px !important
}

.height-500 {
    height: 500px !important
}

.height-550 {
    height: 550px !important
}

.height-600 {
    height: 600px !important
}

.text-primary {
    color: #1f6bff !important
}

.bg-primary {
    background-color: #1f6bff
}

.bg-gradient-primary {
    background: #1f6bff !important;
    background: -moz-linear-gradient(top, #528dff, #065aff) !important;
    background: -webkit-linear-gradient(top, #528dff, #065aff) !important;
    background: linear-gradient(to bottom, #528dff, #065aff) !important
}

.bg-primary-transparent-1 {
    background: rgba(31, 107, 255, .1) !important
}

.text-primary-transparent-1 {
    color: rgba(31, 107, 255, .1) !important
}

.bg-primary-transparent-2 {
    background: rgba(31, 107, 255, .2) !important
}

.text-primary-transparent-2 {
    color: rgba(31, 107, 255, .2) !important
}

.bg-primary-transparent-3 {
    background: rgba(31, 107, 255, .3) !important
}

.text-primary-transparent-3 {
    color: rgba(31, 107, 255, .3) !important
}

.bg-primary-transparent-4 {
    background: rgba(31, 107, 255, .4) !important
}

.text-primary-transparent-4 {
    color: rgba(31, 107, 255, .4) !important
}

.bg-primary-transparent-5 {
    background: rgba(31, 107, 255, .5) !important
}

.text-primary-transparent-5 {
    color: rgba(31, 107, 255, .5) !important
}

.bg-primary-transparent-6 {
    background: rgba(31, 107, 255, .6) !important
}

.text-primary-transparent-6 {
    color: rgba(31, 107, 255, .6) !important
}

.bg-primary-transparent-7 {
    background: rgba(31, 107, 255, .7) !important
}

.text-primary-transparent-7 {
    color: rgba(31, 107, 255, .7) !important
}

.bg-primary-transparent-8 {
    background: rgba(31, 107, 255, .8) !important
}

.text-primary-transparent-8 {
    color: rgba(31, 107, 255, .8) !important
}

.bg-primary-transparent-9 {
    background: rgba(31, 107, 255, .9) !important
}

.text-primary-transparent-9 {
    color: rgba(31, 107, 255, .9) !important
}

.text-primary-100 {
    color: #d2e1ff !important
}

.bg-primary-100 {
    background-color: #d2e1ff !important
}

.text-primary-600 {
    color: #1956cc !important
}

.bg-primary-600 {
    background-color: #1956cc !important
}

.text-primary-200 {
    color: #a5c4ff !important
}

.bg-primary-200 {
    background-color: #a5c4ff !important
}

.text-primary-700 {
    color: #134099 !important
}

.bg-primary-700 {
    background-color: #134099 !important
}

.text-primary-300 {
    color: #79a6ff !important
}

.bg-primary-300 {
    background-color: #79a6ff !important
}

.text-primary-800 {
    color: #0c2b66 !important
}

.bg-primary-800 {
    background-color: #0c2b66 !important
}

.text-primary-400 {
    color: #4c89ff !important
}

.bg-primary-400 {
    background-color: #4c89ff !important
}

.text-primary-900 {
    color: #061533 !important
}

.bg-primary-900 {
    background-color: #061533 !important
}

.text-secondary {
    color: #c9d2e3 !important
}

.bg-secondary {
    background-color: #c9d2e3
}

.bg-gradient-secondary {
    background: #c9d2e3 !important;
    background: -moz-linear-gradient(top, #ebeef4, #b8c4da) !important;
    background: -webkit-linear-gradient(top, #ebeef4, #b8c4da) !important;
    background: linear-gradient(to bottom, #ebeef4, #b8c4da) !important
}

.bg-secondary-transparent-1 {
    background: rgba(201, 210, 227, .1) !important
}

.text-secondary-transparent-1 {
    color: rgba(201, 210, 227, .1) !important
}

.bg-secondary-transparent-2 {
    background: rgba(201, 210, 227, .2) !important
}

.text-secondary-transparent-2 {
    color: rgba(201, 210, 227, .2) !important
}

.bg-secondary-transparent-3 {
    background: rgba(201, 210, 227, .3) !important
}

.text-secondary-transparent-3 {
    color: rgba(201, 210, 227, .3) !important
}

.bg-secondary-transparent-4 {
    background: rgba(201, 210, 227, .4) !important
}

.text-secondary-transparent-4 {
    color: rgba(201, 210, 227, .4) !important
}

.bg-secondary-transparent-5 {
    background: rgba(201, 210, 227, .5) !important
}

.text-secondary-transparent-5 {
    color: rgba(201, 210, 227, .5) !important
}

.bg-secondary-transparent-6 {
    background: rgba(201, 210, 227, .6) !important
}

.text-secondary-transparent-6 {
    color: rgba(201, 210, 227, .6) !important
}

.bg-secondary-transparent-7 {
    background: rgba(201, 210, 227, .7) !important
}

.text-secondary-transparent-7 {
    color: rgba(201, 210, 227, .7) !important
}

.bg-secondary-transparent-8 {
    background: rgba(201, 210, 227, .8) !important
}

.text-secondary-transparent-8 {
    color: rgba(201, 210, 227, .8) !important
}

.bg-secondary-transparent-9 {
    background: rgba(201, 210, 227, .9) !important
}

.text-secondary-transparent-9 {
    color: rgba(201, 210, 227, .9) !important
}

.text-secondary-100 {
    color: #f4f6f9 !important
}

.bg-secondary-100 {
    background-color: #f4f6f9 !important
}

.text-secondary-600 {
    color: #a1a8b6 !important
}

.bg-secondary-600 {
    background-color: #a1a8b6 !important
}

.text-secondary-200 {
    color: #e9edf4 !important
}

.bg-secondary-200 {
    background-color: #e9edf4 !important
}

.text-secondary-700 {
    color: #797e88 !important
}

.bg-secondary-700 {
    background-color: #797e88 !important
}

.text-secondary-300 {
    color: #dfe4ee !important
}

.bg-secondary-300 {
    background-color: #dfe4ee !important
}

.text-secondary-800 {
    color: #50545b !important
}

.bg-secondary-800 {
    background-color: #50545b !important
}

.text-secondary-400 {
    color: #d4dbe9 !important
}

.bg-secondary-400 {
    background-color: #d4dbe9 !important
}

.text-secondary-900 {
    color: #282a2d !important
}

.bg-secondary-900 {
    background-color: #282a2d !important
}

.text-warning {
    color: #ff9500 !important
}

.bg-warning {
    background-color: #ff9500
}

.bg-gradient-warning {
    background: #ff9500 !important;
    background: -moz-linear-gradient(top, #fa3, #e68600) !important;
    background: -webkit-linear-gradient(top, #fa3, #e68600) !important;
    background: linear-gradient(to bottom, #fa3, #e68600) !important
}

.bg-warning-transparent-1 {
    background: rgba(255, 149, 0, .1) !important
}

.text-warning-transparent-1 {
    color: rgba(255, 149, 0, .1) !important
}

.bg-warning-transparent-2 {
    background: rgba(255, 149, 0, .2) !important
}

.text-warning-transparent-2 {
    color: rgba(255, 149, 0, .2) !important
}

.bg-warning-transparent-3 {
    background: rgba(255, 149, 0, .3) !important
}

.text-warning-transparent-3 {
    color: rgba(255, 149, 0, .3) !important
}

.bg-warning-transparent-4 {
    background: rgba(255, 149, 0, .4) !important
}

.text-warning-transparent-4 {
    color: rgba(255, 149, 0, .4) !important
}

.bg-warning-transparent-5 {
    background: rgba(255, 149, 0, .5) !important
}

.text-warning-transparent-5 {
    color: rgba(255, 149, 0, .5) !important
}

.bg-warning-transparent-6 {
    background: rgba(255, 149, 0, .6) !important
}

.text-warning-transparent-6 {
    color: rgba(255, 149, 0, .6) !important
}

.bg-warning-transparent-7 {
    background: rgba(255, 149, 0, .7) !important
}

.text-warning-transparent-7 {
    color: rgba(255, 149, 0, .7) !important
}

.bg-warning-transparent-8 {
    background: rgba(255, 149, 0, .8) !important
}

.text-warning-transparent-8 {
    color: rgba(255, 149, 0, .8) !important
}

.bg-warning-transparent-9 {
    background: rgba(255, 149, 0, .9) !important
}

.text-warning-transparent-9 {
    color: rgba(255, 149, 0, .9) !important
}

.text-warning-100 {
    color: #ffeacc !important
}

.bg-warning-100 {
    background-color: #ffeacc !important
}

.text-warning-600 {
    color: #c70 !important
}

.bg-warning-600 {
    background-color: #c70 !important
}

.text-warning-200 {
    color: #ffd599 !important
}

.bg-warning-200 {
    background-color: #ffd599 !important
}

.text-warning-700 {
    color: #995900 !important
}

.bg-warning-700 {
    background-color: #995900 !important
}

.text-warning-300 {
    color: #ffbf66 !important
}

.bg-warning-300 {
    background-color: #ffbf66 !important
}

.text-warning-800 {
    color: #663c00 !important
}

.bg-warning-800 {
    background-color: #663c00 !important
}

.text-warning-400 {
    color: #fa3 !important
}

.bg-warning-400 {
    background-color: #fa3 !important
}

.text-warning-900 {
    color: #331e00 !important
}

.bg-warning-900 {
    background-color: #331e00 !important
}

.text-danger {
    color: #e6180d !important
}

.bg-danger {
    background-color: #e6180d
}

.bg-gradient-danger {
    background: #e6180d !important;
    background: -moz-linear-gradient(top, #f33c33, #ce150c) !important;
    background: -webkit-linear-gradient(top, #f33c33, #ce150c) !important;
    background: linear-gradient(to bottom, #f33c33, #ce150c) !important
}

.bg-danger-transparent-1 {
    background: rgba(230, 24, 13, .1) !important
}

.text-danger-transparent-1 {
    color: rgba(230, 24, 13, .1) !important
}

.bg-danger-transparent-2 {
    background: rgba(230, 24, 13, .2) !important
}

.text-danger-transparent-2 {
    color: rgba(230, 24, 13, .2) !important
}

.bg-danger-transparent-3 {
    background: rgba(230, 24, 13, .3) !important
}

.text-danger-transparent-3 {
    color: rgba(230, 24, 13, .3) !important
}

.bg-danger-transparent-4 {
    background: rgba(230, 24, 13, .4) !important
}

.text-danger-transparent-4 {
    color: rgba(230, 24, 13, .4) !important
}

.bg-danger-transparent-5 {
    background: rgba(230, 24, 13, .5) !important
}

.text-danger-transparent-5 {
    color: rgba(230, 24, 13, .5) !important
}

.bg-danger-transparent-6 {
    background: rgba(230, 24, 13, .6) !important
}

.text-danger-transparent-6 {
    color: rgba(230, 24, 13, .6) !important
}

.bg-danger-transparent-7 {
    background: rgba(230, 24, 13, .7) !important
}

.text-danger-transparent-7 {
    color: rgba(230, 24, 13, .7) !important
}

.bg-danger-transparent-8 {
    background: rgba(230, 24, 13, .8) !important
}

.text-danger-transparent-8 {
    color: rgba(230, 24, 13, .8) !important
}

.bg-danger-transparent-9 {
    background: rgba(230, 24, 13, .9) !important
}

.text-danger-transparent-9 {
    color: rgba(230, 24, 13, .9) !important
}

.text-danger-100 {
    color: #fad1cf !important
}

.bg-danger-100 {
    background-color: #fad1cf !important
}

.text-danger-600 {
    color: #b8130a !important
}

.bg-danger-600 {
    background-color: #b8130a !important
}

.text-danger-200 {
    color: #f5a39e !important
}

.bg-danger-200 {
    background-color: #f5a39e !important
}

.text-danger-700 {
    color: #8a0e08 !important
}

.bg-danger-700 {
    background-color: #8a0e08 !important
}

.text-danger-300 {
    color: #f0746e !important
}

.bg-danger-300 {
    background-color: #f0746e !important
}

.text-danger-800 {
    color: #5c0a05 !important
}

.bg-danger-800 {
    background-color: #5c0a05 !important
}

.text-danger-400 {
    color: #eb463d !important
}

.bg-danger-400 {
    background-color: #eb463d !important
}

.text-danger-900 {
    color: #2e0503 !important
}

.bg-danger-900 {
    background-color: #2e0503 !important
}

.text-success {
    color: #028824 !important
}

.bg-success {
    background-color: #028824
}

.bg-gradient-success {
    background: #028824 !important;
    background: -moz-linear-gradient(top, #03ba31, #026f1d) !important;
    background: -webkit-linear-gradient(top, #03ba31, #026f1d) !important;
    background: linear-gradient(to bottom, #03ba31, #026f1d) !important
}

.bg-success-transparent-1 {
    background: rgba(2, 136, 36, .1) !important
}

.text-success-transparent-1 {
    color: rgba(2, 136, 36, .1) !important
}

.bg-success-transparent-2 {
    background: rgba(2, 136, 36, .2) !important
}

.text-success-transparent-2 {
    color: rgba(2, 136, 36, .2) !important
}

.bg-success-transparent-3 {
    background: rgba(2, 136, 36, .3) !important
}

.text-success-transparent-3 {
    color: rgba(2, 136, 36, .3) !important
}

.bg-success-transparent-4 {
    background: rgba(2, 136, 36, .4) !important
}

.text-success-transparent-4 {
    color: rgba(2, 136, 36, .4) !important
}

.bg-success-transparent-5 {
    background: rgba(2, 136, 36, .5) !important
}

.text-success-transparent-5 {
    color: rgba(2, 136, 36, .5) !important
}

.bg-success-transparent-6 {
    background: rgba(2, 136, 36, .6) !important
}

.text-success-transparent-6 {
    color: rgba(2, 136, 36, .6) !important
}

.bg-success-transparent-7 {
    background: rgba(2, 136, 36, .7) !important
}

.text-success-transparent-7 {
    color: rgba(2, 136, 36, .7) !important
}

.bg-success-transparent-8 {
    background: rgba(2, 136, 36, .8) !important
}

.text-success-transparent-8 {
    color: rgba(2, 136, 36, .8) !important
}

.bg-success-transparent-9 {
    background: rgba(2, 136, 36, .9) !important
}

.text-success-transparent-9 {
    color: rgba(2, 136, 36, .9) !important
}

.text-success-100 {
    color: #cce7d3 !important
}

.bg-success-100 {
    background-color: #cce7d3 !important
}

.text-success-600 {
    color: #026d1d !important
}

.bg-success-600 {
    background-color: #026d1d !important
}

.text-success-200 {
    color: #9acfa7 !important
}

.bg-success-200 {
    background-color: #9acfa7 !important
}

.text-success-700 {
    color: #015216 !important
}

.bg-success-700 {
    background-color: #015216 !important
}

.text-success-300 {
    color: #67b87c !important
}

.bg-success-300 {
    background-color: #67b87c !important
}

.text-success-800 {
    color: #01360e !important
}

.bg-success-800 {
    background-color: #01360e !important
}

.text-success-400 {
    color: #35a050 !important
}

.bg-success-400 {
    background-color: #35a050 !important
}

.text-success-900 {
    color: #001b07 !important
}

.bg-success-900 {
    background-color: #001b07 !important
}

.text-info {
    color: #30beff !important
}

.bg-info {
    background-color: #30beff
}

.bg-gradient-info {
    background: #30beff !important;
    background: -moz-linear-gradient(top, #63ceff, #17b6ff) !important;
    background: -webkit-linear-gradient(top, #63ceff, #17b6ff) !important;
    background: linear-gradient(to bottom, #63ceff, #17b6ff) !important
}

.bg-info-transparent-1 {
    background: rgba(48, 190, 255, .1) !important
}

.text-info-transparent-1 {
    color: rgba(48, 190, 255, .1) !important
}

.bg-info-transparent-2 {
    background: rgba(48, 190, 255, .2) !important
}

.text-info-transparent-2 {
    color: rgba(48, 190, 255, .2) !important
}

.bg-info-transparent-3 {
    background: rgba(48, 190, 255, .3) !important
}

.text-info-transparent-3 {
    color: rgba(48, 190, 255, .3) !important
}

.bg-info-transparent-4 {
    background: rgba(48, 190, 255, .4) !important
}

.text-info-transparent-4 {
    color: rgba(48, 190, 255, .4) !important
}

.bg-info-transparent-5 {
    background: rgba(48, 190, 255, .5) !important
}

.text-info-transparent-5 {
    color: rgba(48, 190, 255, .5) !important
}

.bg-info-transparent-6 {
    background: rgba(48, 190, 255, .6) !important
}

.text-info-transparent-6 {
    color: rgba(48, 190, 255, .6) !important
}

.bg-info-transparent-7 {
    background: rgba(48, 190, 255, .7) !important
}

.text-info-transparent-7 {
    color: rgba(48, 190, 255, .7) !important
}

.bg-info-transparent-8 {
    background: rgba(48, 190, 255, .8) !important
}

.text-info-transparent-8 {
    color: rgba(48, 190, 255, .8) !important
}

.bg-info-transparent-9 {
    background: rgba(48, 190, 255, .9) !important
}

.text-info-transparent-9 {
    color: rgba(48, 190, 255, .9) !important
}

.text-info-100 {
    color: #d6f2ff !important
}

.bg-info-100 {
    background-color: #d6f2ff !important
}

.text-info-600 {
    color: #2698cc !important
}

.bg-info-600 {
    background-color: #2698cc !important
}

.text-info-200 {
    color: #ace5ff !important
}

.bg-info-200 {
    background-color: #ace5ff !important
}

.text-info-700 {
    color: #1d7299 !important
}

.bg-info-700 {
    background-color: #1d7299 !important
}

.text-info-300 {
    color: #83d8ff !important
}

.bg-info-300 {
    background-color: #83d8ff !important
}

.text-info-800 {
    color: #134c66 !important
}

.bg-info-800 {
    background-color: #134c66 !important
}

.text-info-400 {
    color: #59cbff !important
}

.bg-info-400 {
    background-color: #59cbff !important
}

.text-info-900 {
    color: #0a2633 !important
}

.bg-info-900 {
    background-color: #0a2633 !important
}

.text-dark {
    color: #212837 !important
}

.bg-dark {
    background-color: #212837
}

.bg-gradient-dark {
    background: #212837 !important;
    background: -moz-linear-gradient(top, #343f57, #171c27) !important;
    background: -webkit-linear-gradient(top, #343f57, #171c27) !important;
    background: linear-gradient(to bottom, #343f57, #171c27) !important
}

.bg-dark-transparent-1 {
    background: rgba(33, 40, 55, .1) !important
}

.text-dark-transparent-1 {
    color: rgba(33, 40, 55, .1) !important
}

.bg-dark-transparent-2 {
    background: rgba(33, 40, 55, .2) !important
}

.text-dark-transparent-2 {
    color: rgba(33, 40, 55, .2) !important
}

.bg-dark-transparent-3 {
    background: rgba(33, 40, 55, .3) !important
}

.text-dark-transparent-3 {
    color: rgba(33, 40, 55, .3) !important
}

.bg-dark-transparent-4 {
    background: rgba(33, 40, 55, .4) !important
}

.text-dark-transparent-4 {
    color: rgba(33, 40, 55, .4) !important
}

.bg-dark-transparent-5 {
    background: rgba(33, 40, 55, .5) !important
}

.text-dark-transparent-5 {
    color: rgba(33, 40, 55, .5) !important
}

.bg-dark-transparent-6 {
    background: rgba(33, 40, 55, .6) !important
}

.text-dark-transparent-6 {
    color: rgba(33, 40, 55, .6) !important
}

.bg-dark-transparent-7 {
    background: rgba(33, 40, 55, .7) !important
}

.text-dark-transparent-7 {
    color: rgba(33, 40, 55, .7) !important
}

.bg-dark-transparent-8 {
    background: rgba(33, 40, 55, .8) !important
}

.text-dark-transparent-8 {
    color: rgba(33, 40, 55, .8) !important
}

.bg-dark-transparent-9 {
    background: rgba(33, 40, 55, .9) !important
}

.text-dark-transparent-9 {
    color: rgba(33, 40, 55, .9) !important
}

.text-dark-100 {
    color: #d3d4d7 !important
}

.bg-dark-100 {
    background-color: #d3d4d7 !important
}

.text-dark-600 {
    color: #1a202c !important
}

.bg-dark-600 {
    background-color: #1a202c !important
}

.text-dark-200 {
    color: #a6a9af !important
}

.bg-dark-200 {
    background-color: #a6a9af !important
}

.text-dark-700 {
    color: #141821 !important
}

.bg-dark-700 {
    background-color: #141821 !important
}

.text-dark-300 {
    color: #7a7e87 !important
}

.bg-dark-300 {
    background-color: #7a7e87 !important
}

.text-dark-800 {
    color: #0d1016 !important
}

.bg-dark-800 {
    background-color: #0d1016 !important
}

.text-dark-400 {
    color: #4d535f !important
}

.bg-dark-400 {
    background-color: #4d535f !important
}

.text-dark-900 {
    color: #07080b !important
}

.bg-dark-900 {
    background-color: #07080b !important
}

.text-black {
    color: #000 !important
}

.bg-black {
    background-color: #000
}

.bg-gradient-black {
    background: #000 !important;
    background: -moz-linear-gradient(top, #1a1a1a, #000) !important;
    background: -webkit-linear-gradient(top, #1a1a1a, #000) !important;
    background: linear-gradient(to bottom, #1a1a1a, #000) !important
}

.bg-black-transparent-1 {
    background: rgba(0, 0, 0, .1) !important
}

.text-black-transparent-1 {
    color: rgba(0, 0, 0, .1) !important
}

.bg-black-transparent-2 {
    background: rgba(0, 0, 0, .2) !important
}

.text-black-transparent-2 {
    color: rgba(0, 0, 0, .2) !important
}

.bg-black-transparent-3 {
    background: rgba(0, 0, 0, .3) !important
}

.text-black-transparent-3 {
    color: rgba(0, 0, 0, .3) !important
}

.bg-black-transparent-4 {
    background: rgba(0, 0, 0, .4) !important
}

.text-black-transparent-4 {
    color: rgba(0, 0, 0, .4) !important
}

.bg-black-transparent-5 {
    background: rgba(0, 0, 0, .5) !important
}

.text-black-transparent-5 {
    color: rgba(0, 0, 0, .5) !important
}

.bg-black-transparent-6 {
    background: rgba(0, 0, 0, .6) !important
}

.text-black-transparent-6 {
    color: rgba(0, 0, 0, .6) !important
}

.bg-black-transparent-7 {
    background: rgba(0, 0, 0, .7) !important
}

.text-black-transparent-7 {
    color: rgba(0, 0, 0, .7) !important
}

.bg-black-transparent-8 {
    background: rgba(0, 0, 0, .8) !important
}

.text-black-transparent-8 {
    color: rgba(0, 0, 0, .8) !important
}

.bg-black-transparent-9 {
    background: rgba(0, 0, 0, .9) !important
}

.text-black-transparent-9 {
    color: rgba(0, 0, 0, .9) !important
}

.text-black-100 {
    color: #ccc !important
}

.bg-black-100 {
    background-color: #ccc !important
}

.text-black-600 {
    color: #000 !important
}

.bg-black-600 {
    background-color: #000 !important
}

.text-black-200 {
    color: #999 !important
}

.bg-black-200 {
    background-color: #999 !important
}

.text-black-700 {
    color: #000 !important
}

.bg-black-700 {
    background-color: #000 !important
}

.text-black-300 {
    color: #666 !important
}

.bg-black-300 {
    background-color: #666 !important
}

.text-black-800 {
    color: #000 !important
}

.bg-black-800 {
    background-color: #000 !important
}

.text-black-400 {
    color: #333 !important
}

.bg-black-400 {
    background-color: #333 !important
}

.text-black-900 {
    color: #000 !important
}

.bg-black-900 {
    background-color: #000 !important
}

.text-white {
    color: #fff !important
}

.bg-white {
    background-color: #fff
}

.bg-gradient-white {
    background: #fff !important;
    background: -moz-linear-gradient(top, #fff, #f2f2f2) !important;
    background: -webkit-linear-gradient(top, #fff, #f2f2f2) !important;
    background: linear-gradient(to bottom, #fff, #f2f2f2) !important
}

.bg-white-transparent-1 {
    background: rgba(255, 255, 255, .1) !important
}

.text-white-transparent-1 {
    color: rgba(255, 255, 255, .1) !important
}

.bg-white-transparent-2 {
    background: rgba(255, 255, 255, .2) !important
}

.text-white-transparent-2 {
    color: rgba(255, 255, 255, .2) !important
}

.bg-white-transparent-3 {
    background: rgba(255, 255, 255, .3) !important
}

.text-white-transparent-3 {
    color: rgba(255, 255, 255, .3) !important
}

.bg-white-transparent-4 {
    background: rgba(255, 255, 255, .4) !important
}

.text-white-transparent-4 {
    color: rgba(255, 255, 255, .4) !important
}

.bg-white-transparent-5 {
    background: rgba(255, 255, 255, .5) !important
}

.text-white-transparent-5 {
    color: rgba(255, 255, 255, .5) !important
}

.bg-white-transparent-6 {
    background: rgba(255, 255, 255, .6) !important
}

.text-white-transparent-6 {
    color: rgba(255, 255, 255, .6) !important
}

.bg-white-transparent-7 {
    background: rgba(255, 255, 255, .7) !important
}

.text-white-transparent-7 {
    color: rgba(255, 255, 255, .7) !important
}

.bg-white-transparent-8 {
    background: rgba(255, 255, 255, .8) !important
}

.text-white-transparent-8 {
    color: rgba(255, 255, 255, .8) !important
}

.bg-white-transparent-9 {
    background: rgba(255, 255, 255, .9) !important
}

.text-white-transparent-9 {
    color: rgba(255, 255, 255, .9) !important
}

.text-white-100 {
    color: #fff !important
}

.bg-white-100 {
    background-color: #fff !important
}

.text-white-600 {
    color: #ccc !important
}

.bg-white-600 {
    background-color: #ccc !important
}

.text-white-200 {
    color: #fff !important
}

.bg-white-200 {
    background-color: #fff !important
}

.text-white-700 {
    color: #999 !important
}

.bg-white-700 {
    background-color: #999 !important
}

.text-white-300 {
    color: #fff !important
}

.bg-white-300 {
    background-color: #fff !important
}

.text-white-800 {
    color: #666 !important
}

.bg-white-800 {
    background-color: #666 !important
}

.text-white-400 {
    color: #fff !important
}

.bg-white-400 {
    background-color: #fff !important
}

.text-white-900 {
    color: #333 !important
}

.bg-white-900 {
    background-color: #333 !important
}

.text-purple {
    color: #6f42c1 !important
}

.bg-purple {
    background-color: #6f42c1
}

.bg-gradient-purple {
    background: #6f42c1 !important;
    background: -moz-linear-gradient(top, #8c68ce, #643ab0) !important;
    background: -webkit-linear-gradient(top, #8c68ce, #643ab0) !important;
    background: linear-gradient(to bottom, #8c68ce, #643ab0) !important
}

.bg-purple-transparent-1 {
    background: rgba(111, 66, 193, .1) !important
}

.text-purple-transparent-1 {
    color: rgba(111, 66, 193, .1) !important
}

.bg-purple-transparent-2 {
    background: rgba(111, 66, 193, .2) !important
}

.text-purple-transparent-2 {
    color: rgba(111, 66, 193, .2) !important
}

.bg-purple-transparent-3 {
    background: rgba(111, 66, 193, .3) !important
}

.text-purple-transparent-3 {
    color: rgba(111, 66, 193, .3) !important
}

.bg-purple-transparent-4 {
    background: rgba(111, 66, 193, .4) !important
}

.text-purple-transparent-4 {
    color: rgba(111, 66, 193, .4) !important
}

.bg-purple-transparent-5 {
    background: rgba(111, 66, 193, .5) !important
}

.text-purple-transparent-5 {
    color: rgba(111, 66, 193, .5) !important
}

.bg-purple-transparent-6 {
    background: rgba(111, 66, 193, .6) !important
}

.text-purple-transparent-6 {
    color: rgba(111, 66, 193, .6) !important
}

.bg-purple-transparent-7 {
    background: rgba(111, 66, 193, .7) !important
}

.text-purple-transparent-7 {
    color: rgba(111, 66, 193, .7) !important
}

.bg-purple-transparent-8 {
    background: rgba(111, 66, 193, .8) !important
}

.text-purple-transparent-8 {
    color: rgba(111, 66, 193, .8) !important
}

.bg-purple-transparent-9 {
    background: rgba(111, 66, 193, .9) !important
}

.text-purple-transparent-9 {
    color: rgba(111, 66, 193, .9) !important
}

.text-purple-100 {
    color: #e2d9f3 !important
}

.bg-purple-100 {
    background-color: #e2d9f3 !important
}

.text-purple-600 {
    color: #59359a !important
}

.bg-purple-600 {
    background-color: #59359a !important
}

.text-purple-200 {
    color: #c5b3e6 !important
}

.bg-purple-200 {
    background-color: #c5b3e6 !important
}

.text-purple-700 {
    color: #432874 !important
}

.bg-purple-700 {
    background-color: #432874 !important
}

.text-purple-300 {
    color: #a98eda !important
}

.bg-purple-300 {
    background-color: #a98eda !important
}

.text-purple-800 {
    color: #2c1a4d !important
}

.bg-purple-800 {
    background-color: #2c1a4d !important
}

.text-purple-400 {
    color: #8c68cd !important
}

.bg-purple-400 {
    background-color: #8c68cd !important
}

.text-purple-900 {
    color: #160d27 !important
}

.bg-purple-900 {
    background-color: #160d27 !important
}

.text-indigo {
    color: #640df3 !important
}

.bg-indigo {
    background-color: #640df3
}

.bg-gradient-indigo {
    background: #640df3 !important;
    background: -moz-linear-gradient(top, #833ef5, #5a0bdc) !important;
    background: -webkit-linear-gradient(top, #833ef5, #5a0bdc) !important;
    background: linear-gradient(to bottom, #833ef5, #5a0bdc) !important
}

.bg-indigo-transparent-1 {
    background: rgba(100, 13, 243, .1) !important
}

.text-indigo-transparent-1 {
    color: rgba(100, 13, 243, .1) !important
}

.bg-indigo-transparent-2 {
    background: rgba(100, 13, 243, .2) !important
}

.text-indigo-transparent-2 {
    color: rgba(100, 13, 243, .2) !important
}

.bg-indigo-transparent-3 {
    background: rgba(100, 13, 243, .3) !important
}

.text-indigo-transparent-3 {
    color: rgba(100, 13, 243, .3) !important
}

.bg-indigo-transparent-4 {
    background: rgba(100, 13, 243, .4) !important
}

.text-indigo-transparent-4 {
    color: rgba(100, 13, 243, .4) !important
}

.bg-indigo-transparent-5 {
    background: rgba(100, 13, 243, .5) !important
}

.text-indigo-transparent-5 {
    color: rgba(100, 13, 243, .5) !important
}

.bg-indigo-transparent-6 {
    background: rgba(100, 13, 243, .6) !important
}

.text-indigo-transparent-6 {
    color: rgba(100, 13, 243, .6) !important
}

.bg-indigo-transparent-7 {
    background: rgba(100, 13, 243, .7) !important
}

.text-indigo-transparent-7 {
    color: rgba(100, 13, 243, .7) !important
}

.bg-indigo-transparent-8 {
    background: rgba(100, 13, 243, .8) !important
}

.text-indigo-transparent-8 {
    color: rgba(100, 13, 243, .8) !important
}

.bg-indigo-transparent-9 {
    background: rgba(100, 13, 243, .9) !important
}

.text-indigo-transparent-9 {
    color: rgba(100, 13, 243, .9) !important
}

.text-indigo-100 {
    color: #e0cffd !important
}

.bg-indigo-100 {
    background-color: #e0cffd !important
}

.text-indigo-600 {
    color: #500ac2 !important
}

.bg-indigo-600 {
    background-color: #500ac2 !important
}

.text-indigo-200 {
    color: #c19efa !important
}

.bg-indigo-200 {
    background-color: #c19efa !important
}

.text-indigo-700 {
    color: #3c0892 !important
}

.bg-indigo-700 {
    background-color: #3c0892 !important
}

.text-indigo-300 {
    color: #a26ef8 !important
}

.bg-indigo-300 {
    background-color: #a26ef8 !important
}

.text-indigo-800 {
    color: #280561 !important
}

.bg-indigo-800 {
    background-color: #280561 !important
}

.text-indigo-400 {
    color: #833df5 !important
}

.bg-indigo-400 {
    background-color: #833df5 !important
}

.text-indigo-900 {
    color: #140331 !important
}

.bg-indigo-900 {
    background-color: #140331 !important
}

.text-pink {
    color: #d63384 !important
}

.bg-pink {
    background-color: #d63384
}

.bg-gradient-pink {
    background: #d63384 !important;
    background: -moz-linear-gradient(top, #df5d9e, #c72877) !important;
    background: -webkit-linear-gradient(top, #df5d9e, #c72877) !important;
    background: linear-gradient(to bottom, #df5d9e, #c72877) !important
}

.bg-pink-transparent-1 {
    background: rgba(214, 51, 132, .1) !important
}

.text-pink-transparent-1 {
    color: rgba(214, 51, 132, .1) !important
}

.bg-pink-transparent-2 {
    background: rgba(214, 51, 132, .2) !important
}

.text-pink-transparent-2 {
    color: rgba(214, 51, 132, .2) !important
}

.bg-pink-transparent-3 {
    background: rgba(214, 51, 132, .3) !important
}

.text-pink-transparent-3 {
    color: rgba(214, 51, 132, .3) !important
}

.bg-pink-transparent-4 {
    background: rgba(214, 51, 132, .4) !important
}

.text-pink-transparent-4 {
    color: rgba(214, 51, 132, .4) !important
}

.bg-pink-transparent-5 {
    background: rgba(214, 51, 132, .5) !important
}

.text-pink-transparent-5 {
    color: rgba(214, 51, 132, .5) !important
}

.bg-pink-transparent-6 {
    background: rgba(214, 51, 132, .6) !important
}

.text-pink-transparent-6 {
    color: rgba(214, 51, 132, .6) !important
}

.bg-pink-transparent-7 {
    background: rgba(214, 51, 132, .7) !important
}

.text-pink-transparent-7 {
    color: rgba(214, 51, 132, .7) !important
}

.bg-pink-transparent-8 {
    background: rgba(214, 51, 132, .8) !important
}

.text-pink-transparent-8 {
    color: rgba(214, 51, 132, .8) !important
}

.bg-pink-transparent-9 {
    background: rgba(214, 51, 132, .9) !important
}

.text-pink-transparent-9 {
    color: rgba(214, 51, 132, .9) !important
}

.text-pink-100 {
    color: #f7d6e6 !important
}

.bg-pink-100 {
    background-color: #f7d6e6 !important
}

.text-pink-600 {
    color: #ab296a !important
}

.bg-pink-600 {
    background-color: #ab296a !important
}

.text-pink-200 {
    color: #efadce !important
}

.bg-pink-200 {
    background-color: #efadce !important
}

.text-pink-700 {
    color: #801f4f !important
}

.bg-pink-700 {
    background-color: #801f4f !important
}

.text-pink-300 {
    color: #e685b5 !important
}

.bg-pink-300 {
    background-color: #e685b5 !important
}

.text-pink-800 {
    color: #561435 !important
}

.bg-pink-800 {
    background-color: #561435 !important
}

.text-pink-400 {
    color: #de5c9d !important
}

.bg-pink-400 {
    background-color: #de5c9d !important
}

.text-pink-900 {
    color: #2b0a1a !important
}

.bg-pink-900 {
    background-color: #2b0a1a !important
}

.text-yellow {
    color: #fc0 !important
}

.bg-yellow {
    background-color: #fc0
}

.bg-gradient-yellow {
    background: #fc0 !important;
    background: -moz-linear-gradient(top, #ffd633, #e6b800) !important;
    background: -webkit-linear-gradient(top, #ffd633, #e6b800) !important;
    background: linear-gradient(to bottom, #ffd633, #e6b800) !important
}

.bg-yellow-transparent-1 {
    background: rgba(255, 204, 0, .1) !important
}

.text-yellow-transparent-1 {
    color: rgba(255, 204, 0, .1) !important
}

.bg-yellow-transparent-2 {
    background: rgba(255, 204, 0, .2) !important
}

.text-yellow-transparent-2 {
    color: rgba(255, 204, 0, .2) !important
}

.bg-yellow-transparent-3 {
    background: rgba(255, 204, 0, .3) !important
}

.text-yellow-transparent-3 {
    color: rgba(255, 204, 0, .3) !important
}

.bg-yellow-transparent-4 {
    background: rgba(255, 204, 0, .4) !important
}

.text-yellow-transparent-4 {
    color: rgba(255, 204, 0, .4) !important
}

.bg-yellow-transparent-5 {
    background: rgba(255, 204, 0, .5) !important
}

.text-yellow-transparent-5 {
    color: rgba(255, 204, 0, .5) !important
}

.bg-yellow-transparent-6 {
    background: rgba(255, 204, 0, .6) !important
}

.text-yellow-transparent-6 {
    color: rgba(255, 204, 0, .6) !important
}

.bg-yellow-transparent-7 {
    background: rgba(255, 204, 0, .7) !important
}

.text-yellow-transparent-7 {
    color: rgba(255, 204, 0, .7) !important
}

.bg-yellow-transparent-8 {
    background: rgba(255, 204, 0, .8) !important
}

.text-yellow-transparent-8 {
    color: rgba(255, 204, 0, .8) !important
}

.bg-yellow-transparent-9 {
    background: rgba(255, 204, 0, .9) !important
}

.text-yellow-transparent-9 {
    color: rgba(255, 204, 0, .9) !important
}

.text-yellow-100 {
    color: #fff5cc !important
}

.bg-yellow-100 {
    background-color: #fff5cc !important
}

.text-yellow-600 {
    color: #cca300 !important
}

.bg-yellow-600 {
    background-color: #cca300 !important
}

.text-yellow-200 {
    color: #ffeb99 !important
}

.bg-yellow-200 {
    background-color: #ffeb99 !important
}

.text-yellow-700 {
    color: #997a00 !important
}

.bg-yellow-700 {
    background-color: #997a00 !important
}

.text-yellow-300 {
    color: #ffe066 !important
}

.bg-yellow-300 {
    background-color: #ffe066 !important
}

.text-yellow-800 {
    color: #665200 !important
}

.bg-yellow-800 {
    background-color: #665200 !important
}

.text-yellow-400 {
    color: #ffd633 !important
}

.bg-yellow-400 {
    background-color: #ffd633 !important
}

.text-yellow-900 {
    color: #332900 !important
}

.bg-yellow-900 {
    background-color: #332900 !important
}

.text-teal {
    color: #20c997 !important
}

.bg-teal {
    background-color: #20c997
}

.bg-gradient-teal {
    background: #20c997 !important;
    background: -moz-linear-gradient(top, #3ce0af, #1cb386) !important;
    background: -webkit-linear-gradient(top, #3ce0af, #1cb386) !important;
    background: linear-gradient(to bottom, #3ce0af, #1cb386) !important
}

.bg-teal-transparent-1 {
    background: rgba(32, 201, 151, .1) !important
}

.text-teal-transparent-1 {
    color: rgba(32, 201, 151, .1) !important
}

.bg-teal-transparent-2 {
    background: rgba(32, 201, 151, .2) !important
}

.text-teal-transparent-2 {
    color: rgba(32, 201, 151, .2) !important
}

.bg-teal-transparent-3 {
    background: rgba(32, 201, 151, .3) !important
}

.text-teal-transparent-3 {
    color: rgba(32, 201, 151, .3) !important
}

.bg-teal-transparent-4 {
    background: rgba(32, 201, 151, .4) !important
}

.text-teal-transparent-4 {
    color: rgba(32, 201, 151, .4) !important
}

.bg-teal-transparent-5 {
    background: rgba(32, 201, 151, .5) !important
}

.text-teal-transparent-5 {
    color: rgba(32, 201, 151, .5) !important
}

.bg-teal-transparent-6 {
    background: rgba(32, 201, 151, .6) !important
}

.text-teal-transparent-6 {
    color: rgba(32, 201, 151, .6) !important
}

.bg-teal-transparent-7 {
    background: rgba(32, 201, 151, .7) !important
}

.text-teal-transparent-7 {
    color: rgba(32, 201, 151, .7) !important
}

.bg-teal-transparent-8 {
    background: rgba(32, 201, 151, .8) !important
}

.text-teal-transparent-8 {
    color: rgba(32, 201, 151, .8) !important
}

.bg-teal-transparent-9 {
    background: rgba(32, 201, 151, .9) !important
}

.text-teal-transparent-9 {
    color: rgba(32, 201, 151, .9) !important
}

.text-teal-100 {
    color: #d2f4ea !important
}

.bg-teal-100 {
    background-color: #d2f4ea !important
}

.text-teal-600 {
    color: #1aa179 !important
}

.bg-teal-600 {
    background-color: #1aa179 !important
}

.text-teal-200 {
    color: #a6e9d5 !important
}

.bg-teal-200 {
    background-color: #a6e9d5 !important
}

.text-teal-700 {
    color: #13795b !important
}

.bg-teal-700 {
    background-color: #13795b !important
}

.text-teal-300 {
    color: #79dfc1 !important
}

.bg-teal-300 {
    background-color: #79dfc1 !important
}

.text-teal-800 {
    color: #0d503c !important
}

.bg-teal-800 {
    background-color: #0d503c !important
}

.text-teal-400 {
    color: #4dd4ac !important
}

.bg-teal-400 {
    background-color: #4dd4ac !important
}

.text-teal-900 {
    color: #06281e !important
}

.bg-teal-900 {
    background-color: #06281e !important
}

.text-muted {
    color: #869ac0 !important
}

.bg-muted {
    background-color: #869ac0
}

.bg-gradient-muted {
    background: #869ac0 !important;
    background: -moz-linear-gradient(top, #a8b6d1, #758cb7) !important;
    background: -webkit-linear-gradient(top, #a8b6d1, #758cb7) !important;
    background: linear-gradient(to bottom, #a8b6d1, #758cb7) !important
}

.bg-muted-transparent-1 {
    background: rgba(134, 154, 192, .1) !important
}

.text-muted-transparent-1 {
    color: rgba(134, 154, 192, .1) !important
}

.bg-muted-transparent-2 {
    background: rgba(134, 154, 192, .2) !important
}

.text-muted-transparent-2 {
    color: rgba(134, 154, 192, .2) !important
}

.bg-muted-transparent-3 {
    background: rgba(134, 154, 192, .3) !important
}

.text-muted-transparent-3 {
    color: rgba(134, 154, 192, .3) !important
}

.bg-muted-transparent-4 {
    background: rgba(134, 154, 192, .4) !important
}

.text-muted-transparent-4 {
    color: rgba(134, 154, 192, .4) !important
}

.bg-muted-transparent-5 {
    background: rgba(134, 154, 192, .5) !important
}

.text-muted-transparent-5 {
    color: rgba(134, 154, 192, .5) !important
}

.bg-muted-transparent-6 {
    background: rgba(134, 154, 192, .6) !important
}

.text-muted-transparent-6 {
    color: rgba(134, 154, 192, .6) !important
}

.bg-muted-transparent-7 {
    background: rgba(134, 154, 192, .7) !important
}

.text-muted-transparent-7 {
    color: rgba(134, 154, 192, .7) !important
}

.bg-muted-transparent-8 {
    background: rgba(134, 154, 192, .8) !important
}

.text-muted-transparent-8 {
    color: rgba(134, 154, 192, .8) !important
}

.bg-muted-transparent-9 {
    background: rgba(134, 154, 192, .9) !important
}

.text-muted-transparent-9 {
    color: rgba(134, 154, 192, .9) !important
}

.text-muted-100 {
    color: #e7ebf2 !important
}

.bg-muted-100 {
    background-color: #e7ebf2 !important
}

.text-muted-600 {
    color: #6b7b9a !important
}

.bg-muted-600 {
    background-color: #6b7b9a !important
}

.text-muted-200 {
    color: #cfd7e6 !important
}

.bg-muted-200 {
    background-color: #cfd7e6 !important
}

.text-muted-700 {
    color: #505c73 !important
}

.bg-muted-700 {
    background-color: #505c73 !important
}

.text-muted-300 {
    color: #b6c2d9 !important
}

.bg-muted-300 {
    background-color: #b6c2d9 !important
}

.text-muted-800 {
    color: #363e4d !important
}

.bg-muted-800 {
    background-color: #363e4d !important
}

.text-muted-400 {
    color: #9eaecd !important
}

.bg-muted-400 {
    background-color: #9eaecd !important
}

.text-muted-900 {
    color: #1b1f26 !important
}

.bg-muted-900 {
    background-color: #1b1f26 !important
}

.text-light {
    color: #ebeef4 !important
}

.bg-light {
    background-color: #ebeef4
}

.bg-gradient-light {
    background: #ebeef4 !important;
    background: -moz-linear-gradient(top, #fff, #dbe0eb) !important;
    background: -webkit-linear-gradient(top, #fff, #dbe0eb) !important;
    background: linear-gradient(to bottom, #fff, #dbe0eb) !important
}

.bg-light-transparent-1 {
    background: rgba(235, 238, 244, .1) !important
}

.text-light-transparent-1 {
    color: rgba(235, 238, 244, .1) !important
}

.bg-light-transparent-2 {
    background: rgba(235, 238, 244, .2) !important
}

.text-light-transparent-2 {
    color: rgba(235, 238, 244, .2) !important
}

.bg-light-transparent-3 {
    background: rgba(235, 238, 244, .3) !important
}

.text-light-transparent-3 {
    color: rgba(235, 238, 244, .3) !important
}

.bg-light-transparent-4 {
    background: rgba(235, 238, 244, .4) !important
}

.text-light-transparent-4 {
    color: rgba(235, 238, 244, .4) !important
}

.bg-light-transparent-5 {
    background: rgba(235, 238, 244, .5) !important
}

.text-light-transparent-5 {
    color: rgba(235, 238, 244, .5) !important
}

.bg-light-transparent-6 {
    background: rgba(235, 238, 244, .6) !important
}

.text-light-transparent-6 {
    color: rgba(235, 238, 244, .6) !important
}

.bg-light-transparent-7 {
    background: rgba(235, 238, 244, .7) !important
}

.text-light-transparent-7 {
    color: rgba(235, 238, 244, .7) !important
}

.bg-light-transparent-8 {
    background: rgba(235, 238, 244, .8) !important
}

.text-light-transparent-8 {
    color: rgba(235, 238, 244, .8) !important
}

.bg-light-transparent-9 {
    background: rgba(235, 238, 244, .9) !important
}

.text-light-transparent-9 {
    color: rgba(235, 238, 244, .9) !important
}

.text-light-100 {
    color: #fbfcfd !important
}

.bg-light-100 {
    background-color: #fbfcfd !important
}

.text-light-600 {
    color: #bcbec3 !important
}

.bg-light-600 {
    background-color: #bcbec3 !important
}

.text-light-200 {
    color: #f7f8fb !important
}

.bg-light-200 {
    background-color: #f7f8fb !important
}

.text-light-700 {
    color: #8d8f92 !important
}

.bg-light-700 {
    background-color: #8d8f92 !important
}

.text-light-300 {
    color: #f3f5f8 !important
}

.bg-light-300 {
    background-color: #f3f5f8 !important
}

.text-light-800 {
    color: #5e5f62 !important
}

.bg-light-800 {
    background-color: #5e5f62 !important
}

.text-light-400 {
    color: #eff1f6 !important
}

.bg-light-400 {
    background-color: #eff1f6 !important
}

.text-light-900 {
    color: #2f3031 !important
}

.bg-light-900 {
    background-color: #2f3031 !important
}

.text-gray-100 {
    color: #ebeef4 !important
}

.bg-gray-100 {
    background-color: #ebeef4
}

.bg-gradient-gray-100 {
    background: #ebeef4 !important;
    background: -moz-linear-gradient(top, #fff, #dbe0eb) !important;
    background: -webkit-linear-gradient(top, #fff, #dbe0eb) !important;
    background: linear-gradient(to bottom, #fff, #dbe0eb) !important
}

.bg-gray-100-transparent-1 {
    background: rgba(235, 238, 244, .1) !important
}

.text-gray-100-transparent-1 {
    color: rgba(235, 238, 244, .1) !important
}

.bg-gray-100-transparent-2 {
    background: rgba(235, 238, 244, .2) !important
}

.text-gray-100-transparent-2 {
    color: rgba(235, 238, 244, .2) !important
}

.bg-gray-100-transparent-3 {
    background: rgba(235, 238, 244, .3) !important
}

.text-gray-100-transparent-3 {
    color: rgba(235, 238, 244, .3) !important
}

.bg-gray-100-transparent-4 {
    background: rgba(235, 238, 244, .4) !important
}

.text-gray-100-transparent-4 {
    color: rgba(235, 238, 244, .4) !important
}

.bg-gray-100-transparent-5 {
    background: rgba(235, 238, 244, .5) !important
}

.text-gray-100-transparent-5 {
    color: rgba(235, 238, 244, .5) !important
}

.bg-gray-100-transparent-6 {
    background: rgba(235, 238, 244, .6) !important
}

.text-gray-100-transparent-6 {
    color: rgba(235, 238, 244, .6) !important
}

.bg-gray-100-transparent-7 {
    background: rgba(235, 238, 244, .7) !important
}

.text-gray-100-transparent-7 {
    color: rgba(235, 238, 244, .7) !important
}

.bg-gray-100-transparent-8 {
    background: rgba(235, 238, 244, .8) !important
}

.text-gray-100-transparent-8 {
    color: rgba(235, 238, 244, .8) !important
}

.bg-gray-100-transparent-9 {
    background: rgba(235, 238, 244, .9) !important
}

.text-gray-100-transparent-9 {
    color: rgba(235, 238, 244, .9) !important
}

.text-gray-100-100 {
    color: #fbfcfd !important
}

.bg-gray-100-100 {
    background-color: #fbfcfd !important
}

.text-gray-100-600 {
    color: #bcbec3 !important
}

.bg-gray-100-600 {
    background-color: #bcbec3 !important
}

.text-gray-100-200 {
    color: #f7f8fb !important
}

.bg-gray-100-200 {
    background-color: #f7f8fb !important
}

.text-gray-100-700 {
    color: #8d8f92 !important
}

.bg-gray-100-700 {
    background-color: #8d8f92 !important
}

.text-gray-100-300 {
    color: #f3f5f8 !important
}

.bg-gray-100-300 {
    background-color: #f3f5f8 !important
}

.text-gray-100-800 {
    color: #5e5f62 !important
}

.bg-gray-100-800 {
    background-color: #5e5f62 !important
}

.text-gray-100-400 {
    color: #eff1f6 !important
}

.bg-gray-100-400 {
    background-color: #eff1f6 !important
}

.text-gray-100-900 {
    color: #2f3031 !important
}

.bg-gray-100-900 {
    background-color: #2f3031 !important
}

.text-gray-200 {
    color: #dae0ec !important
}

.bg-gray-200 {
    background-color: #dae0ec
}

.bg-gradient-gray-200 {
    background: #dae0ec !important;
    background: -moz-linear-gradient(top, #fcfcfd, #c9d2e3) !important;
    background: -webkit-linear-gradient(top, #fcfcfd, #c9d2e3) !important;
    background: linear-gradient(to bottom, #fcfcfd, #c9d2e3) !important
}

.bg-gray-200-transparent-1 {
    background: rgba(218, 224, 236, .1) !important
}

.text-gray-200-transparent-1 {
    color: rgba(218, 224, 236, .1) !important
}

.bg-gray-200-transparent-2 {
    background: rgba(218, 224, 236, .2) !important
}

.text-gray-200-transparent-2 {
    color: rgba(218, 224, 236, .2) !important
}

.bg-gray-200-transparent-3 {
    background: rgba(218, 224, 236, .3) !important
}

.text-gray-200-transparent-3 {
    color: rgba(218, 224, 236, .3) !important
}

.bg-gray-200-transparent-4 {
    background: rgba(218, 224, 236, .4) !important
}

.text-gray-200-transparent-4 {
    color: rgba(218, 224, 236, .4) !important
}

.bg-gray-200-transparent-5 {
    background: rgba(218, 224, 236, .5) !important
}

.text-gray-200-transparent-5 {
    color: rgba(218, 224, 236, .5) !important
}

.bg-gray-200-transparent-6 {
    background: rgba(218, 224, 236, .6) !important
}

.text-gray-200-transparent-6 {
    color: rgba(218, 224, 236, .6) !important
}

.bg-gray-200-transparent-7 {
    background: rgba(218, 224, 236, .7) !important
}

.text-gray-200-transparent-7 {
    color: rgba(218, 224, 236, .7) !important
}

.bg-gray-200-transparent-8 {
    background: rgba(218, 224, 236, .8) !important
}

.text-gray-200-transparent-8 {
    color: rgba(218, 224, 236, .8) !important
}

.bg-gray-200-transparent-9 {
    background: rgba(218, 224, 236, .9) !important
}

.text-gray-200-transparent-9 {
    color: rgba(218, 224, 236, .9) !important
}

.text-gray-200-100 {
    color: #f8f9fb !important
}

.bg-gray-200-100 {
    background-color: #f8f9fb !important
}

.text-gray-200-600 {
    color: #aeb3bd !important
}

.bg-gray-200-600 {
    background-color: #aeb3bd !important
}

.text-gray-200-200 {
    color: #f0f3f7 !important
}

.bg-gray-200-200 {
    background-color: #f0f3f7 !important
}

.text-gray-200-700 {
    color: #83868e !important
}

.bg-gray-200-700 {
    background-color: #83868e !important
}

.text-gray-200-300 {
    color: #e9ecf4 !important
}

.bg-gray-200-300 {
    background-color: #e9ecf4 !important
}

.text-gray-200-800 {
    color: #575a5e !important
}

.bg-gray-200-800 {
    background-color: #575a5e !important
}

.text-gray-200-400 {
    color: #e1e6f0 !important
}

.bg-gray-200-400 {
    background-color: #e1e6f0 !important
}

.text-gray-200-900 {
    color: #2c2d2f !important
}

.bg-gray-200-900 {
    background-color: #2c2d2f !important
}

.text-gray-300 {
    color: #c9d2e3 !important
}

.bg-gray-300 {
    background-color: #c9d2e3
}

.bg-gradient-gray-300 {
    background: #c9d2e3 !important;
    background: -moz-linear-gradient(top, #ebeef4, #b8c4da) !important;
    background: -webkit-linear-gradient(top, #ebeef4, #b8c4da) !important;
    background: linear-gradient(to bottom, #ebeef4, #b8c4da) !important
}

.bg-gray-300-transparent-1 {
    background: rgba(201, 210, 227, .1) !important
}

.text-gray-300-transparent-1 {
    color: rgba(201, 210, 227, .1) !important
}

.bg-gray-300-transparent-2 {
    background: rgba(201, 210, 227, .2) !important
}

.text-gray-300-transparent-2 {
    color: rgba(201, 210, 227, .2) !important
}

.bg-gray-300-transparent-3 {
    background: rgba(201, 210, 227, .3) !important
}

.text-gray-300-transparent-3 {
    color: rgba(201, 210, 227, .3) !important
}

.bg-gray-300-transparent-4 {
    background: rgba(201, 210, 227, .4) !important
}

.text-gray-300-transparent-4 {
    color: rgba(201, 210, 227, .4) !important
}

.bg-gray-300-transparent-5 {
    background: rgba(201, 210, 227, .5) !important
}

.text-gray-300-transparent-5 {
    color: rgba(201, 210, 227, .5) !important
}

.bg-gray-300-transparent-6 {
    background: rgba(201, 210, 227, .6) !important
}

.text-gray-300-transparent-6 {
    color: rgba(201, 210, 227, .6) !important
}

.bg-gray-300-transparent-7 {
    background: rgba(201, 210, 227, .7) !important
}

.text-gray-300-transparent-7 {
    color: rgba(201, 210, 227, .7) !important
}

.bg-gray-300-transparent-8 {
    background: rgba(201, 210, 227, .8) !important
}

.text-gray-300-transparent-8 {
    color: rgba(201, 210, 227, .8) !important
}

.bg-gray-300-transparent-9 {
    background: rgba(201, 210, 227, .9) !important
}

.text-gray-300-transparent-9 {
    color: rgba(201, 210, 227, .9) !important
}

.text-gray-300-100 {
    color: #f4f6f9 !important
}

.bg-gray-300-100 {
    background-color: #f4f6f9 !important
}

.text-gray-300-600 {
    color: #a1a8b6 !important
}

.bg-gray-300-600 {
    background-color: #a1a8b6 !important
}

.text-gray-300-200 {
    color: #e9edf4 !important
}

.bg-gray-300-200 {
    background-color: #e9edf4 !important
}

.text-gray-300-700 {
    color: #797e88 !important
}

.bg-gray-300-700 {
    background-color: #797e88 !important
}

.text-gray-300-300 {
    color: #dfe4ee !important
}

.bg-gray-300-300 {
    background-color: #dfe4ee !important
}

.text-gray-300-800 {
    color: #50545b !important
}

.bg-gray-300-800 {
    background-color: #50545b !important
}

.text-gray-300-400 {
    color: #d4dbe9 !important
}

.bg-gray-300-400 {
    background-color: #d4dbe9 !important
}

.text-gray-300-900 {
    color: #282a2d !important
}

.bg-gray-300-900 {
    background-color: #282a2d !important
}

.text-gray-400 {
    color: #a8b6d1 !important
}

.bg-gray-400 {
    background-color: #a8b6d1
}

.bg-gradient-gray-400 {
    background: #a8b6d1 !important;
    background: -moz-linear-gradient(top, #c9d2e3, #97a8c8) !important;
    background: -webkit-linear-gradient(top, #c9d2e3, #97a8c8) !important;
    background: linear-gradient(to bottom, #c9d2e3, #97a8c8) !important
}

.bg-gray-400-transparent-1 {
    background: rgba(168, 182, 209, .1) !important
}

.text-gray-400-transparent-1 {
    color: rgba(168, 182, 209, .1) !important
}

.bg-gray-400-transparent-2 {
    background: rgba(168, 182, 209, .2) !important
}

.text-gray-400-transparent-2 {
    color: rgba(168, 182, 209, .2) !important
}

.bg-gray-400-transparent-3 {
    background: rgba(168, 182, 209, .3) !important
}

.text-gray-400-transparent-3 {
    color: rgba(168, 182, 209, .3) !important
}

.bg-gray-400-transparent-4 {
    background: rgba(168, 182, 209, .4) !important
}

.text-gray-400-transparent-4 {
    color: rgba(168, 182, 209, .4) !important
}

.bg-gray-400-transparent-5 {
    background: rgba(168, 182, 209, .5) !important
}

.text-gray-400-transparent-5 {
    color: rgba(168, 182, 209, .5) !important
}

.bg-gray-400-transparent-6 {
    background: rgba(168, 182, 209, .6) !important
}

.text-gray-400-transparent-6 {
    color: rgba(168, 182, 209, .6) !important
}

.bg-gray-400-transparent-7 {
    background: rgba(168, 182, 209, .7) !important
}

.text-gray-400-transparent-7 {
    color: rgba(168, 182, 209, .7) !important
}

.bg-gray-400-transparent-8 {
    background: rgba(168, 182, 209, .8) !important
}

.text-gray-400-transparent-8 {
    color: rgba(168, 182, 209, .8) !important
}

.bg-gray-400-transparent-9 {
    background: rgba(168, 182, 209, .9) !important
}

.text-gray-400-transparent-9 {
    color: rgba(168, 182, 209, .9) !important
}

.text-gray-400-100 {
    color: #eef0f6 !important
}

.bg-gray-400-100 {
    background-color: #eef0f6 !important
}

.text-gray-400-600 {
    color: #8692a7 !important
}

.bg-gray-400-600 {
    background-color: #8692a7 !important
}

.text-gray-400-200 {
    color: #dce2ed !important
}

.bg-gray-400-200 {
    background-color: #dce2ed !important
}

.text-gray-400-700 {
    color: #656d7d !important
}

.bg-gray-400-700 {
    background-color: #656d7d !important
}

.text-gray-400-300 {
    color: #cbd3e3 !important
}

.bg-gray-400-300 {
    background-color: #cbd3e3 !important
}

.text-gray-400-800 {
    color: #434954 !important
}

.bg-gray-400-800 {
    background-color: #434954 !important
}

.text-gray-400-400 {
    color: #b9c5da !important
}

.bg-gray-400-400 {
    background-color: #b9c5da !important
}

.text-gray-400-900 {
    color: #22242a !important
}

.bg-gray-400-900 {
    background-color: #22242a !important
}

.text-gray-500 {
    color: #869ac0 !important
}

.bg-gray-500 {
    background-color: #869ac0
}

.bg-gradient-gray-500 {
    background: #869ac0 !important;
    background: -moz-linear-gradient(top, #a8b6d1, #758cb7) !important;
    background: -webkit-linear-gradient(top, #a8b6d1, #758cb7) !important;
    background: linear-gradient(to bottom, #a8b6d1, #758cb7) !important
}

.bg-gray-500-transparent-1 {
    background: rgba(134, 154, 192, .1) !important
}

.text-gray-500-transparent-1 {
    color: rgba(134, 154, 192, .1) !important
}

.bg-gray-500-transparent-2 {
    background: rgba(134, 154, 192, .2) !important
}

.text-gray-500-transparent-2 {
    color: rgba(134, 154, 192, .2) !important
}

.bg-gray-500-transparent-3 {
    background: rgba(134, 154, 192, .3) !important
}

.text-gray-500-transparent-3 {
    color: rgba(134, 154, 192, .3) !important
}

.bg-gray-500-transparent-4 {
    background: rgba(134, 154, 192, .4) !important
}

.text-gray-500-transparent-4 {
    color: rgba(134, 154, 192, .4) !important
}

.bg-gray-500-transparent-5 {
    background: rgba(134, 154, 192, .5) !important
}

.text-gray-500-transparent-5 {
    color: rgba(134, 154, 192, .5) !important
}

.bg-gray-500-transparent-6 {
    background: rgba(134, 154, 192, .6) !important
}

.text-gray-500-transparent-6 {
    color: rgba(134, 154, 192, .6) !important
}

.bg-gray-500-transparent-7 {
    background: rgba(134, 154, 192, .7) !important
}

.text-gray-500-transparent-7 {
    color: rgba(134, 154, 192, .7) !important
}

.bg-gray-500-transparent-8 {
    background: rgba(134, 154, 192, .8) !important
}

.text-gray-500-transparent-8 {
    color: rgba(134, 154, 192, .8) !important
}

.bg-gray-500-transparent-9 {
    background: rgba(134, 154, 192, .9) !important
}

.text-gray-500-transparent-9 {
    color: rgba(134, 154, 192, .9) !important
}

.text-gray-500-100 {
    color: #e7ebf2 !important
}

.bg-gray-500-100 {
    background-color: #e7ebf2 !important
}

.text-gray-500-600 {
    color: #6b7b9a !important
}

.bg-gray-500-600 {
    background-color: #6b7b9a !important
}

.text-gray-500-200 {
    color: #cfd7e6 !important
}

.bg-gray-500-200 {
    background-color: #cfd7e6 !important
}

.text-gray-500-700 {
    color: #505c73 !important
}

.bg-gray-500-700 {
    background-color: #505c73 !important
}

.text-gray-500-300 {
    color: #b6c2d9 !important
}

.bg-gray-500-300 {
    background-color: #b6c2d9 !important
}

.text-gray-500-800 {
    color: #363e4d !important
}

.bg-gray-500-800 {
    background-color: #363e4d !important
}

.text-gray-500-400 {
    color: #9eaecd !important
}

.bg-gray-500-400 {
    background-color: #9eaecd !important
}

.text-gray-500-900 {
    color: #1b1f26 !important
}

.bg-gray-500-900 {
    background-color: #1b1f26 !important
}

.text-gray-600 {
    color: #657eae !important
}

.bg-gray-600 {
    background-color: #657eae
}

.bg-gradient-gray-600 {
    background: #657eae !important;
    background: -moz-linear-gradient(top, #869ac0, #5671a4) !important;
    background: -webkit-linear-gradient(top, #869ac0, #5671a4) !important;
    background: linear-gradient(to bottom, #869ac0, #5671a4) !important
}

.bg-gray-600-transparent-1 {
    background: rgba(101, 126, 174, .1) !important
}

.text-gray-600-transparent-1 {
    color: rgba(101, 126, 174, .1) !important
}

.bg-gray-600-transparent-2 {
    background: rgba(101, 126, 174, .2) !important
}

.text-gray-600-transparent-2 {
    color: rgba(101, 126, 174, .2) !important
}

.bg-gray-600-transparent-3 {
    background: rgba(101, 126, 174, .3) !important
}

.text-gray-600-transparent-3 {
    color: rgba(101, 126, 174, .3) !important
}

.bg-gray-600-transparent-4 {
    background: rgba(101, 126, 174, .4) !important
}

.text-gray-600-transparent-4 {
    color: rgba(101, 126, 174, .4) !important
}

.bg-gray-600-transparent-5 {
    background: rgba(101, 126, 174, .5) !important
}

.text-gray-600-transparent-5 {
    color: rgba(101, 126, 174, .5) !important
}

.bg-gray-600-transparent-6 {
    background: rgba(101, 126, 174, .6) !important
}

.text-gray-600-transparent-6 {
    color: rgba(101, 126, 174, .6) !important
}

.bg-gray-600-transparent-7 {
    background: rgba(101, 126, 174, .7) !important
}

.text-gray-600-transparent-7 {
    color: rgba(101, 126, 174, .7) !important
}

.bg-gray-600-transparent-8 {
    background: rgba(101, 126, 174, .8) !important
}

.text-gray-600-transparent-8 {
    color: rgba(101, 126, 174, .8) !important
}

.bg-gray-600-transparent-9 {
    background: rgba(101, 126, 174, .9) !important
}

.text-gray-600-transparent-9 {
    color: rgba(101, 126, 174, .9) !important
}

.text-gray-600-100 {
    color: #e0e5ef !important
}

.bg-gray-600-100 {
    background-color: #e0e5ef !important
}

.text-gray-600-600 {
    color: #51658b !important
}

.bg-gray-600-600 {
    background-color: #51658b !important
}

.text-gray-600-200 {
    color: #c1cbdf !important
}

.bg-gray-600-200 {
    background-color: #c1cbdf !important
}

.text-gray-600-700 {
    color: #3d4c68 !important
}

.bg-gray-600-700 {
    background-color: #3d4c68 !important
}

.text-gray-600-300 {
    color: #a3b2ce !important
}

.bg-gray-600-300 {
    background-color: #a3b2ce !important
}

.text-gray-600-800 {
    color: #283246 !important
}

.bg-gray-600-800 {
    background-color: #283246 !important
}

.text-gray-600-400 {
    color: #8498be !important
}

.bg-gray-600-400 {
    background-color: #8498be !important
}

.text-gray-600-900 {
    color: #141923 !important
}

.bg-gray-600-900 {
    background-color: #141923 !important
}

.text-gray-700 {
    color: #4d6593 !important
}

.bg-gray-700 {
    background-color: #4d6593
}

.bg-gradient-gray-700 {
    background: #4d6593 !important;
    background: -moz-linear-gradient(top, #657eae, #445a82) !important;
    background: -webkit-linear-gradient(top, #657eae, #445a82) !important;
    background: linear-gradient(to bottom, #657eae, #445a82) !important
}

.bg-gray-700-transparent-1 {
    background: rgba(77, 101, 147, .1) !important
}

.text-gray-700-transparent-1 {
    color: rgba(77, 101, 147, .1) !important
}

.bg-gray-700-transparent-2 {
    background: rgba(77, 101, 147, .2) !important
}

.text-gray-700-transparent-2 {
    color: rgba(77, 101, 147, .2) !important
}

.bg-gray-700-transparent-3 {
    background: rgba(77, 101, 147, .3) !important
}

.text-gray-700-transparent-3 {
    color: rgba(77, 101, 147, .3) !important
}

.bg-gray-700-transparent-4 {
    background: rgba(77, 101, 147, .4) !important
}

.text-gray-700-transparent-4 {
    color: rgba(77, 101, 147, .4) !important
}

.bg-gray-700-transparent-5 {
    background: rgba(77, 101, 147, .5) !important
}

.text-gray-700-transparent-5 {
    color: rgba(77, 101, 147, .5) !important
}

.bg-gray-700-transparent-6 {
    background: rgba(77, 101, 147, .6) !important
}

.text-gray-700-transparent-6 {
    color: rgba(77, 101, 147, .6) !important
}

.bg-gray-700-transparent-7 {
    background: rgba(77, 101, 147, .7) !important
}

.text-gray-700-transparent-7 {
    color: rgba(77, 101, 147, .7) !important
}

.bg-gray-700-transparent-8 {
    background: rgba(77, 101, 147, .8) !important
}

.text-gray-700-transparent-8 {
    color: rgba(77, 101, 147, .8) !important
}

.bg-gray-700-transparent-9 {
    background: rgba(77, 101, 147, .9) !important
}

.text-gray-700-transparent-9 {
    color: rgba(77, 101, 147, .9) !important
}

.text-gray-700-100 {
    color: #dbe0e9 !important
}

.bg-gray-700-100 {
    background-color: #dbe0e9 !important
}

.text-gray-700-600 {
    color: #3e5176 !important
}

.bg-gray-700-600 {
    background-color: #3e5176 !important
}

.text-gray-700-200 {
    color: #b8c1d4 !important
}

.bg-gray-700-200 {
    background-color: #b8c1d4 !important
}

.text-gray-700-700 {
    color: #2e3d58 !important
}

.bg-gray-700-700 {
    background-color: #2e3d58 !important
}

.text-gray-700-300 {
    color: #94a3be !important
}

.bg-gray-700-300 {
    background-color: #94a3be !important
}

.text-gray-700-800 {
    color: #1f283b !important
}

.bg-gray-700-800 {
    background-color: #1f283b !important
}

.text-gray-700-400 {
    color: #7184a9 !important
}

.bg-gray-700-400 {
    background-color: #7184a9 !important
}

.text-gray-700-900 {
    color: #0f141d !important
}

.bg-gray-700-900 {
    background-color: #0f141d !important
}

.text-gray-800 {
    color: #3c4e71 !important
}

.bg-gray-800 {
    background-color: #3c4e71
}

.bg-gradient-gray-800 {
    background: #3c4e71 !important;
    background: -moz-linear-gradient(top, #4e6592, #334360) !important;
    background: -webkit-linear-gradient(top, #4e6592, #334360) !important;
    background: linear-gradient(to bottom, #4e6592, #334360) !important
}

.bg-gray-800-transparent-1 {
    background: rgba(60, 78, 113, .1) !important
}

.text-gray-800-transparent-1 {
    color: rgba(60, 78, 113, .1) !important
}

.bg-gray-800-transparent-2 {
    background: rgba(60, 78, 113, .2) !important
}

.text-gray-800-transparent-2 {
    color: rgba(60, 78, 113, .2) !important
}

.bg-gray-800-transparent-3 {
    background: rgba(60, 78, 113, .3) !important
}

.text-gray-800-transparent-3 {
    color: rgba(60, 78, 113, .3) !important
}

.bg-gray-800-transparent-4 {
    background: rgba(60, 78, 113, .4) !important
}

.text-gray-800-transparent-4 {
    color: rgba(60, 78, 113, .4) !important
}

.bg-gray-800-transparent-5 {
    background: rgba(60, 78, 113, .5) !important
}

.text-gray-800-transparent-5 {
    color: rgba(60, 78, 113, .5) !important
}

.bg-gray-800-transparent-6 {
    background: rgba(60, 78, 113, .6) !important
}

.text-gray-800-transparent-6 {
    color: rgba(60, 78, 113, .6) !important
}

.bg-gray-800-transparent-7 {
    background: rgba(60, 78, 113, .7) !important
}

.text-gray-800-transparent-7 {
    color: rgba(60, 78, 113, .7) !important
}

.bg-gray-800-transparent-8 {
    background: rgba(60, 78, 113, .8) !important
}

.text-gray-800-transparent-8 {
    color: rgba(60, 78, 113, .8) !important
}

.bg-gray-800-transparent-9 {
    background: rgba(60, 78, 113, .9) !important
}

.text-gray-800-transparent-9 {
    color: rgba(60, 78, 113, .9) !important
}

.text-gray-800-100 {
    color: #d8dce3 !important
}

.bg-gray-800-100 {
    background-color: #d8dce3 !important
}

.text-gray-800-600 {
    color: #303e5a !important
}

.bg-gray-800-600 {
    background-color: #303e5a !important
}

.text-gray-800-200 {
    color: #b1b8c6 !important
}

.bg-gray-800-200 {
    background-color: #b1b8c6 !important
}

.text-gray-800-700 {
    color: #242f44 !important
}

.bg-gray-800-700 {
    background-color: #242f44 !important
}

.text-gray-800-300 {
    color: #8a95aa !important
}

.bg-gray-800-300 {
    background-color: #8a95aa !important
}

.text-gray-800-800 {
    color: #181f2d !important
}

.bg-gray-800-800 {
    background-color: #181f2d !important
}

.text-gray-800-400 {
    color: #63718d !important
}

.bg-gray-800-400 {
    background-color: #63718d !important
}

.text-gray-800-900 {
    color: #0c1017 !important
}

.bg-gray-800-900 {
    background-color: #0c1017 !important
}

.text-gray-900 {
    color: #212837 !important
}

.bg-gray-900 {
    background-color: #212837
}

.bg-gradient-gray-900 {
    background: #212837 !important;
    background: -moz-linear-gradient(top, #343f57, #171c27) !important;
    background: -webkit-linear-gradient(top, #343f57, #171c27) !important;
    background: linear-gradient(to bottom, #343f57, #171c27) !important
}

.bg-gray-900-transparent-1 {
    background: rgba(33, 40, 55, .1) !important
}

.text-gray-900-transparent-1 {
    color: rgba(33, 40, 55, .1) !important
}

.bg-gray-900-transparent-2 {
    background: rgba(33, 40, 55, .2) !important
}

.text-gray-900-transparent-2 {
    color: rgba(33, 40, 55, .2) !important
}

.bg-gray-900-transparent-3 {
    background: rgba(33, 40, 55, .3) !important
}

.text-gray-900-transparent-3 {
    color: rgba(33, 40, 55, .3) !important
}

.bg-gray-900-transparent-4 {
    background: rgba(33, 40, 55, .4) !important
}

.text-gray-900-transparent-4 {
    color: rgba(33, 40, 55, .4) !important
}

.bg-gray-900-transparent-5 {
    background: rgba(33, 40, 55, .5) !important
}

.text-gray-900-transparent-5 {
    color: rgba(33, 40, 55, .5) !important
}

.bg-gray-900-transparent-6 {
    background: rgba(33, 40, 55, .6) !important
}

.text-gray-900-transparent-6 {
    color: rgba(33, 40, 55, .6) !important
}

.bg-gray-900-transparent-7 {
    background: rgba(33, 40, 55, .7) !important
}

.text-gray-900-transparent-7 {
    color: rgba(33, 40, 55, .7) !important
}

.bg-gray-900-transparent-8 {
    background: rgba(33, 40, 55, .8) !important
}

.text-gray-900-transparent-8 {
    color: rgba(33, 40, 55, .8) !important
}

.bg-gray-900-transparent-9 {
    background: rgba(33, 40, 55, .9) !important
}

.text-gray-900-transparent-9 {
    color: rgba(33, 40, 55, .9) !important
}

.text-gray-900-100 {
    color: #d3d4d7 !important
}

.bg-gray-900-100 {
    background-color: #d3d4d7 !important
}

.text-gray-900-600 {
    color: #1a202c !important
}

.bg-gray-900-600 {
    background-color: #1a202c !important
}

.text-gray-900-200 {
    color: #a6a9af !important
}

.bg-gray-900-200 {
    background-color: #a6a9af !important
}

.text-gray-900-700 {
    color: #141821 !important
}

.bg-gray-900-700 {
    background-color: #141821 !important
}

.text-gray-900-300 {
    color: #7a7e87 !important
}

.bg-gray-900-300 {
    background-color: #7a7e87 !important
}

.text-gray-900-800 {
    color: #0d1016 !important
}

.bg-gray-900-800 {
    background-color: #0d1016 !important
}

.text-gray-900-400 {
    color: #4d535f !important
}

.bg-gray-900-400 {
    background-color: #4d535f !important
}

.text-gray-900-900 {
    color: #07080b !important
}

.bg-gray-900-900 {
    background-color: #07080b !important
}

.text-blue {
    color: #1f6bff !important
}

.bg-blue {
    background-color: #1f6bff
}

.bg-gradient-blue {
    background: #1f6bff !important;
    background: -moz-linear-gradient(top, #528dff, #065aff) !important;
    background: -webkit-linear-gradient(top, #528dff, #065aff) !important;
    background: linear-gradient(to bottom, #528dff, #065aff) !important
}

.bg-blue-transparent-1 {
    background: rgba(31, 107, 255, .1) !important
}

.text-blue-transparent-1 {
    color: rgba(31, 107, 255, .1) !important
}

.bg-blue-transparent-2 {
    background: rgba(31, 107, 255, .2) !important
}

.text-blue-transparent-2 {
    color: rgba(31, 107, 255, .2) !important
}

.bg-blue-transparent-3 {
    background: rgba(31, 107, 255, .3) !important
}

.text-blue-transparent-3 {
    color: rgba(31, 107, 255, .3) !important
}

.bg-blue-transparent-4 {
    background: rgba(31, 107, 255, .4) !important
}

.text-blue-transparent-4 {
    color: rgba(31, 107, 255, .4) !important
}

.bg-blue-transparent-5 {
    background: rgba(31, 107, 255, .5) !important
}

.text-blue-transparent-5 {
    color: rgba(31, 107, 255, .5) !important
}

.bg-blue-transparent-6 {
    background: rgba(31, 107, 255, .6) !important
}

.text-blue-transparent-6 {
    color: rgba(31, 107, 255, .6) !important
}

.bg-blue-transparent-7 {
    background: rgba(31, 107, 255, .7) !important
}

.text-blue-transparent-7 {
    color: rgba(31, 107, 255, .7) !important
}

.bg-blue-transparent-8 {
    background: rgba(31, 107, 255, .8) !important
}

.text-blue-transparent-8 {
    color: rgba(31, 107, 255, .8) !important
}

.bg-blue-transparent-9 {
    background: rgba(31, 107, 255, .9) !important
}

.text-blue-transparent-9 {
    color: rgba(31, 107, 255, .9) !important
}

.text-blue-100 {
    color: #d2e1ff !important
}

.bg-blue-100 {
    background-color: #d2e1ff !important
}

.text-blue-600 {
    color: #1956cc !important
}

.bg-blue-600 {
    background-color: #1956cc !important
}

.text-blue-200 {
    color: #a5c4ff !important
}

.bg-blue-200 {
    background-color: #a5c4ff !important
}

.text-blue-700 {
    color: #134099 !important
}

.bg-blue-700 {
    background-color: #134099 !important
}

.text-blue-300 {
    color: #79a6ff !important
}

.bg-blue-300 {
    background-color: #79a6ff !important
}

.text-blue-800 {
    color: #0c2b66 !important
}

.bg-blue-800 {
    background-color: #0c2b66 !important
}

.text-blue-400 {
    color: #4c89ff !important
}

.bg-blue-400 {
    background-color: #4c89ff !important
}

.text-blue-900 {
    color: #061533 !important
}

.bg-blue-900 {
    background-color: #061533 !important
}

.text-indigo {
    color: #640df3 !important
}

.bg-indigo {
    background-color: #640df3
}

.bg-gradient-indigo {
    background: #640df3 !important;
    background: -moz-linear-gradient(top, #833ef5, #5a0bdc) !important;
    background: -webkit-linear-gradient(top, #833ef5, #5a0bdc) !important;
    background: linear-gradient(to bottom, #833ef5, #5a0bdc) !important
}

.bg-indigo-transparent-1 {
    background: rgba(100, 13, 243, .1) !important
}

.text-indigo-transparent-1 {
    color: rgba(100, 13, 243, .1) !important
}

.bg-indigo-transparent-2 {
    background: rgba(100, 13, 243, .2) !important
}

.text-indigo-transparent-2 {
    color: rgba(100, 13, 243, .2) !important
}

.bg-indigo-transparent-3 {
    background: rgba(100, 13, 243, .3) !important
}

.text-indigo-transparent-3 {
    color: rgba(100, 13, 243, .3) !important
}

.bg-indigo-transparent-4 {
    background: rgba(100, 13, 243, .4) !important
}

.text-indigo-transparent-4 {
    color: rgba(100, 13, 243, .4) !important
}

.bg-indigo-transparent-5 {
    background: rgba(100, 13, 243, .5) !important
}

.text-indigo-transparent-5 {
    color: rgba(100, 13, 243, .5) !important
}

.bg-indigo-transparent-6 {
    background: rgba(100, 13, 243, .6) !important
}

.text-indigo-transparent-6 {
    color: rgba(100, 13, 243, .6) !important
}

.bg-indigo-transparent-7 {
    background: rgba(100, 13, 243, .7) !important
}

.text-indigo-transparent-7 {
    color: rgba(100, 13, 243, .7) !important
}

.bg-indigo-transparent-8 {
    background: rgba(100, 13, 243, .8) !important
}

.text-indigo-transparent-8 {
    color: rgba(100, 13, 243, .8) !important
}

.bg-indigo-transparent-9 {
    background: rgba(100, 13, 243, .9) !important
}

.text-indigo-transparent-9 {
    color: rgba(100, 13, 243, .9) !important
}

.text-indigo-100 {
    color: #e0cffd !important
}

.bg-indigo-100 {
    background-color: #e0cffd !important
}

.text-indigo-600 {
    color: #500ac2 !important
}

.bg-indigo-600 {
    background-color: #500ac2 !important
}

.text-indigo-200 {
    color: #c19efa !important
}

.bg-indigo-200 {
    background-color: #c19efa !important
}

.text-indigo-700 {
    color: #3c0892 !important
}

.bg-indigo-700 {
    background-color: #3c0892 !important
}

.text-indigo-300 {
    color: #a26ef8 !important
}

.bg-indigo-300 {
    background-color: #a26ef8 !important
}

.text-indigo-800 {
    color: #280561 !important
}

.bg-indigo-800 {
    background-color: #280561 !important
}

.text-indigo-400 {
    color: #833df5 !important
}

.bg-indigo-400 {
    background-color: #833df5 !important
}

.text-indigo-900 {
    color: #140331 !important
}

.bg-indigo-900 {
    background-color: #140331 !important
}

.text-purple {
    color: #6f42c1 !important
}

.bg-purple {
    background-color: #6f42c1
}

.bg-gradient-purple {
    background: #6f42c1 !important;
    background: -moz-linear-gradient(top, #8c68ce, #643ab0) !important;
    background: -webkit-linear-gradient(top, #8c68ce, #643ab0) !important;
    background: linear-gradient(to bottom, #8c68ce, #643ab0) !important
}

.bg-purple-transparent-1 {
    background: rgba(111, 66, 193, .1) !important
}

.text-purple-transparent-1 {
    color: rgba(111, 66, 193, .1) !important
}

.bg-purple-transparent-2 {
    background: rgba(111, 66, 193, .2) !important
}

.text-purple-transparent-2 {
    color: rgba(111, 66, 193, .2) !important
}

.bg-purple-transparent-3 {
    background: rgba(111, 66, 193, .3) !important
}

.text-purple-transparent-3 {
    color: rgba(111, 66, 193, .3) !important
}

.bg-purple-transparent-4 {
    background: rgba(111, 66, 193, .4) !important
}

.text-purple-transparent-4 {
    color: rgba(111, 66, 193, .4) !important
}

.bg-purple-transparent-5 {
    background: rgba(111, 66, 193, .5) !important
}

.text-purple-transparent-5 {
    color: rgba(111, 66, 193, .5) !important
}

.bg-purple-transparent-6 {
    background: rgba(111, 66, 193, .6) !important
}

.text-purple-transparent-6 {
    color: rgba(111, 66, 193, .6) !important
}

.bg-purple-transparent-7 {
    background: rgba(111, 66, 193, .7) !important
}

.text-purple-transparent-7 {
    color: rgba(111, 66, 193, .7) !important
}

.bg-purple-transparent-8 {
    background: rgba(111, 66, 193, .8) !important
}

.text-purple-transparent-8 {
    color: rgba(111, 66, 193, .8) !important
}

.bg-purple-transparent-9 {
    background: rgba(111, 66, 193, .9) !important
}

.text-purple-transparent-9 {
    color: rgba(111, 66, 193, .9) !important
}

.text-purple-100 {
    color: #e2d9f3 !important
}

.bg-purple-100 {
    background-color: #e2d9f3 !important
}

.text-purple-600 {
    color: #59359a !important
}

.bg-purple-600 {
    background-color: #59359a !important
}

.text-purple-200 {
    color: #c5b3e6 !important
}

.bg-purple-200 {
    background-color: #c5b3e6 !important
}

.text-purple-700 {
    color: #432874 !important
}

.bg-purple-700 {
    background-color: #432874 !important
}

.text-purple-300 {
    color: #a98eda !important
}

.bg-purple-300 {
    background-color: #a98eda !important
}

.text-purple-800 {
    color: #2c1a4d !important
}

.bg-purple-800 {
    background-color: #2c1a4d !important
}

.text-purple-400 {
    color: #8c68cd !important
}

.bg-purple-400 {
    background-color: #8c68cd !important
}

.text-purple-900 {
    color: #160d27 !important
}

.bg-purple-900 {
    background-color: #160d27 !important
}

.text-pink {
    color: #d63384 !important
}

.bg-pink {
    background-color: #d63384
}

.bg-gradient-pink {
    background: #d63384 !important;
    background: -moz-linear-gradient(top, #df5d9e, #c72877) !important;
    background: -webkit-linear-gradient(top, #df5d9e, #c72877) !important;
    background: linear-gradient(to bottom, #df5d9e, #c72877) !important
}

.bg-pink-transparent-1 {
    background: rgba(214, 51, 132, .1) !important
}

.text-pink-transparent-1 {
    color: rgba(214, 51, 132, .1) !important
}

.bg-pink-transparent-2 {
    background: rgba(214, 51, 132, .2) !important
}

.text-pink-transparent-2 {
    color: rgba(214, 51, 132, .2) !important
}

.bg-pink-transparent-3 {
    background: rgba(214, 51, 132, .3) !important
}

.text-pink-transparent-3 {
    color: rgba(214, 51, 132, .3) !important
}

.bg-pink-transparent-4 {
    background: rgba(214, 51, 132, .4) !important
}

.text-pink-transparent-4 {
    color: rgba(214, 51, 132, .4) !important
}

.bg-pink-transparent-5 {
    background: rgba(214, 51, 132, .5) !important
}

.text-pink-transparent-5 {
    color: rgba(214, 51, 132, .5) !important
}

.bg-pink-transparent-6 {
    background: rgba(214, 51, 132, .6) !important
}

.text-pink-transparent-6 {
    color: rgba(214, 51, 132, .6) !important
}

.bg-pink-transparent-7 {
    background: rgba(214, 51, 132, .7) !important
}

.text-pink-transparent-7 {
    color: rgba(214, 51, 132, .7) !important
}

.bg-pink-transparent-8 {
    background: rgba(214, 51, 132, .8) !important
}

.text-pink-transparent-8 {
    color: rgba(214, 51, 132, .8) !important
}

.bg-pink-transparent-9 {
    background: rgba(214, 51, 132, .9) !important
}

.text-pink-transparent-9 {
    color: rgba(214, 51, 132, .9) !important
}

.text-pink-100 {
    color: #f7d6e6 !important
}

.bg-pink-100 {
    background-color: #f7d6e6 !important
}

.text-pink-600 {
    color: #ab296a !important
}

.bg-pink-600 {
    background-color: #ab296a !important
}

.text-pink-200 {
    color: #efadce !important
}

.bg-pink-200 {
    background-color: #efadce !important
}

.text-pink-700 {
    color: #801f4f !important
}

.bg-pink-700 {
    background-color: #801f4f !important
}

.text-pink-300 {
    color: #e685b5 !important
}

.bg-pink-300 {
    background-color: #e685b5 !important
}

.text-pink-800 {
    color: #561435 !important
}

.bg-pink-800 {
    background-color: #561435 !important
}

.text-pink-400 {
    color: #de5c9d !important
}

.bg-pink-400 {
    background-color: #de5c9d !important
}

.text-pink-900 {
    color: #2b0a1a !important
}

.bg-pink-900 {
    background-color: #2b0a1a !important
}

.text-red {
    color: #e6180d !important
}

.bg-red {
    background-color: #e6180d
}

.bg-gradient-red {
    background: #e6180d !important;
    background: -moz-linear-gradient(top, #f33c33, #ce150c) !important;
    background: -webkit-linear-gradient(top, #f33c33, #ce150c) !important;
    background: linear-gradient(to bottom, #f33c33, #ce150c) !important
}

.bg-red-transparent-1 {
    background: rgba(230, 24, 13, .1) !important
}

.text-red-transparent-1 {
    color: rgba(230, 24, 13, .1) !important
}

.bg-red-transparent-2 {
    background: rgba(230, 24, 13, .2) !important
}

.text-red-transparent-2 {
    color: rgba(230, 24, 13, .2) !important
}

.bg-red-transparent-3 {
    background: rgba(230, 24, 13, .3) !important
}

.text-red-transparent-3 {
    color: rgba(230, 24, 13, .3) !important
}

.bg-red-transparent-4 {
    background: rgba(230, 24, 13, .4) !important
}

.text-red-transparent-4 {
    color: rgba(230, 24, 13, .4) !important
}

.bg-red-transparent-5 {
    background: rgba(230, 24, 13, .5) !important
}

.text-red-transparent-5 {
    color: rgba(230, 24, 13, .5) !important
}

.bg-red-transparent-6 {
    background: rgba(230, 24, 13, .6) !important
}

.text-red-transparent-6 {
    color: rgba(230, 24, 13, .6) !important
}

.bg-red-transparent-7 {
    background: rgba(230, 24, 13, .7) !important
}

.text-red-transparent-7 {
    color: rgba(230, 24, 13, .7) !important
}

.bg-red-transparent-8 {
    background: rgba(230, 24, 13, .8) !important
}

.text-red-transparent-8 {
    color: rgba(230, 24, 13, .8) !important
}

.bg-red-transparent-9 {
    background: rgba(230, 24, 13, .9) !important
}

.text-red-transparent-9 {
    color: rgba(230, 24, 13, .9) !important
}

.text-red-100 {
    color: #fad1cf !important
}

.bg-red-100 {
    background-color: #fad1cf !important
}

.text-red-600 {
    color: #b8130a !important
}

.bg-red-600 {
    background-color: #b8130a !important
}

.text-red-200 {
    color: #f5a39e !important
}

.bg-red-200 {
    background-color: #f5a39e !important
}

.text-red-700 {
    color: #8a0e08 !important
}

.bg-red-700 {
    background-color: #8a0e08 !important
}

.text-red-300 {
    color: #f0746e !important
}

.bg-red-300 {
    background-color: #f0746e !important
}

.text-red-800 {
    color: #5c0a05 !important
}

.bg-red-800 {
    background-color: #5c0a05 !important
}

.text-red-400 {
    color: #eb463d !important
}

.bg-red-400 {
    background-color: #eb463d !important
}

.text-red-900 {
    color: #2e0503 !important
}

.bg-red-900 {
    background-color: #2e0503 !important
}

.text-orange {
    color: #ff9500 !important
}

.bg-orange {
    background-color: #ff9500
}

.bg-gradient-orange {
    background: #ff9500 !important;
    background: -moz-linear-gradient(top, #fa3, #e68600) !important;
    background: -webkit-linear-gradient(top, #fa3, #e68600) !important;
    background: linear-gradient(to bottom, #fa3, #e68600) !important
}

.bg-orange-transparent-1 {
    background: rgba(255, 149, 0, .1) !important
}

.text-orange-transparent-1 {
    color: rgba(255, 149, 0, .1) !important
}

.bg-orange-transparent-2 {
    background: rgba(255, 149, 0, .2) !important
}

.text-orange-transparent-2 {
    color: rgba(255, 149, 0, .2) !important
}

.bg-orange-transparent-3 {
    background: rgba(255, 149, 0, .3) !important
}

.text-orange-transparent-3 {
    color: rgba(255, 149, 0, .3) !important
}

.bg-orange-transparent-4 {
    background: rgba(255, 149, 0, .4) !important
}

.text-orange-transparent-4 {
    color: rgba(255, 149, 0, .4) !important
}

.bg-orange-transparent-5 {
    background: rgba(255, 149, 0, .5) !important
}

.text-orange-transparent-5 {
    color: rgba(255, 149, 0, .5) !important
}

.bg-orange-transparent-6 {
    background: rgba(255, 149, 0, .6) !important
}

.text-orange-transparent-6 {
    color: rgba(255, 149, 0, .6) !important
}

.bg-orange-transparent-7 {
    background: rgba(255, 149, 0, .7) !important
}

.text-orange-transparent-7 {
    color: rgba(255, 149, 0, .7) !important
}

.bg-orange-transparent-8 {
    background: rgba(255, 149, 0, .8) !important
}

.text-orange-transparent-8 {
    color: rgba(255, 149, 0, .8) !important
}

.bg-orange-transparent-9 {
    background: rgba(255, 149, 0, .9) !important
}

.text-orange-transparent-9 {
    color: rgba(255, 149, 0, .9) !important
}

.text-orange-100 {
    color: #ffeacc !important
}

.bg-orange-100 {
    background-color: #ffeacc !important
}

.text-orange-600 {
    color: #c70 !important
}

.bg-orange-600 {
    background-color: #c70 !important
}

.text-orange-200 {
    color: #ffd599 !important
}

.bg-orange-200 {
    background-color: #ffd599 !important
}

.text-orange-700 {
    color: #995900 !important
}

.bg-orange-700 {
    background-color: #995900 !important
}

.text-orange-300 {
    color: #ffbf66 !important
}

.bg-orange-300 {
    background-color: #ffbf66 !important
}

.text-orange-800 {
    color: #663c00 !important
}

.bg-orange-800 {
    background-color: #663c00 !important
}

.text-orange-400 {
    color: #fa3 !important
}

.bg-orange-400 {
    background-color: #fa3 !important
}

.text-orange-900 {
    color: #331e00 !important
}

.bg-orange-900 {
    background-color: #331e00 !important
}

.text-yellow {
    color: #fc0 !important
}

.bg-yellow {
    background-color: #fc0
}

.bg-gradient-yellow {
    background: #fc0 !important;
    background: -moz-linear-gradient(top, #ffd633, #e6b800) !important;
    background: -webkit-linear-gradient(top, #ffd633, #e6b800) !important;
    background: linear-gradient(to bottom, #ffd633, #e6b800) !important
}

.bg-yellow-transparent-1 {
    background: rgba(255, 204, 0, .1) !important
}

.text-yellow-transparent-1 {
    color: rgba(255, 204, 0, .1) !important
}

.bg-yellow-transparent-2 {
    background: rgba(255, 204, 0, .2) !important
}

.text-yellow-transparent-2 {
    color: rgba(255, 204, 0, .2) !important
}

.bg-yellow-transparent-3 {
    background: rgba(255, 204, 0, .3) !important
}

.text-yellow-transparent-3 {
    color: rgba(255, 204, 0, .3) !important
}

.bg-yellow-transparent-4 {
    background: rgba(255, 204, 0, .4) !important
}

.text-yellow-transparent-4 {
    color: rgba(255, 204, 0, .4) !important
}

.bg-yellow-transparent-5 {
    background: rgba(255, 204, 0, .5) !important
}

.text-yellow-transparent-5 {
    color: rgba(255, 204, 0, .5) !important
}

.bg-yellow-transparent-6 {
    background: rgba(255, 204, 0, .6) !important
}

.text-yellow-transparent-6 {
    color: rgba(255, 204, 0, .6) !important
}

.bg-yellow-transparent-7 {
    background: rgba(255, 204, 0, .7) !important
}

.text-yellow-transparent-7 {
    color: rgba(255, 204, 0, .7) !important
}

.bg-yellow-transparent-8 {
    background: rgba(255, 204, 0, .8) !important
}

.text-yellow-transparent-8 {
    color: rgba(255, 204, 0, .8) !important
}

.bg-yellow-transparent-9 {
    background: rgba(255, 204, 0, .9) !important
}

.text-yellow-transparent-9 {
    color: rgba(255, 204, 0, .9) !important
}

.text-yellow-100 {
    color: #fff5cc !important
}

.bg-yellow-100 {
    background-color: #fff5cc !important
}

.text-yellow-600 {
    color: #cca300 !important
}

.bg-yellow-600 {
    background-color: #cca300 !important
}

.text-yellow-200 {
    color: #ffeb99 !important
}

.bg-yellow-200 {
    background-color: #ffeb99 !important
}

.text-yellow-700 {
    color: #997a00 !important
}

.bg-yellow-700 {
    background-color: #997a00 !important
}

.text-yellow-300 {
    color: #ffe066 !important
}

.bg-yellow-300 {
    background-color: #ffe066 !important
}

.text-yellow-800 {
    color: #665200 !important
}

.bg-yellow-800 {
    background-color: #665200 !important
}

.text-yellow-400 {
    color: #ffd633 !important
}

.bg-yellow-400 {
    background-color: #ffd633 !important
}

.text-yellow-900 {
    color: #332900 !important
}

.bg-yellow-900 {
    background-color: #332900 !important
}

.text-green {
    color: #028824 !important
}

.bg-green {
    background-color: #028824
}

.bg-gradient-green {
    background: #028824 !important;
    background: -moz-linear-gradient(top, #03ba31, #026f1d) !important;
    background: -webkit-linear-gradient(top, #03ba31, #026f1d) !important;
    background: linear-gradient(to bottom, #03ba31, #026f1d) !important
}

.bg-green-transparent-1 {
    background: rgba(2, 136, 36, .1) !important
}

.text-green-transparent-1 {
    color: rgba(2, 136, 36, .1) !important
}

.bg-green-transparent-2 {
    background: rgba(2, 136, 36, .2) !important
}

.text-green-transparent-2 {
    color: rgba(2, 136, 36, .2) !important
}

.bg-green-transparent-3 {
    background: rgba(2, 136, 36, .3) !important
}

.text-green-transparent-3 {
    color: rgba(2, 136, 36, .3) !important
}

.bg-green-transparent-4 {
    background: rgba(2, 136, 36, .4) !important
}

.text-green-transparent-4 {
    color: rgba(2, 136, 36, .4) !important
}

.bg-green-transparent-5 {
    background: rgba(2, 136, 36, .5) !important
}

.text-green-transparent-5 {
    color: rgba(2, 136, 36, .5) !important
}

.bg-green-transparent-6 {
    background: rgba(2, 136, 36, .6) !important
}

.text-green-transparent-6 {
    color: rgba(2, 136, 36, .6) !important
}

.bg-green-transparent-7 {
    background: rgba(2, 136, 36, .7) !important
}

.text-green-transparent-7 {
    color: rgba(2, 136, 36, .7) !important
}

.bg-green-transparent-8 {
    background: rgba(2, 136, 36, .8) !important
}

.text-green-transparent-8 {
    color: rgba(2, 136, 36, .8) !important
}

.bg-green-transparent-9 {
    background: rgba(2, 136, 36, .9) !important
}

.text-green-transparent-9 {
    color: rgba(2, 136, 36, .9) !important
}

.text-green-100 {
    color: #cce7d3 !important
}

.bg-green-100 {
    background-color: #cce7d3 !important
}

.text-green-600 {
    color: #026d1d !important
}

.bg-green-600 {
    background-color: #026d1d !important
}

.text-green-200 {
    color: #9acfa7 !important
}

.bg-green-200 {
    background-color: #9acfa7 !important
}

.text-green-700 {
    color: #015216 !important
}

.bg-green-700 {
    background-color: #015216 !important
}

.text-green-300 {
    color: #67b87c !important
}

.bg-green-300 {
    background-color: #67b87c !important
}

.text-green-800 {
    color: #01360e !important
}

.bg-green-800 {
    background-color: #01360e !important
}

.text-green-400 {
    color: #35a050 !important
}

.bg-green-400 {
    background-color: #35a050 !important
}

.text-green-900 {
    color: #001b07 !important
}

.bg-green-900 {
    background-color: #001b07 !important
}

.text-teal {
    color: #20c997 !important
}

.bg-teal {
    background-color: #20c997
}

.bg-gradient-teal {
    background: #20c997 !important;
    background: -moz-linear-gradient(top, #3ce0af, #1cb386) !important;
    background: -webkit-linear-gradient(top, #3ce0af, #1cb386) !important;
    background: linear-gradient(to bottom, #3ce0af, #1cb386) !important
}

.bg-teal-transparent-1 {
    background: rgba(32, 201, 151, .1) !important
}

.text-teal-transparent-1 {
    color: rgba(32, 201, 151, .1) !important
}

.bg-teal-transparent-2 {
    background: rgba(32, 201, 151, .2) !important
}

.text-teal-transparent-2 {
    color: rgba(32, 201, 151, .2) !important
}

.bg-teal-transparent-3 {
    background: rgba(32, 201, 151, .3) !important
}

.text-teal-transparent-3 {
    color: rgba(32, 201, 151, .3) !important
}

.bg-teal-transparent-4 {
    background: rgba(32, 201, 151, .4) !important
}

.text-teal-transparent-4 {
    color: rgba(32, 201, 151, .4) !important
}

.bg-teal-transparent-5 {
    background: rgba(32, 201, 151, .5) !important
}

.text-teal-transparent-5 {
    color: rgba(32, 201, 151, .5) !important
}

.bg-teal-transparent-6 {
    background: rgba(32, 201, 151, .6) !important
}

.text-teal-transparent-6 {
    color: rgba(32, 201, 151, .6) !important
}

.bg-teal-transparent-7 {
    background: rgba(32, 201, 151, .7) !important
}

.text-teal-transparent-7 {
    color: rgba(32, 201, 151, .7) !important
}

.bg-teal-transparent-8 {
    background: rgba(32, 201, 151, .8) !important
}

.text-teal-transparent-8 {
    color: rgba(32, 201, 151, .8) !important
}

.bg-teal-transparent-9 {
    background: rgba(32, 201, 151, .9) !important
}

.text-teal-transparent-9 {
    color: rgba(32, 201, 151, .9) !important
}

.text-teal-100 {
    color: #d2f4ea !important
}

.bg-teal-100 {
    background-color: #d2f4ea !important
}

.text-teal-600 {
    color: #1aa179 !important
}

.bg-teal-600 {
    background-color: #1aa179 !important
}

.text-teal-200 {
    color: #a6e9d5 !important
}

.bg-teal-200 {
    background-color: #a6e9d5 !important
}

.text-teal-700 {
    color: #13795b !important
}

.bg-teal-700 {
    background-color: #13795b !important
}

.text-teal-300 {
    color: #79dfc1 !important
}

.bg-teal-300 {
    background-color: #79dfc1 !important
}

.text-teal-800 {
    color: #0d503c !important
}

.bg-teal-800 {
    background-color: #0d503c !important
}

.text-teal-400 {
    color: #4dd4ac !important
}

.bg-teal-400 {
    background-color: #4dd4ac !important
}

.text-teal-900 {
    color: #06281e !important
}

.bg-teal-900 {
    background-color: #06281e !important
}

.text-cyan {
    color: #30beff !important
}

.bg-cyan {
    background-color: #30beff
}

.bg-gradient-cyan {
    background: #30beff !important;
    background: -moz-linear-gradient(top, #63ceff, #17b6ff) !important;
    background: -webkit-linear-gradient(top, #63ceff, #17b6ff) !important;
    background: linear-gradient(to bottom, #63ceff, #17b6ff) !important
}

.bg-cyan-transparent-1 {
    background: rgba(48, 190, 255, .1) !important
}

.text-cyan-transparent-1 {
    color: rgba(48, 190, 255, .1) !important
}

.bg-cyan-transparent-2 {
    background: rgba(48, 190, 255, .2) !important
}

.text-cyan-transparent-2 {
    color: rgba(48, 190, 255, .2) !important
}

.bg-cyan-transparent-3 {
    background: rgba(48, 190, 255, .3) !important
}

.text-cyan-transparent-3 {
    color: rgba(48, 190, 255, .3) !important
}

.bg-cyan-transparent-4 {
    background: rgba(48, 190, 255, .4) !important
}

.text-cyan-transparent-4 {
    color: rgba(48, 190, 255, .4) !important
}

.bg-cyan-transparent-5 {
    background: rgba(48, 190, 255, .5) !important
}

.text-cyan-transparent-5 {
    color: rgba(48, 190, 255, .5) !important
}

.bg-cyan-transparent-6 {
    background: rgba(48, 190, 255, .6) !important
}

.text-cyan-transparent-6 {
    color: rgba(48, 190, 255, .6) !important
}

.bg-cyan-transparent-7 {
    background: rgba(48, 190, 255, .7) !important
}

.text-cyan-transparent-7 {
    color: rgba(48, 190, 255, .7) !important
}

.bg-cyan-transparent-8 {
    background: rgba(48, 190, 255, .8) !important
}

.text-cyan-transparent-8 {
    color: rgba(48, 190, 255, .8) !important
}

.bg-cyan-transparent-9 {
    background: rgba(48, 190, 255, .9) !important
}

.text-cyan-transparent-9 {
    color: rgba(48, 190, 255, .9) !important
}

.text-cyan-100 {
    color: #d6f2ff !important
}

.bg-cyan-100 {
    background-color: #d6f2ff !important
}

.text-cyan-600 {
    color: #2698cc !important
}

.bg-cyan-600 {
    background-color: #2698cc !important
}

.text-cyan-200 {
    color: #ace5ff !important
}

.bg-cyan-200 {
    background-color: #ace5ff !important
}

.text-cyan-700 {
    color: #1d7299 !important
}

.bg-cyan-700 {
    background-color: #1d7299 !important
}

.text-cyan-300 {
    color: #83d8ff !important
}

.bg-cyan-300 {
    background-color: #83d8ff !important
}

.text-cyan-800 {
    color: #134c66 !important
}

.bg-cyan-800 {
    background-color: #134c66 !important
}

.text-cyan-400 {
    color: #59cbff !important
}

.bg-cyan-400 {
    background-color: #59cbff !important
}

.text-cyan-900 {
    color: #0a2633 !important
}

.bg-cyan-900 {
    background-color: #0a2633 !important
}

.text-white {
    color: #fff !important
}

.bg-white {
    background-color: #fff
}

.bg-gradient-white {
    background: #fff !important;
    background: -moz-linear-gradient(top, #fff, #f2f2f2) !important;
    background: -webkit-linear-gradient(top, #fff, #f2f2f2) !important;
    background: linear-gradient(to bottom, #fff, #f2f2f2) !important
}

.bg-white-transparent-1 {
    background: rgba(255, 255, 255, .1) !important
}

.text-white-transparent-1 {
    color: rgba(255, 255, 255, .1) !important
}

.bg-white-transparent-2 {
    background: rgba(255, 255, 255, .2) !important
}

.text-white-transparent-2 {
    color: rgba(255, 255, 255, .2) !important
}

.bg-white-transparent-3 {
    background: rgba(255, 255, 255, .3) !important
}

.text-white-transparent-3 {
    color: rgba(255, 255, 255, .3) !important
}

.bg-white-transparent-4 {
    background: rgba(255, 255, 255, .4) !important
}

.text-white-transparent-4 {
    color: rgba(255, 255, 255, .4) !important
}

.bg-white-transparent-5 {
    background: rgba(255, 255, 255, .5) !important
}

.text-white-transparent-5 {
    color: rgba(255, 255, 255, .5) !important
}

.bg-white-transparent-6 {
    background: rgba(255, 255, 255, .6) !important
}

.text-white-transparent-6 {
    color: rgba(255, 255, 255, .6) !important
}

.bg-white-transparent-7 {
    background: rgba(255, 255, 255, .7) !important
}

.text-white-transparent-7 {
    color: rgba(255, 255, 255, .7) !important
}

.bg-white-transparent-8 {
    background: rgba(255, 255, 255, .8) !important
}

.text-white-transparent-8 {
    color: rgba(255, 255, 255, .8) !important
}

.bg-white-transparent-9 {
    background: rgba(255, 255, 255, .9) !important
}

.text-white-transparent-9 {
    color: rgba(255, 255, 255, .9) !important
}

.text-white-100 {
    color: #fff !important
}

.bg-white-100 {
    background-color: #fff !important
}

.text-white-600 {
    color: #ccc !important
}

.bg-white-600 {
    background-color: #ccc !important
}

.text-white-200 {
    color: #fff !important
}

.bg-white-200 {
    background-color: #fff !important
}

.text-white-700 {
    color: #999 !important
}

.bg-white-700 {
    background-color: #999 !important
}

.text-white-300 {
    color: #fff !important
}

.bg-white-300 {
    background-color: #fff !important
}

.text-white-800 {
    color: #666 !important
}

.bg-white-800 {
    background-color: #666 !important
}

.text-white-400 {
    color: #fff !important
}

.bg-white-400 {
    background-color: #fff !important
}

.text-white-900 {
    color: #333 !important
}

.bg-white-900 {
    background-color: #333 !important
}

.text-gray {
    color: #657eae !important
}

.bg-gray {
    background-color: #657eae
}

.bg-gradient-gray {
    background: #657eae !important;
    background: -moz-linear-gradient(top, #869ac0, #5671a4) !important;
    background: -webkit-linear-gradient(top, #869ac0, #5671a4) !important;
    background: linear-gradient(to bottom, #869ac0, #5671a4) !important
}

.bg-gray-transparent-1 {
    background: rgba(101, 126, 174, .1) !important
}

.text-gray-transparent-1 {
    color: rgba(101, 126, 174, .1) !important
}

.bg-gray-transparent-2 {
    background: rgba(101, 126, 174, .2) !important
}

.text-gray-transparent-2 {
    color: rgba(101, 126, 174, .2) !important
}

.bg-gray-transparent-3 {
    background: rgba(101, 126, 174, .3) !important
}

.text-gray-transparent-3 {
    color: rgba(101, 126, 174, .3) !important
}

.bg-gray-transparent-4 {
    background: rgba(101, 126, 174, .4) !important
}

.text-gray-transparent-4 {
    color: rgba(101, 126, 174, .4) !important
}

.bg-gray-transparent-5 {
    background: rgba(101, 126, 174, .5) !important
}

.text-gray-transparent-5 {
    color: rgba(101, 126, 174, .5) !important
}

.bg-gray-transparent-6 {
    background: rgba(101, 126, 174, .6) !important
}

.text-gray-transparent-6 {
    color: rgba(101, 126, 174, .6) !important
}

.bg-gray-transparent-7 {
    background: rgba(101, 126, 174, .7) !important
}

.text-gray-transparent-7 {
    color: rgba(101, 126, 174, .7) !important
}

.bg-gray-transparent-8 {
    background: rgba(101, 126, 174, .8) !important
}

.text-gray-transparent-8 {
    color: rgba(101, 126, 174, .8) !important
}

.bg-gray-transparent-9 {
    background: rgba(101, 126, 174, .9) !important
}

.text-gray-transparent-9 {
    color: rgba(101, 126, 174, .9) !important
}

.text-gray-100 {
    color: #e0e5ef !important
}

.bg-gray-100 {
    background-color: #e0e5ef !important
}

.text-gray-600 {
    color: #51658b !important
}

.bg-gray-600 {
    background-color: #51658b !important
}

.text-gray-200 {
    color: #c1cbdf !important
}

.bg-gray-200 {
    background-color: #c1cbdf !important
}

.text-gray-700 {
    color: #3d4c68 !important
}

.bg-gray-700 {
    background-color: #3d4c68 !important
}

.text-gray-300 {
    color: #a3b2ce !important
}

.bg-gray-300 {
    background-color: #a3b2ce !important
}

.text-gray-800 {
    color: #283246 !important
}

.bg-gray-800 {
    background-color: #283246 !important
}

.text-gray-400 {
    color: #8498be !important
}

.bg-gray-400 {
    background-color: #8498be !important
}

.text-gray-900 {
    color: #141923 !important
}

.bg-gray-900 {
    background-color: #141923 !important
}

.text-gray-dark {
    color: #3c4e71 !important
}

.bg-gray-dark {
    background-color: #3c4e71
}

.bg-gradient-gray-dark {
    background: #3c4e71 !important;
    background: -moz-linear-gradient(top, #4e6592, #334360) !important;
    background: -webkit-linear-gradient(top, #4e6592, #334360) !important;
    background: linear-gradient(to bottom, #4e6592, #334360) !important
}

.bg-gray-dark-transparent-1 {
    background: rgba(60, 78, 113, .1) !important
}

.text-gray-dark-transparent-1 {
    color: rgba(60, 78, 113, .1) !important
}

.bg-gray-dark-transparent-2 {
    background: rgba(60, 78, 113, .2) !important
}

.text-gray-dark-transparent-2 {
    color: rgba(60, 78, 113, .2) !important
}

.bg-gray-dark-transparent-3 {
    background: rgba(60, 78, 113, .3) !important
}

.text-gray-dark-transparent-3 {
    color: rgba(60, 78, 113, .3) !important
}

.bg-gray-dark-transparent-4 {
    background: rgba(60, 78, 113, .4) !important
}

.text-gray-dark-transparent-4 {
    color: rgba(60, 78, 113, .4) !important
}

.bg-gray-dark-transparent-5 {
    background: rgba(60, 78, 113, .5) !important
}

.text-gray-dark-transparent-5 {
    color: rgba(60, 78, 113, .5) !important
}

.bg-gray-dark-transparent-6 {
    background: rgba(60, 78, 113, .6) !important
}

.text-gray-dark-transparent-6 {
    color: rgba(60, 78, 113, .6) !important
}

.bg-gray-dark-transparent-7 {
    background: rgba(60, 78, 113, .7) !important
}

.text-gray-dark-transparent-7 {
    color: rgba(60, 78, 113, .7) !important
}

.bg-gray-dark-transparent-8 {
    background: rgba(60, 78, 113, .8) !important
}

.text-gray-dark-transparent-8 {
    color: rgba(60, 78, 113, .8) !important
}

.bg-gray-dark-transparent-9 {
    background: rgba(60, 78, 113, .9) !important
}

.text-gray-dark-transparent-9 {
    color: rgba(60, 78, 113, .9) !important
}

.text-gray-dark-100 {
    color: #d8dce3 !important
}

.bg-gray-dark-100 {
    background-color: #d8dce3 !important
}

.text-gray-dark-600 {
    color: #303e5a !important
}

.bg-gray-dark-600 {
    background-color: #303e5a !important
}

.text-gray-dark-200 {
    color: #b1b8c6 !important
}

.bg-gray-dark-200 {
    background-color: #b1b8c6 !important
}

.text-gray-dark-700 {
    color: #242f44 !important
}

.bg-gray-dark-700 {
    background-color: #242f44 !important
}

.text-gray-dark-300 {
    color: #8a95aa !important
}

.bg-gray-dark-300 {
    background-color: #8a95aa !important
}

.text-gray-dark-800 {
    color: #181f2d !important
}

.bg-gray-dark-800 {
    background-color: #181f2d !important
}

.text-gray-dark-400 {
    color: #63718d !important
}

.bg-gray-dark-400 {
    background-color: #63718d !important
}

.text-gray-dark-900 {
    color: #0c1017 !important
}

.bg-gray-dark-900 {
    background-color: #0c1017 !important
}

.bg-none {
    background: 0 0 !important
}

.bg-gradient-orange-red {
    background: #fa3
}

.bg-gradient-orange-red {
    background: -moz-linear-gradient(top, #fa3, #f33c33) !important;
    background: -webkit-linear-gradient(top, #fa3, #f33c33) !important;
    background: linear-gradient(to top, #fa3, #f33c33) !important
}

.bg-gradient-red-pink {
    background: #f33c33
}

.bg-gradient-red-pink {
    background: -moz-linear-gradient(top, #f33c33, #df5d9e) !important;
    background: -webkit-linear-gradient(top, #f33c33, #df5d9e) !important;
    background: linear-gradient(to top, #f33c33, #df5d9e) !important
}

.bg-gradient-yellow-orange {
    background: #ffd633
}

.bg-gradient-yellow-orange {
    background: -moz-linear-gradient(top, #ffd633, #fa3) !important;
    background: -webkit-linear-gradient(top, #ffd633, #fa3) !important;
    background: linear-gradient(to top, #ffd633, #fa3) !important
}

.bg-gradient-yellow-red {
    background: #ffd633
}

.bg-gradient-yellow-red {
    background: -moz-linear-gradient(top, #ffd633, #f33c33) !important;
    background: -webkit-linear-gradient(top, #ffd633, #f33c33) !important;
    background: linear-gradient(to top, #ffd633, #f33c33) !important
}

.bg-gradient-yellow-green {
    background: #ffd633
}

.bg-gradient-yellow-green {
    background: -moz-linear-gradient(top, #ffd633, #03ba31) !important;
    background: -webkit-linear-gradient(top, #ffd633, #03ba31) !important;
    background: linear-gradient(to top, #ffd633, #03ba31) !important
}

.bg-gradient-cyan-blue {
    background: #30beff
}

.bg-gradient-cyan-blue {
    background: -moz-linear-gradient(top, #30beff, #1f6bff) !important;
    background: -webkit-linear-gradient(top, #30beff, #1f6bff) !important;
    background: linear-gradient(to top, #30beff, #1f6bff) !important
}

.bg-gradient-cyan-indigo {
    background: #30beff
}

.bg-gradient-cyan-indigo {
    background: -moz-linear-gradient(top, #30beff, #640df3) !important;
    background: -webkit-linear-gradient(top, #30beff, #640df3) !important;
    background: linear-gradient(to top, #30beff, #640df3) !important
}

.bg-gradient-custom-orange {
    background: #ee7b5e
}

.bg-gradient-custom-orange {
    background: -moz-linear-gradient(right, #ee7b5e, #f1957e) !important;
    background: -webkit-linear-gradient(right, #ee7b5e, #f1957e) !important;
    background: linear-gradient(to left, #ee7b5e, #f1957e) !important
}

.bg-gradient-custom-pink {
    background: #d3488e
}

.bg-gradient-custom-pink {
    background: -moz-linear-gradient(right, #d3488e, #da65a0) !important;
    background: -webkit-linear-gradient(right, #d3488e, #da65a0) !important;
    background: linear-gradient(to left, #d3488e, #da65a0) !important
}

.bg-gradient-custom-teal {
    background: #1aa57c
}

.bg-gradient-custom-teal {
    background: -moz-linear-gradient(right, #1aa57c, #20c997) !important;
    background: -webkit-linear-gradient(right, #1aa57c, #20c997) !important;
    background: linear-gradient(to left, #1aa57c, #20c997) !important
}

.bg-gradient-custom-indigo {
    background: #8845f5
}

.bg-gradient-custom-indigo {
    background: -moz-linear-gradient(right, #8845f5, #aa7af8) !important;
    background: -webkit-linear-gradient(right, #8845f5, #aa7af8) !important;
    background: linear-gradient(to left, #8845f5, #aa7af8) !important
}

.bg-gradient-custom-blue {
    background: #1f6bff
}

.bg-gradient-custom-blue {
    background: -moz-linear-gradient(right, #1f6bff, #528dff) !important;
    background: -webkit-linear-gradient(right, #1f6bff, #528dff) !important;
    background: linear-gradient(to left, #1f6bff, #528dff) !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-1 {
    opacity: .1 !important
}

.opacity-2 {
    opacity: .2 !important
}

.opacity-3 {
    opacity: .3 !important
}

.opacity-4 {
    opacity: .4 !important
}

.opacity-5 {
    opacity: .5 !important
}

.opacity-6 {
    opacity: .6 !important
}

.opacity-7 {
    opacity: .7 !important
}

.opacity-8 {
    opacity: .8 !important
}

.opacity-9 {
    opacity: .9 !important
}

.opacity-10 {
    opacity: 1 !important
}

@media (min-width:1200px) {
    .desktop-sticky-top {
        position: sticky;
        top: 80px
    }
}

@media (min-width:992px) {

    #app,
    body,
    html {
        height: 100%
    }
}

.app {
    padding-top: 3.75rem
}

@media (max-width:767.98px) {
    .app {
        padding-top: 3.75rem
    }
}

@media (min-width:992px) {
    .app.app-boxed-layout {
        background: #ebeef4;
        min-height: 100%
    }

    .app.app-boxed-layout,
    .app.app-boxed-layout .app-header {
        max-width: 80rem;
        margin-left: auto;
        margin-right: auto
    }

    .app.app-boxed-layout .app-sidebar {
        left: auto;
        background: #ebeef4
    }

    .app.app-boxed-layout .app-content {
        background: #ebeef4
    }
}

.app.app-full-height {
    height: 100%
}

.app.app-without-header {
    padding-top: 0
}

@media (min-width:992px) {
    .app-with-bg {
        background-image: url(images/bg.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat
    }
}

.app-sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 240px;
    border-right: 1px solid #dae0ec;
    z-index: 1020;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

@media (max-width:991.98px) {
    .app-sidebar {
        top: 0;
        left: -240px;
        border-right: none;
        z-index: 1130
    }
}

.app-sidebar .app-sidebar-content {
    padding: 10px 0;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

@media (max-width:991.98px) {
    .app-sidebar .app-sidebar-content {
        background: #fff;
        position: relative;
        z-index: 1010;
        width: 240px
    }
}

.app-sidebar .app-sidebar-mobile-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    margin: 0;
    outline: 0;
    display: none
}

.app-sidebar .menu {
    min-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.app-sidebar .menu .menu-item {
    padding: 0 10px
}

.app-sidebar .menu .menu-item .menu-icon {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1rem;
    color: #3c4e71;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.app-sidebar .menu .menu-item .menu-icon+.menu-text {
    margin-left: .625rem
}

.app-sidebar .menu .menu-item .menu-icon .menu-icon-label {
    position: absolute;
    right: -.3125rem;
    top: -.3125rem;
    font-size: .5625rem;
    font-weight: 600;
    height: auto;
    background: #ff9500;
    background: linear-gradient(to bottom, #ff9500, #d63384);
    color: #fff;
    padding: .0625rem 0 .125rem;
    line-height: 1;
    min-width: .875rem;
    min-height: .875rem;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.app-sidebar .menu .menu-item .menu-caret {
    opacity: .75
}

.app-sidebar .menu .menu-item .menu-text {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.app-sidebar .menu .menu-item .menu-link {
    padding: 6px 10px;
    line-height: 20px;
    text-decoration: none;
    color: #3c4e71;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-border-radius: 6px;
    border-radius: 6px
}

.app-sidebar .menu .menu-item .menu-link:hover {
    background: rgba(201, 210, 227, .5)
}

.app-sidebar .menu .menu-item .menu-submenu {
    display: none;
    padding-left: 20px;
    padding-bottom: 10px
}

.app-sidebar .menu .menu-item .menu-submenu .menu-item {
    padding-right: 0
}

.app-sidebar .menu .menu-item .menu-submenu .menu-item .menu-link {
    padding: 4px 10px
}

.app-sidebar .menu .menu-item.active:not(.has-sub)>.menu-link {
    color: #1f6bff;
    font-weight: 600;
    background: 0 0
}

.app-sidebar .menu .menu-item.active:not(.has-sub)>.menu-link .menu-icon {
    color: #1f6bff
}

.app-sidebar .menu .menu-item.active>.menu-submenu {
    display: block
}

.app-sidebar .menu .menu-divider {
    margin: 8px 0;
    height: 1px
}

.app-sidebar .menu .menu-header {
    margin: 8px 20px;
    font-size: .7875rem;
    font-weight: 600;
    color: #657eae
}

.app-full-width .app-sidebar {
    left: -240px;
    z-index: 1020;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease
}

@-webkit-keyframes appSidebarMobileSlideIn {
    from {
        left: -240px
    }

    to {
        left: 0
    }
}

@-moz-keyframes appSidebarMobileSlideIn {
    from {
        left: -240px
    }

    to {
        left: 0
    }
}

@-o-keyframes appSidebarMobileSlideIn {
    from {
        left: -240px
    }

    to {
        left: 0
    }
}

@keyframes appSidebarMobileSlideIn {
    from {
        left: -240px
    }

    to {
        left: 0
    }
}

@-webkit-keyframes appSidebarMobileSlideOut {
    from {
        left: 0
    }

    to {
        left: -240px
    }
}

@-moz-keyframes appSidebarMobileSlideOut {
    from {
        left: 0
    }

    to {
        left: -240px
    }
}

@-o-keyframes appSidebarMobileSlideOut {
    from {
        left: 0
    }

    to {
        left: -240px
    }
}

@keyframes appSidebarMobileSlideOut {
    from {
        left: 0
    }

    to {
        left: -240px
    }
}

@-webkit-keyframes appSidebarMobileBackdrop {
    from {
        background: 0 0
    }

    to {
        background: rgba(33, 40, 55, .5)
    }
}

@-moz-keyframes appSidebarMobileBackdrop {
    from {
        background: 0 0
    }

    to {
        background: rgba(33, 40, 55, .5)
    }
}

@-o-keyframes appSidebarMobileBackdrop {
    from {
        background: 0 0
    }

    to {
        background: rgba(33, 40, 55, .5)
    }
}

@keyframes appSidebarMobileBackdrop {
    from {
        background: 0 0
    }

    to {
        background: rgba(33, 40, 55, .5)
    }
}

@-webkit-keyframes appSidebarMobileRemoveBackdrop {
    from {
        background: rgba(33, 40, 55, .5)
    }

    to {
        background: 0 0
    }
}

@-moz-keyframes appSidebarMobileRemoveBackdrop {
    from {
        background: rgba(33, 40, 55, .5)
    }

    to {
        background: 0 0
    }
}

@-o-keyframes appSidebarMobileRemoveBackdrop {
    from {
        background: rgba(33, 40, 55, .5)
    }

    to {
        background: 0 0
    }
}

@keyframes appSidebarMobileRemoveBackdrop {
    from {
        background: rgba(33, 40, 55, .5)
    }

    to {
        background: 0 0
    }
}

.app-sidebar-mobile-toggled .app-sidebar {
    left: 0;
    -webkit-animation: appSidebarMobileSlideIn .1s ease;
    -moz-animation: appSidebarMobileSlideIn .1s ease;
    -ms-animation: appSidebarMobileSlideIn .1s ease;
    -o-animation: appSidebarMobileSlideIn .1s ease;
    animation: appSidebarMobileSlideIn .1s ease
}

.app-sidebar-mobile-toggled .app-sidebar-mobile-backdrop {
    display: block;
    background: rgba(33, 40, 55, .5);
    -webkit-animation: appSidebarMobileBackdrop .2s ease;
    -moz-animation: appSidebarMobileBackdrop .2s ease;
    -ms-animation: appSidebarMobileBackdrop .2s ease;
    -o-animation: appSidebarMobileBackdrop .2s ease;
    animation: appSidebarMobileBackdrop .2s ease
}

.app-sidebar-mobile-closed .app-sidebar {
    left: -240px;
    -webkit-animation: appSidebarMobileSlideOut .1s ease;
    -moz-animation: appSidebarMobileSlideOut .1s ease;
    -ms-animation: appSidebarMobileSlideOut .1s ease;
    -o-animation: appSidebarMobileSlideOut .1s ease;
    animation: appSidebarMobileSlideOut .1s ease
}

.app-sidebar-mobile-closed .app-sidebar-mobile-backdrop {
    display: block;
    background: 0 0;
    -webkit-animation: appSidebarMobileRemoveBackdrop .2s ease;
    -moz-animation: appSidebarMobileRemoveBackdrop .2s ease;
    -ms-animation: appSidebarMobileRemoveBackdrop .2s ease;
    -o-animation: appSidebarMobileRemoveBackdrop .2s ease;
    animation: appSidebarMobileRemoveBackdrop .2s ease
}

.app-sidebar-minified .app-sidebar {
    width: 65px
}

.app-sidebar-minified .app-sidebar .menu-item {
    position: relative;
    padding: 3px 12px
}

.app-sidebar-minified .app-sidebar .menu-item .menu-link {
    position: relative
}

.app-sidebar-minified .app-sidebar .menu-item .menu-link .menu-icon {
    margin-left: auto;
    margin-right: auto
}

.app-sidebar-minified .app-sidebar .menu-item .menu-link .menu-caret {
    color: #657eae
}

.app-sidebar-minified .app-sidebar .menu-submenu,
.app-sidebar-minified .app-sidebar .menu-text,
.app-sidebar-minified .app-sidebar .slimScrollBar {
    display: none !important
}

.app-sidebar-minified .app-sidebar .menu-header {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.app-sidebar-minified .app-sidebar .menu-caret {
    position: absolute;
    right: 3px;
    top: 50%;
    margin-top: -9px;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.app-sidebar-minified .app-sidebar .hide-on-minified {
    display: none
}

.app-sidebar-minified .app-content {
    margin-left: 65px
}

@media (max-width:991.98px) {
    .app-sidebar-minified .app-content {
        margin-left: 0
    }
}

.app-float-submenu {
    position: fixed;
    width: 240px;
    background: #fff;
    margin: 0;
    padding: 10px 0;
    list-style-type: none;
    z-index: 1130;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    box-shadow: 0 8px 16px rgba(134, 154, 192, .45)
}

@media (min-width:992px) {
    .app-float-submenu {
        z-index: 1030
    }
}

.app-float-submenu a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    color: #212837;
    padding: 5px 20px;
    line-height: 20px
}

.app-float-submenu .active>a,
.app-float-submenu a:active,
.app-float-submenu a:focus,
.app-float-submenu a:hover {
    color: #1f6bff;
    text-decoration: none
}

.app-float-submenu .active>a .menu-caret,
.app-float-submenu a:active .menu-caret,
.app-float-submenu a:focus .menu-caret,
.app-float-submenu a:hover .menu-caret {
    color: #1f6bff
}

.app-float-submenu .menu-submenu {
    display: none;
    padding-left: 15px
}

.app-float-submenu .menu-caret,
.app-float-submenu .menu-icon,
.app-float-submenu .menu-text {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.app-float-submenu .menu-caret {
    color: #869ac0
}

.app-float-submenu .menu-text {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.app-header {
    border: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 0;
    z-index: 1030;
    height: 60px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    -webkit-transition: box-shadow .2s linear;
    -moz-transition: box-shadow .2s linear;
    -ms-transition: box-shadow .2s linear;
    -o-transition: box-shadow .2s linear;
    transition: box-shadow .2s linear;
    -webkit-box-shadow: 0 6px 6px rgba(31, 107, 255, .1);
    box-shadow: 0 6px 6px rgba(31, 107, 255, .1)
}

@media (max-width:991.98px) {
    .app-header {
        padding: 0 10px
    }
}

.app-header .brand {
    width: 240px;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width:991.98px) {
    .app-header .brand {
        width: auto
    }
}

@media (max-width:767.98px) {
    .app-header .brand {
        display: none
    }
}

.app-header .brand img {
    max-height: 100%;
    max-width: 100%
}

.app-header .brand .brand-logo {
    font-weight: 700;
    color: #212837;
    font-size: 20px;
    text-decoration: none;
    height: 60px;
    width: 100%;
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.app-header .brand .brand-logo .logo {
    display: block;
    height: 26px;
    width: 26px;
    border: 2px solid #1f6bff;
    margin-right: 8px;
    position: relative;
    -webkit-border-radius: 16px;
    border-radius: 16px
}

.app-header .brand .brand-logo .logo:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    margin-top: -5px;
    margin-right: -5px;
    width: 10px;
    height: 10px;
    background: #1a5bd9;
    -webkit-border-radius: 6px;
    border-radius: 6px
}

.app-header .brand i {
    font-size: 26px;
    margin-right: 10px
}

.app-header .mobile-toggler {
    margin-right: auto;
    display: none
}

@media (max-width:991.98px) {
    .app-header .mobile-toggler {
        display: block;
        margin-right: 10px
    }
}

@media (max-width:767.98px) {
    .app-header .mobile-toggler {
        margin-right: 0
    }
}

.app-header .desktop-toggler {
    height: 60px;
    width: 4.0625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (max-width:991.98px) {
    .app-header .desktop-toggler {
        display: none
    }
}

.app-header .desktop-toggler .menu-toggler {
    width: 65px;
    height: 60px;
    padding: 0 20px
}

.app-header .desktop-toggler .menu-toggler:hover {
    opacity: .5
}

.app-header .menu-toggler {
    position: relative;
    border: none;
    background: 0 0;
    width: 45px;
    display: block;
    outline: 0;
    padding: 0 10px;
    margin: 0;
    height: 60px
}

.app-header .menu-toggler .bar {
    display: block;
    background: #212837;
    height: 2px;
    -webkit-border-radius: 3px;
    border-radius: 3px
}

.app-header .menu-toggler .bar+.bar {
    margin-top: 4px
}

.app-header .menu {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.app-header .menu .menu-search {
    margin: 0 auto 0 50px;
    position: relative;
    max-width: 760px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

@media (max-width:991.98px) {
    .app-header .menu .menu-search {
        margin-left: 30px;
        margin-right: 10px
    }
}

@media (max-width:767.98px) {
    .app-header .menu .menu-search {
        margin-left: 15px;
        margin-right: 10px
    }
}

.app-header .menu .menu-search .menu-search-icon {
    position: absolute;
    left: 0;
    width: 46px;
    top: 0;
    bottom: 0;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (max-width:767.98px) {
    .app-header .menu .menu-search .menu-search-icon {
        width: 40px
    }
}

.app-header .menu .menu-search .menu-search-input .form-control {
    background: #ebeef4;
    border-color: transparent;
    padding-left: 46px;
    height: 40px;
    font-size: 1rem
}

.app-header .menu .menu-search .menu-search-input .form-control::-webkit-input-placeholder {
    color: #a8b6d1
}

.app-header .menu .menu-search .menu-search-input .form-control::-moz-placeholder {
    color: #a8b6d1
}

.app-header .menu .menu-search .menu-search-input .form-control:-ms-input-placeholder {
    color: #a8b6d1
}

.app-header .menu .menu-search .menu-search-input .form-control:-moz-placeholder {
    color: #a8b6d1
}

@media (max-width:767.98px) {
    .app-header .menu .menu-search .menu-search-input .form-control {
        padding-left: 36px
    }
}

.app-header .menu .menu-search .menu-search-input .form-control:focus {
    -webkit-box-shadow: 0 0 0 2px #1f6bff;
    box-shadow: 0 0 0 2px #1f6bff
}

.app-header .menu .menu-item {
    position: relative
}

.app-header .menu .menu-item .menu-link {
    height: 60px;
    padding: 20px;
    text-decoration: none;
    color: #212837;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width:767.98px) {
    .app-header .menu .menu-item .menu-link {
        padding: 15px
    }
}

.app-header .menu .menu-item .menu-link:focus,
.app-header .menu .menu-item .menu-link:hover {
    color: #000
}

.app-header .menu .menu-item .menu-icon {
    font-size: 20px
}

.app-header .menu .menu-item .menu-label {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #1f6bff;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    padding: 0 6px;
    min-width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-border-radius: 30px;
    border-radius: 30px
}

@media (max-width:767.98px) {
    .app-header .menu .menu-item .menu-label {
        right: 5px
    }
}

.app-header .menu .menu-item .menu-text {
    font-weight: 600
}

@media (max-width:767.98px) {
    .app-header .menu .menu-item .menu-text {
        display: none
    }
}

.app-header .menu .menu-item .menu-img,
.app-header .menu .menu-item .menu-img-text {
    height: 36px;
    width: 36px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.app-header .menu .menu-item .menu-img-text.online:before,
.app-header .menu .menu-item .menu-img.online:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    right: 2px;
    bottom: 2px;
    background: #03ba31;
    -webkit-border-radius: 8px;
    border-radius: 8px
}

.app-header .menu .menu-item .menu-img+.menu-text,
.app-header .menu .menu-item .menu-img-text+.menu-text {
    margin-left: 8px
}

.app-header .menu .menu-item .menu-img-text {
    background: #ebeef4;
    color: #212837;
    font-size: 1.09375rem;
    font-weight: 700;
    -webkit-border-radius: 36px;
    border-radius: 36px
}

.app-content {
    margin-left: 240px;
    padding: 30px 50px;
    position: relative;
    min-height: 100%
}

@media (max-width:991.98px) {
    .app-content {
        margin-left: 0;
        padding: 20px 20px
    }
}

.app-content>.container,
.app-content>.container-fluid,
.app-content>.container-lg,
.app-content>.container-md,
.app-content>.container-sm,
.app-content>.container-xl,
.app-content>.container-xxl {
    padding-left: 0;
    padding-right: 0
}

.app-content .app-content-container {
    position: relative;
    z-index: 10
}

.app-content .app-content-cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.app-content-full-width .app-content {
    margin: 0
}

@media (min-width:992px) {
    .app-content-full-height {
        height: 100%
    }

    .app-content-full-height .app-content {
        height: 100%
    }
}

.app-footer {
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: 240px;
    line-height: 1.25rem;
    padding: 1.25rem 50px;
    border-top: 1px solid #dae0ec;
    background: #ebeef4;
    font-weight: 500
}

@media (max-width:767.98px) {
    .app-footer {
        margin-left: 0 !important;
        padding-left: 20px;
        padding-right: 20px
    }
}

.app-content-full-width .app-footer {
    margin-left: 0
}

.app-sidebar-minified .app-footer {
    margin-left: 65px
}

.app-footer-fixed .app-content {
    padding-bottom: 3.75rem
}

.app-footer-fixed .app-footer {
    position: fixed
}

.breadcrumb {
    font-weight: 700;
    font-size: 90%;
    padding: 0;
    background: 0 0;
    -webkit-border-radius: 0;
    border-radius: 0
}

.breadcrumb .breadcrumb-item a {
    text-decoration: none
}

.breadcrumb .breadcrumb-item a:hover {
    text-decoration: underline
}

.btn-scroll-top {
    position: fixed;
    bottom: 1.875rem;
    right: 1.875rem;
    width: 2.5rem;
    height: 2.5rem;
    background: rgba(60, 78, 113, .75);
    color: #fff;
    text-align: center;
    text-decoration: none;
    z-index: 1030;
    -webkit-border-radius: 36px;
    border-radius: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (max-width:767.98px) {
    .btn-scroll-top {
        bottom: 1.25rem;
        right: 1.25rem;
        z-index: 1030
    }
}

.btn-scroll-top:hover {
    background: #1f6bff;
    color: #fff;
    text-decoration: none
}

.btn-default {
    color: #3c4e71;
    background-color: #fff;
    border-color: #c9d2e3;
    -webkit-box-shadow: "";
    box-shadow: ""
}

.btn-default:hover {
    color: #3c4e71;
    background-color: #ebeef4;
    border-color: #c9d2e3
}

.btn-check:focus+.btn-default,
.btn-default:focus {
    color: #3c4e71;
    background-color: #ebeef4;
    border-color: #c9d2e3;
    box-shadow: 0 0 0 .25rem rgba(180, 190, 210, .5)
}

.btn-check:active+.btn-default,
.btn-check:checked+.btn-default,
.btn-default.active,
.btn-default:active,
.show>.btn-default.dropdown-toggle {
    color: #000;
    background-color: #fff;
    border-color: #ced7e6
}

.btn-check:active+.btn-default:focus,
.btn-check:checked+.btn-default:focus,
.btn-default.active:focus,
.btn-default:active:focus,
.show>.btn-default.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(180, 190, 210, .5)
}

.btn-default.disabled,
.btn-default:disabled {
    color: #000;
    background-color: #fff;
    border-color: #c9d2e3
}

.btn-outline-default {
    color: #c9d2e3;
    border-color: #c9d2e3;
    color: #212837
}

.btn-outline-default:hover {
    color: #212837;
    background-color: #c9d2e3;
    border-color: #c9d2e3
}

.btn-check:focus+.btn-outline-default,
.btn-outline-default:focus {
    box-shadow: 0 0 0 .25rem rgba(201, 210, 227, .5)
}

.btn-check:active+.btn-outline-default,
.btn-check:checked+.btn-outline-default,
.btn-outline-default.active,
.btn-outline-default.dropdown-toggle.show,
.btn-outline-default:active {
    color: #000;
    background-color: #c9d2e3;
    border-color: #c9d2e3
}

.btn-check:active+.btn-outline-default:focus,
.btn-check:checked+.btn-outline-default:focus,
.btn-outline-default.active:focus,
.btn-outline-default.dropdown-toggle.show:focus,
.btn-outline-default:active:focus {
    box-shadow: 0 0 0 .25rem rgba(201, 210, 227, .5)
}

.btn-outline-default.disabled,
.btn-outline-default:disabled {
    color: #c9d2e3;
    background-color: transparent
}

.btn.btn-rounded {
    -webkit-border-radius: 200px !important;
    border-radius: 200px !important
}

.card.card-expand {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1060;
    border-radius: 0;
    margin: 0 !important
}

.card.card-expand .card-body,
.card.card-expand .card-header {
    border-radius: 0
}

.card.card-expand .card-body {
    -webkit-box-flex: 1 0 0;
    -ms-flex: 1 0 0;
    flex: 1 0 0;
    overflow: scroll
}

.card .card-header .card-header-tabs .nav-item .nav-link.active {
    background: #fff;
    border-bottom-color: #fff
}

.card .card-body .nav-tabs .nav-item .nav-link.active {
    background: #fff;
    border-bottom-color: #fff
}

@media (max-width:991.98px) {
    .card-columns {
        column-count: 2
    }
}

@media (max-width:575.98px) {
    .card-columns {
        column-count: 1
    }
}

.caret {
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto
}

.caret:before {
    content: '\f0d7'
}

.dropup .caret,
.dropup.caret {
    content: '';
    border-top: 0;
    border-bottom: 4px dashed
}

.dropdown-menu {
    -webkit-box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    box-shadow: 0 8px 16px rgba(134, 154, 192, .45)
}

.dropdown-menu.dropdown-notification {
    min-width: 320px
}

.dropdown-menu.dropdown-notification .dropdown-notification-item {
    padding: 10px 20px;
    text-decoration: none;
    color: #212837;
    border-top: 1px solid #dae0ec;
    border-bottom: 1px solid #dae0ec;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.dropdown-menu.dropdown-notification .dropdown-notification-item:hover {
    background: #f0f2f6
}

.dropdown-menu.dropdown-notification .dropdown-notification-item .dropdown-notification-icon {
    width: 24px;
    font-size: 16px;
    text-align: center
}

.dropdown-menu.dropdown-notification .dropdown-notification-item .dropdown-notification-icon+.dropdown-notification-info {
    padding-left: 15px
}

.dropdown-menu.dropdown-notification .dropdown-notification-item .dropdown-notification-arrow {
    color: #c9d2e3
}

.dropdown-menu.dropdown-notification .dropdown-notification-item .dropdown-notification-info {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.dropdown-menu.dropdown-notification .dropdown-notification-item .dropdown-notification-info .title+.time {
    margin-top: 5px
}

.dropdown-menu.dropdown-notification .dropdown-notification-item .dropdown-notification-info .time {
    font-size: 12px;
    color: #869ac0
}

.dropdown-menu.dropdown-notification .dropdown-notification-item .dropdown-notification-info+.dropdown-notification-arrow {
    padding-left: 20px
}

.dropdown-menu.dropdown-notification .dropdown-notification-item+.dropdown-notification-item {
    margin-top: -1px
}

hr.hr-bg,
hr.hr-inverse,
hr.hr-inverse-transparent,
hr.hr-transparent {
    border: none;
    height: 1px
}

hr.hr-transparent {
    background-color: rgba(0, 0, 0, .3)
}

hr.hr-inverse {
    background-color: #1a1a1a
}

hr.hr-inverse-transparent {
    background-color: rgba(255, 255, 255, .3)
}

.modal .modal-dialog.modal-full {
    max-width: 100%;
    width: 100%
}

.modal .modal-dialog.modal-full .modal-content {
    border-radius: 0
}

.modal.modal-cover {
    background: #fff
}

.modal.modal-cover .modal-dialog {
    min-height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
    background: 0 0;
    border: none
}

.nav.nav-tabs.nav-tabs-v2>.nav-item>.nav-link {
    border: none;
    border-bottom: 3px solid transparent;
    color: #4d6593;
    padding: 1rem 0
}

.nav.nav-tabs.nav-tabs-v2>.nav-item>.nav-link:hover {
    border-bottom-color: #c9d2e3;
    color: #212837
}

.nav.nav-tabs.nav-tabs-v2>.nav-item>.nav-link.active {
    border-bottom-color: #1f6bff;
    color: #212837;
    background: 0 0
}

.nav.nav-tabs.nav-tabs-v2>.nav-item.show>.nav-link {
    background: 0 0;
    border-bottom-color: #c9d2e3;
    color: #212837
}

@media (max-width:767.98px) {
    .nav-wizards-container {
        overflow: scroll
    }

    .nav-wizards-container .nav.nav-wizards-1,
    .nav-wizards-container .nav.nav-wizards-2,
    .nav-wizards-container .nav.nav-wizards-3 {
        min-width: 37.5rem
    }
}

.nav.nav-wizards-1 .nav-item {
    padding: 0
}

.nav.nav-wizards-1 .nav-item .nav-link {
    position: relative;
    color: #4d6593
}

.nav.nav-wizards-1 .nav-item .nav-link .nav-no {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 auto;
    font-weight: 500;
    background: #fff;
    border: 2px solid #dae0ec;
    position: relative;
    z-index: 10;
    -webkit-border-radius: 40px;
    border-radius: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear
}

.nav.nav-wizards-1 .nav-item .nav-link .nav-text {
    text-align: center;
    margin-top: .3125rem;
    font-weight: 500;
    font-size: .8125rem
}

.nav.nav-wizards-1 .nav-item .nav-link.active,
.nav.nav-wizards-1 .nav-item .nav-link.completed {
    color: #1f6bff
}

.nav.nav-wizards-1 .nav-item .nav-link.active .nav-no,
.nav.nav-wizards-1 .nav-item .nav-link.completed .nav-no {
    border-color: #1f6bff;
    color: #1f6bff
}

.nav.nav-wizards-1 .nav-item .nav-link.active:after,
.nav.nav-wizards-1 .nav-item .nav-link.completed:after {
    width: 100%
}

.nav.nav-wizards-1 .nav-item .nav-link.active .nav-no {
    -webkit-box-shadow: 0 0 0 5px rgba(31, 107, 255, .2);
    box-shadow: 0 0 0 5px rgba(31, 107, 255, .2)
}

.nav.nav-wizards-1 .nav-item .nav-link.disabled {
    color: #c9d2e3
}

.nav.nav-wizards-1 .nav-item .nav-link:after,
.nav.nav-wizards-1 .nav-item .nav-link:before {
    content: '';
    position: absolute;
    left: 0;
    top: 1.625rem;
    right: 0;
    height: .125rem;
    background: #dae0ec
}

.nav.nav-wizards-1 .nav-item .nav-link:after {
    right: 0;
    width: 0%;
    background: #1f6bff;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear
}

.nav.nav-wizards-1 .nav-item:first-child .nav-link:after,
.nav.nav-wizards-1 .nav-item:first-child .nav-link:before {
    left: 50%
}

.nav.nav-wizards-1 .nav-item:last-child .nav-link:before {
    right: 50%
}

.nav.nav-wizards-1 .nav-item:last-child .nav-link.active:before,
.nav.nav-wizards-1 .nav-item:last-child .nav-link.completed:before {
    background: #1f6bff
}

.nav.nav-wizards-1 .nav-item:last-child .nav-link:after {
    display: none
}

.nav.nav-wizards-2 .nav-item {
    padding: 0 1rem
}

.nav.nav-wizards-2 .nav-item .nav-link {
    position: relative;
    color: #3c4e71;
    background: #dae0ec;
    -webkit-border-radius: 60px;
    border-radius: 60px
}

.nav.nav-wizards-2 .nav-item .nav-link .nav-text {
    text-align: center;
    font-weight: 500;
    font-size: .8125rem;
    position: relative;
    z-index: 10;
    background: inherit
}

.nav.nav-wizards-2 .nav-item .nav-link:after,
.nav.nav-wizards-2 .nav-item .nav-link:before {
    content: '';
    position: absolute;
    left: -1rem;
    right: -1rem;
    height: .125rem;
    top: 50%;
    margin-top: -.0625rem;
    background-color: #dae0ec;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear
}

.nav.nav-wizards-2 .nav-item .nav-link:after {
    width: 0%;
    background: #1f6bff
}

.nav.nav-wizards-2 .nav-item .nav-link.disabled {
    color: #a8b6d1
}

.nav.nav-wizards-2 .nav-item .nav-link.active,
.nav.nav-wizards-2 .nav-item .nav-link.completed {
    background: #1f6bff;
    color: #fff
}

.nav.nav-wizards-2 .nav-item .nav-link.active:after,
.nav.nav-wizards-2 .nav-item .nav-link.completed:after {
    width: calc(100% + 2rem)
}

.nav.nav-wizards-2 .nav-item .nav-link.active {
    -webkit-box-shadow: 0 0 0 4px rgba(31, 107, 255, .2);
    box-shadow: 0 0 0 4px rgba(31, 107, 255, .2)
}

.nav.nav-wizards-2 .nav-item:first-child .nav-link:after,
.nav.nav-wizards-2 .nav-item:first-child .nav-link:before {
    left: 50%
}

.nav.nav-wizards-2 .nav-item:last-child .nav-link:before {
    right: 50%
}

.nav.nav-wizards-2 .nav-item:last-child .nav-link.active:before,
.nav.nav-wizards-2 .nav-item:last-child .nav-link.completed:before {
    background: #1f6bff
}

.nav.nav-wizards-2 .nav-item:last-child .nav-link:after {
    display: none
}

.nav.nav-wizards-3 .nav-item {
    padding: 0
}

.nav.nav-wizards-3 .nav-item .nav-link {
    position: relative;
    color: #4d6593
}

.nav.nav-wizards-3 .nav-item .nav-link .nav-dot {
    width: 1rem;
    height: 1rem;
    margin: 0 auto;
    font-weight: 500;
    background: #fff;
    border: 3px solid #dae0ec;
    position: relative;
    z-index: 10;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear
}

.nav.nav-wizards-3 .nav-item .nav-link .nav-title {
    margin-top: .625rem;
    font-weight: 500;
    text-align: center
}

.nav.nav-wizards-3 .nav-item .nav-link .nav-text {
    text-align: center;
    font-size: .8125rem;
    color: #3c4e71
}

.nav.nav-wizards-3 .nav-item .nav-link.active,
.nav.nav-wizards-3 .nav-item .nav-link.completed {
    color: #212837
}

.nav.nav-wizards-3 .nav-item .nav-link.active .nav-dot,
.nav.nav-wizards-3 .nav-item .nav-link.completed .nav-dot {
    border-color: #1f6bff;
    color: #1f6bff
}

.nav.nav-wizards-3 .nav-item .nav-link.active:after,
.nav.nav-wizards-3 .nav-item .nav-link.completed:after {
    width: 100%
}

.nav.nav-wizards-3 .nav-item .nav-link.active .nav-dot {
    -webkit-box-shadow: 0 0 0 5px rgba(31, 107, 255, .2);
    box-shadow: 0 0 0 5px rgba(31, 107, 255, .2)
}

.nav.nav-wizards-3 .nav-item .nav-link.disabled {
    color: #c9d2e3
}

.nav.nav-wizards-3 .nav-item .nav-link.disabled .nav-text {
    color: inherit
}

.nav.nav-wizards-3 .nav-item .nav-link:after,
.nav.nav-wizards-3 .nav-item .nav-link:before {
    content: '';
    position: absolute;
    left: 0;
    top: .875rem;
    right: 0;
    height: .1875rem;
    background: #dae0ec
}

.nav.nav-wizards-3 .nav-item .nav-link:after {
    right: 0;
    width: 0%;
    background: #1f6bff;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear
}

.nav.nav-wizards-3 .nav-item:first-child .nav-link:after,
.nav.nav-wizards-3 .nav-item:first-child .nav-link:before {
    left: 50%
}

.nav.nav-wizards-3 .nav-item:last-child .nav-link:before {
    right: 50%
}

.nav.nav-wizards-3 .nav-item:last-child .nav-link.active:before,
.nav.nav-wizards-3 .nav-item:last-child .nav-link.completed:before {
    background: #1f6bff
}

.nav.nav-wizards-3 .nav-item:last-child .nav-link:after {
    display: none
}

.navbar.navbar-sticky {
    top: 90px;
    position: sticky;
    border-left: 1px solid #c9d2e3;
    padding: 0
}

.navbar.navbar-sticky .nav {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.navbar.navbar-sticky .nav .nav-link {
    color: #657eae;
    padding: 3px 24px
}

.navbar.navbar-sticky .nav .nav-link:hover {
    color: #3c4e71
}

.navbar.navbar-sticky .nav .nav-link.active {
    color: #212837;
    font-weight: 600
}

.page-header {
    color: #212837;
    padding: 0;
    border: none;
    margin: 0 0 1rem;
    font-size: 1.75rem;
    font-weight: 600
}

.page-header .small,
.page-header small {
    font-size: 1rem;
    color: #3c4e71;
    font-weight: 400
}

.pagination .page-item .page-link {
    border-radius: .375rem
}

.pagination .page-item+.page-item {
    margin-left: .3125rem
}

pre {
    display: block;
    padding: 1rem;
    font-size: .875rem;
    color: #4d6593;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #fff;
    border: 1px solid #dae0ec;
    -webkit-border-radius: 6px;
    border-radius: 6px
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.table tbody tr td.btn-col .btn {
    margin-top: -.1875rem;
    margin-bottom: -.0625rem
}

.table tbody tr td.checkbox-col .checkbox,
.table tbody tr td.checkbox-col .checkbox-inline {
    margin: 0
}

.table tbody tr td.radio-col .radio,
.table tbody tr td.radio-col .radio-inline {
    margin: 0
}

.table tbody tr td.switcher-col .switcher,
.table tbody tr td.switcher-col .switcher-inline {
    margin-top: -.25rem;
    margin-bottom: -.4375rem
}

.table tbody tr td.form-col .form-control {
    margin-top: -.5rem;
    margin-bottom: -.5rem
}

.table tbody tr td.img-col img {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
    display: block;
    border-radius: 6px;
    max-height: 3.75rem;
    max-width: 7.5rem
}

.table tbody tr td.img-col img.img-lg {
    max-height: 7.5rem;
    max-width: 15rem;
    border-radius: 9px
}

.table tbody tr td.img-col img.img-sm {
    max-height: 1.875rem;
    max-width: 3.75rem;
    border-radius: 3px
}

.table.table-card tbody>tr td:first-child,
.table.table-card tbody>tr th:first-child,
.table.table-card thead>tr td:first-child,
.table.table-card thead>tr th:first-child {
    padding-left: 1rem
}

.table.table-card tbody>tr td:last-child,
.table.table-card tbody>tr th:last-child,
.table.table-card thead>tr td:last-child,
.table.table-card thead>tr th:last-child {
    padding-right: 1rem
}

.toasts-container {
    position: fixed;
    top: 60px;
    right: 0;
    padding-right: 15px;
    padding-top: 15px;
    z-index: 1030;
    width: 350px
}

@media (max-width:767.98px) {
    .toasts-container {
        width: 300px
    }
}

.toasts-container .toast {
    width: 100%
}

.apexcharts-canvas .apexcharts-tooltip {
    font-size: .8125rem;
    -webkit-box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    box-shadow: 0 8px 16px rgba(134, 154, 192, .45)
}

.apexcharts-canvas .apexcharts-tooltip * {
    font-family: inherit !important
}

.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light {
    border: none
}

.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: #fff;
    border-color: #ebeef4;
    color: #212837;
    text-transform: uppercase
}

.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title {
    font-family: inherit !important;
    font-weight: 600;
    padding: .3125rem .625rem;
    margin: 0
}

.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-series-group {
    padding: .625rem
}

.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-series-group .apexcharts-tooltip-marker {
    width: .625rem;
    height: .625rem
}

.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-series-group .apexcharts-tooltip-text .apexcharts-tooltip-y-group {
    padding: 0
}

.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-series-group+.apexcharts-tooltip-series-group {
    padding-top: 0
}

.apexcharts-canvas .apexcharts-xaxistooltip * {
    font-family: inherit !important
}

.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light {
    color: #212837;
    background: #ebeef4;
    border-color: #c9d2e3
}

.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light.apexcharts-xaxistooltip-bottom:before {
    border-bottom-color: #c9d2e3
}

.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light.apexcharts-xaxistooltip-bottom:after {
    border-bottom-color: #ebeef4
}

.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light.apexcharts-xaxistooltip-top:before {
    border-top-color: #c9d2e3
}

.apexcharts-canvas .apexcharts-xaxistooltip.apexcharts-theme-light.apexcharts-xaxistooltip-top:after {
    border-top-color: #ebeef4
}

.apexcharts-canvas .apexcharts-yaxistooltip * {
    font-family: inherit !important
}

.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-light {
    color: #212837;
    background: #ebeef4;
    border-color: #c9d2e3
}

.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-light.apexcharts-yaxistooltip-right:before {
    border-right-color: #c9d2e3
}

.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-light.apexcharts-yaxistooltip-right:after {
    border-right-color: #ebeef4
}

.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-light.apexcharts-yaxistooltip-left:before {
    border-left-color: #c9d2e3
}

.apexcharts-canvas .apexcharts-yaxistooltip.apexcharts-theme-light.apexcharts-yaxistooltip-left:after {
    border-left-color: #ebeef4
}

.apexcharts-canvas .apexcharts-legend-marker {
    width: .625rem !important;
    height: .625rem !important;
    margin-right: .3125rem
}

.ui-menu.ui-autocomplete.search-bar-autocomplete {
    border: none;
    border-radius: 0;
    box-shadow: 0 .125rem .375rem rgba(0, 0, 0, .3);
    font-family: inherit;
    z-index: 1030;
    padding: .3125rem 0;
    border-radius: .375rem
}

.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-menu-item .ui-menu-item-wrapper,
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-menu-item a {
    padding: .5rem .9375rem;
    border-radius: 0;
    margin: 0;
    font-weight: 600
}

.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-state-active,
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-state-focus,
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-state-hover,
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-widget-content .ui-state-active,
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-widget-content .ui-state-focus,
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-widget-content .ui-state-hover,
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-widget-header .ui-state-active,
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-widget-header .ui-state-focus,
.ui-menu.ui-autocomplete.search-bar-autocomplete .ui-widget-header .ui-state-hover {
    border: none;
    background: #e6e6e6;
    color: #000
}

.datepicker.datepicker-dropdown {
    width: 20rem;
    padding: .625rem .9375rem .9375rem;
    z-index: 1000 !important
}

.datepicker.datepicker-dropdown:after,
.datepicker.datepicker-dropdown:before {
    display: none
}

.datepicker .datepicker-switch,
.datepicker .next,
.datepicker .prev {
    padding: .625rem;
    min-width: 2.5rem
}

.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover {
    background: 0 0 !important;
    color: #1f6bff
}

.datepicker .next,
.datepicker .prev {
    overflow: hidden;
    text-indent: -100px;
    max-width: 2.5rem
}

.datepicker .next:before,
.datepicker .prev:before {
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    position: absolute;
    width: 2.5rem;
    text-indent: 0;
    margin-top: .125rem
}

.datepicker .next:before {
    content: '\f061';
    right: 0
}

.datepicker .prev:before {
    content: '\f060';
    left: 0
}

.datepicker .datepicker-switch {
    font-weight: 500;
    font-size: 1rem;
    color: #212837;
    width: 100% !important
}

.datepicker .datepicker-switch,
.datepicker .next,
.datepicker .prev {
    padding: .3125rem
}

.datepicker .datepicker-days,
.datepicker .datepicker-months,
.datepicker .datepicker-years {
    position: relative
}

.datepicker .datepicker-days table {
    width: 100%
}

.datepicker .datepicker-days table thead tr th.dow {
    color: #212837;
    font-size: .75rem;
    font-weight: 600;
    border-radius: 0;
    padding: .625rem 0 .625rem 0
}

.datepicker .datepicker-days table tbody tr td {
    padding: .625rem 0;
    border-radius: 0;
    font-weight: 500;
    font-size: .8125rem;
    border: 1px solid #dae0ec;
    color: #212837;
    width: 14%
}

.datepicker .datepicker-days table tbody tr td.old {
    color: #c9d2e3
}

.datepicker .datepicker-days table tbody tr td.focused,
.datepicker .datepicker-days table tbody tr td.range,
.datepicker .datepicker-days table tbody tr td:hover {
    background: #f2f4f8 !important
}

.datepicker .datepicker-days table tbody tr td.active,
.datepicker .datepicker-days table tbody tr td.selected {
    background: #1f6bff !important;
    border-color: #1f6bff !important;
    color: #fff;
    text-shadow: none;
    position: relative
}

.datepicker .datepicker-days table tbody tr td.active:after,
.datepicker .datepicker-days table tbody tr td.active:before,
.datepicker .datepicker-days table tbody tr td.selected:after,
.datepicker .datepicker-days table tbody tr td.selected:before {
    content: '';
    position: absolute;
    left: -1px;
    top: 0;
    bottom: -1px;
    background: #1f6bff;
    width: 1px
}

.datepicker .datepicker-days table tbody tr td.active:after,
.datepicker .datepicker-days table tbody tr td.selected:after {
    bottom: auto;
    right: -1px;
    top: -1px;
    width: auto;
    height: 1px
}

.datepicker .datepicker-months table tbody tr td,
.datepicker .datepicker-years table tbody tr td {
    padding-top: .625rem
}

.datepicker .datepicker-months table tbody tr td span,
.datepicker .datepicker-years table tbody tr td span {
    width: 25%;
    margin: 0;
    border-radius: 0;
    border: 1px solid #dae0ec;
    font-weight: 600;
    font-size: .8125rem;
    margin: 0 -1px -1px 0;
    color: #212837
}

.datepicker .datepicker-months table tbody tr td span.focused,
.datepicker .datepicker-months table tbody tr td span:hover,
.datepicker .datepicker-years table tbody tr td span.focused,
.datepicker .datepicker-years table tbody tr td span:hover {
    background: #f2f4f8 !important
}

.datepicker .datepicker-months table tbody tr td span.focused,
.datepicker .datepicker-years table tbody tr td span.focused {
    color: #1f6bff
}

.datepicker .datepicker-months table tbody tr td span.active,
.datepicker .datepicker-years table tbody tr td span.active {
    border-color: #1f6bff;
    position: relative;
    background: #1f6bff !important;
    color: #fff !important;
    text-shadow: none
}

.input-daterange input.form-control:first-child {
    -webkit-border-radius: 6px 0 0 6px;
    border-radius: 6px 0 0 6px
}

.input-daterange input.form-control:last-child {
    -webkit-border-radius: 0 6px 6px 0;
    border-radius: 0 6px 6px 0
}

body .daterangepicker {
    z-index: 1020;
    border: none;
    font-family: inherit;
    font-size: inherit;
    -webkit-border-radius: 9px;
    border-radius: 9px;
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175)
}

body .daterangepicker:before {
    display: none
}

body .daterangepicker:after {
    border-width: .4375rem;
    top: -.4375rem;
    left: .625rem
}

body .daterangepicker select.monthselect,
body .daterangepicker select.yearselect {
    font-size: .8125rem
}

body .daterangepicker .drp-calendar {
    max-width: inherit;
    padding: .9375rem
}

body .daterangepicker .drp-calendar .calendar-table table tbody .week,
body .daterangepicker .drp-calendar .calendar-table table thead .week {
    font-size: 90%;
    color: #657eae;
    font-weight: 600
}

body .daterangepicker .drp-calendar .calendar-table table thead tr th {
    min-width: auto;
    width: auto;
    height: auto;
    line-height: 1;
    padding: .4375rem .4375rem;
    color: #212837;
    font-weight: 700;
    font-size: inherit;
    border: none
}

body .daterangepicker .drp-calendar .calendar-table table thead tr th.next span,
body .daterangepicker .drp-calendar .calendar-table table thead tr th.prev span {
    border-color: #212837
}

body .daterangepicker .drp-calendar .calendar-table table thead tr th.next:focus,
body .daterangepicker .drp-calendar .calendar-table table thead tr th.next:hover,
body .daterangepicker .drp-calendar .calendar-table table thead tr th.prev:focus,
body .daterangepicker .drp-calendar .calendar-table table thead tr th.prev:hover {
    background: #ebeef4;
    color: #212837
}

body .daterangepicker .drp-calendar .calendar-table table thead tr th.month .monthselect,
body .daterangepicker .drp-calendar .calendar-table table thead tr th.month .yearselect {
    border-color: #dae0ec;
    background: #fff;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear
}

body .daterangepicker .drp-calendar .calendar-table table thead tr th.month .monthselect:focus,
body .daterangepicker .drp-calendar .calendar-table table thead tr th.month .yearselect:focus {
    outline: 0;
    border-color: #528dff;
    -webkit-box-shadow: 0 0 0 .125rem rgba(82, 141, 255, .3);
    box-shadow: 0 0 0 .125rem rgba(82, 141, 255, .3)
}

body .daterangepicker .drp-calendar .calendar-table table thead tr:last-child th {
    padding-bottom: .875rem
}

body .daterangepicker .drp-calendar .calendar-table table tbody tr td {
    min-width: auto;
    width: auto;
    height: auto;
    line-height: 1;
    padding: .5625rem .5625rem;
    font-weight: 400;
    color: #212837;
    border: none;
    font-size: inherit
}

body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available {
    -webkit-border-radius: 6px;
    border-radius: 6px
}

body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available:focus,
body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available:hover {
    background: #ebeef4
}

body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available.in-range {
    background: #ebeef4;
    color: #869ac0;
    -webkit-border-radius: 0;
    border-radius: 0
}

body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available.active {
    background: #1f6bff;
    color: #fff
}

body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available.start-date {
    -webkit-border-radius: 6px 0 0 6px;
    border-radius: 6px 0 0 6px
}

body .daterangepicker .drp-calendar .calendar-table table tbody tr td.available.end-date {
    -webkit-border-radius: 0 6px 6px 0;
    border-radius: 0 6px 6px 0
}

body .daterangepicker .drp-calendar .calendar-table table tbody tr td.off {
    background: 0 0;
    color: #b7c0d4
}

body .daterangepicker .drp-calendar.left {
    padding: .625rem !important
}

body .daterangepicker .drp-calendar.left .calendar-table {
    padding: 0 !important
}

body .daterangepicker .drp-calendar.right {
    padding: .625rem !important;
    border-left: 1px solid #dae0ec;
    border-left: none;
    border-top: 1px solid #dae0ec
}

body .daterangepicker .drp-buttons {
    padding: .75rem;
    border-color: #dae0ec
}

body .daterangepicker .drp-buttons .btn {
    font-size: .8125rem;
    font-weight: 500
}

body .daterangepicker .drp-buttons .drp-selected {
    font-weight: 500;
    color: #5a6e97
}

body .daterangepicker .ranges {
    display: none
}

body .daterangepicker.show-ranges .ranges {
    position: relative;
    display: block
}

@media (max-width:767.98px) {
    body .daterangepicker.show-ranges .ranges {
        float: none;
        margin: 0;
        padding: .625rem;
        border-bottom: 1px solid #dae0ec;
        overflow: scroll;
        max-width: 35rem
    }
}

@media (max-width:767.98px) {
    body .daterangepicker.show-ranges .ranges ul {
        width: auto;
        white-space: nowrap;
        padding-right: .625rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}

body .daterangepicker.show-ranges .ranges ul li {
    color: #4d6593;
    font-size: .8125rem;
    font-weight: 400
}

@media (max-width:767.98px) {
    body .daterangepicker.show-ranges .ranges ul li {
        margin-right: .625rem !important;
        -webkit-border-radius: 6px;
        border-radius: 6px
    }
}

body .daterangepicker.show-ranges .ranges ul li:focus,
body .daterangepicker.show-ranges .ranges ul li:hover {
    background: #ebeef4;
    color: #212837
}

body .daterangepicker.show-ranges .ranges ul li.active {
    background: #1f6bff;
    color: #fff;
    margin-right: -.0625rem
}

body .daterangepicker.show-ranges .drp-calendar.left {
    border-left: 1px solid #dae0ec !important
}

@media (max-width:767.98px) {
    body .daterangepicker.show-ranges .drp-calendar.left {
        border-left: none !important
    }
}

.app .slider.slider-horizontal {
    height: 1.5rem;
    width: 100%
}

.app .slider.slider-horizontal .slider-track {
    height: .375rem;
    margin-top: -.1875rem
}

.app .slider.slider-vertical {
    margin: 0 auto;
    display: block
}

.app .slider.slider-vertical .slider-track {
    width: .375rem;
    left: 50%;
    margin-left: -.1875rem
}

.app .slider.slider-disabled .slider-track {
    opacity: .5
}

.app .slider.slider-disabled .slider-handle {
    background: #fff;
    cursor: not-allowed
}

.app .slider .slider-track {
    box-shadow: none;
    background: #dae0ec;
    -webkit-border-radius: 6px;
    border-radius: 6px
}

.app .slider .slider-track .slider-selection {
    background: #1f6bff;
    box-shadow: none
}

.app .slider .slider-handle {
    background: #fff;
    box-shadow: 0 .0625rem .375rem rgba(33, 40, 55, .25)
}

.app .slider .tooltip.in {
    opacity: 1
}

.app .slider .tooltip.top {
    margin-top: -1.875rem
}

.app .slider .tooltip.bottom {
    margin-top: 1.5625rem
}

.app .slider .tooltip.left {
    margin-left: -1.875rem
}

.app .slider .tooltip.right {
    margin-left: .3125rem
}

.bootstrap-timepicker-widget.dropdown-menu.open {
    width: 15rem;
    padding: .9375rem
}

.bootstrap-timepicker-widget.dropdown-menu.open:after,
.bootstrap-timepicker-widget.dropdown-menu.open:before {
    display: none
}

.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td {
    padding: 0;
    height: auto
}

.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td.separator {
    padding: 0 .3125rem
}

.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td:not(.separator) {
    min-width: 3.75rem
}

.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td input {
    border-radius: 6px;
    border: 1px solid #c9d2e3;
    height: calc(1.5em + .75rem + 2px);
    padding: .3125rem;
    line-height: 1.5;
    display: block;
    width: 100%;
    margin: .3125rem 0;
    -webkit-transition: all .15s ease-in-out;
    -moz-transition: all .15s ease-in-out;
    -ms-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out
}

.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td input:focus {
    border-color: #528dff;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(31, 107, 255, .25)
}

.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a {
    padding: 0;
    outline: 0;
    color: #869ac0;
    display: block;
    line-height: 2.25rem;
    height: 2.25rem;
    width: auto;
    margin: 0 auto;
    border: none;
    text-decoration: none;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-transition: all .15s linear;
    -moz-transition: all .15s linear;
    -ms-transition: all .15s linear;
    -o-transition: all .15s linear;
    transition: all .15s linear
}

.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a:hover {
    background: #ebeef4;
    color: #3c4e71
}

.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a .glyphicon {
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto
}

.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a .glyphicon.glyphicon-chevron-up:before {
    content: '\f077'
}

.bootstrap-timepicker-widget.dropdown-menu.open table tbody tr td a .glyphicon.glyphicon-chevron-down:before {
    content: '\f078'
}

.bootstrap-table .dropdown-toggle .caret {
    display: none
}

.bootstrap-table .fixed-table-container.fixed-height .fixed-table-header .table {
    border-bottom: none
}

.bootstrap-table .fixed-table-container.fixed-height .fixed-table-body .table thead tr th {
    border-top: none;
    border-bottom: none
}

.bootstrap-table .fixed-table-container .table thead tr {
    border-bottom-width: 0 !important
}

.bootstrap-table .fixed-table-container .table thead tr th {
    border-bottom-color: initial !important
}

.bootstrap-table .fixed-table-container .table thead tr th .sortable {
    position: relative;
    background-image: none !important
}

.bootstrap-table .fixed-table-container .table thead tr th .sortable:after {
    content: '';
    position: absolute;
    right: .625rem;
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto
}

.bootstrap-table .fixed-table-container .table thead tr th .sortable.both:after {
    content: '\f0dc';
    color: #c9d2e3
}

.bootstrap-table .fixed-table-container .table thead tr th .sortable.asc:after {
    content: '\f0de';
    color: #1f6bff
}

.bootstrap-table .fixed-table-container .table thead tr th .sortable.desc:after {
    content: '\f0dd';
    color: #1f6bff
}

.bootstrap-table .fixed-table-container .table tbody tr:first-child {
    border-top: none
}

.bootstrap-table .fixed-table-container .table tbody tr:first-child td {
    border-top: none
}

.dataTables_wrapper.dt-bootstrap4 .dataTables_filter label,
.dataTables_wrapper.dt-bootstrap4 .dataTables_length label,
.dataTables_wrapper.dt-bootstrap4 .dataTables_paginate label {
    margin: 0
}

.dataTables_wrapper.dt-bootstrap4 .dataTables_filter label .form-control,
.dataTables_wrapper.dt-bootstrap4 .dataTables_length label .form-control,
.dataTables_wrapper.dt-bootstrap4 .dataTables_paginate label .form-control {
    margin: 0 .3125rem
}

.dataTables_wrapper.dt-bootstrap4 .dataTables_paginate .pagination {
    margin-bottom: 0
}

.dataTables_wrapper.dt-bootstrap4 .dataTables_length .custom-select {
    background: #fff;
    width: auto;
    padding-top: 0;
    padding-bottom: 0
}

.dataTables_wrapper.dt-bootstrap4 .dataTables_filter .form-control {
    width: auto;
    display: inline-block
}

.dataTables_wrapper.dt-bootstrap4 .table thead tr th {
    position: relative;
    border-bottom-width: 1px
}

.dataTables_wrapper.dt-bootstrap4 .table thead tr th:focus {
    outline: 0
}

.dataTables_wrapper.dt-bootstrap4 .table thead tr th.sorting,
.dataTables_wrapper.dt-bootstrap4 .table thead tr th.sorting_asc,
.dataTables_wrapper.dt-bootstrap4 .table thead tr th.sorting_desc {
    padding-right: 1.875rem
}

.dataTables_wrapper.dt-bootstrap4 .table thead tr th.sorting:before,
.dataTables_wrapper.dt-bootstrap4 .table thead tr th.sorting_asc:before,
.dataTables_wrapper.dt-bootstrap4 .table thead tr th.sorting_desc:before {
    bottom: .65rem;
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto
}

.dataTables_wrapper.dt-bootstrap4 .table thead tr th.sorting:after,
.dataTables_wrapper.dt-bootstrap4 .table thead tr th.sorting_asc:after,
.dataTables_wrapper.dt-bootstrap4 .table thead tr th.sorting_desc:after {
    content: ''
}

.dataTables_wrapper.dt-bootstrap4 .table thead tr th.sorting:before {
    content: '\f0dc';
    color: #c9d2e3;
    opacity: 1
}

.dataTables_wrapper.dt-bootstrap4 .table thead tr th.sorting_asc:before {
    content: '\f0de';
    color: #1f6bff;
    opacity: 1
}

.dataTables_wrapper.dt-bootstrap4 .table thead tr th.sorting_desc:before {
    content: '\f0dd';
    color: #1f6bff;
    opacity: 1
}

.dataTables_wrapper.dt-bootstrap4 .table tbody tr td.focus,
.dataTables_wrapper.dt-bootstrap4 .table tbody tr th.focus,
.dataTables_wrapper.dt-bootstrap4 .table thead tr td.focus,
.dataTables_wrapper.dt-bootstrap4 .table thead tr th.focus {
    box-shadow: inset 0 0 0 2px #1f6bff
}

.dataTables_wrapper.dt-bootstrap4 .table.table-sm thead tr th.sorting:before,
.dataTables_wrapper.dt-bootstrap4 .table.table-sm thead tr th.sorting_asc:before,
.dataTables_wrapper.dt-bootstrap4 .table.table-sm thead tr th.sorting_desc:before {
    bottom: .3rem
}

.dataTables_wrapper.dt-bootstrap4 .table.dataTable>tbody>tr.child ul.dtr-details>li {
    border-color: #dae0ec
}

.dataTables_wrapper.dt-bootstrap4 .table.dataTable.dtr-inline.collapsed>tbody tr>td.dtr-control:before,
.dataTables_wrapper.dt-bootstrap4 .table.dataTable.dtr-inline.collapsed>tbody tr>th.dtr-control:before {
    content: '\f067';
    background: #dae0ec;
    color: #4d6593;
    border: none;
    left: .275rem;
    height: 1.375rem;
    line-height: 1.375rem;
    width: 1.375rem;
    font-size: .625rem;
    margin-top: -.65rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-border-radius: 6px;
    border-radius: 6px
}

.dataTables_wrapper.dt-bootstrap4 .table.dataTable.dtr-inline.collapsed>tbody tr.parent>td.dtr-control:before,
.dataTables_wrapper.dt-bootstrap4 .table.dataTable.dtr-inline.collapsed>tbody tr.parent>th.dtr-control:before {
    content: '\f068'
}

.dataTables_wrapper.dt-bootstrap4 .dataTables_scroll .dataTables_scrollHead .dataTables_scrollHeadInner .table {
    margin-bottom: -.0625rem
}

.dataTables_wrapper.dt-bootstrap4 .dataTables_scroll .dataTables_scrollBody .table thead tr th:after {
    display: none
}

.dataTables_wrapper.dt-bootstrap4 .form-control {
    font-size: .875rem;
    padding: .75rem .375rem;
    height: calc(1.5em + .75rem + 2px);
    -webkit-border-radius: 6px;
    border-radius: 6px
}

body div.dt-button-info {
    border: none;
    padding: 1rem 1rem;
    -webkit-border-radius: 9px;
    border-radius: 9px;
    -webkit-box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    box-shadow: 0 8px 16px rgba(134, 154, 192, .45)
}

body div.dt-button-info .h2,
body div.dt-button-info h2 {
    background: 0 0;
    border: none;
    color: #212837;
    font-weight: 600;
    padding-bottom: .625rem
}

body div.dt-button-info div {
    color: #3c4e71;
    padding-top: 0
}

.fixedHeader-floating {
    z-index: 1080
}

.fc-event-list .fc-event-item .fc-event-link {
    padding: .375rem .75rem;
    background: #fff;
    color: #4d6593;
    position: relative;
    border: 1px solid #dae0ec;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-border-radius: 6px;
    border-radius: 6px
}

.fc-event-list .fc-event-item .fc-event-link:hover {
    background: #ebeef4
}

.fc-event-list .fc-event-item .fc-event-link:after {
    content: '\f0b2';
    margin-left: auto;
    color: #c9d2e3;
    line-height: 1;
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto
}

.fc-event-list .fc-event-item+.fc-event-item {
    margin-top: .375rem
}

.fc-event-link.fc-dragging,
.fc-event-link.fc-event-dragging {
    padding: .375rem .75rem;
    background: #fff;
    color: #212837;
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    box-shadow: 0 8px 16px rgba(134, 154, 192, .45)
}

.fc .fc-header-toolbar,
.fc .fc-toolbar {
    margin-bottom: 0 !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center
}

.fc .fc-header-toolbar .fc-left,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1),
.fc .fc-toolbar .fc-left,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) {
    border-bottom: 1px solid #dae0ec;
    padding: .75rem 1rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.fc .fc-header-toolbar .fc-left .btn,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn,
.fc .fc-toolbar .fc-left .btn,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn {
    padding: .25rem .5rem;
    line-height: 1.25;
    font-size: .875rem;
    background: #fff;
    color: #657eae;
    border-color: #c9d2e3;
    min-width: 6.25rem
}

.fc .fc-header-toolbar .fc-left .btn:hover,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn:hover,
.fc .fc-toolbar .fc-left .btn:hover,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:hover {
    background: #ebeef4
}

.fc .fc-header-toolbar .fc-left .btn:focus,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn:focus,
.fc .fc-toolbar .fc-left .btn:focus,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(201, 210, 227, .5);
    box-shadow: 0 0 0 .2rem rgba(201, 210, 227, .5)
}

.fc .fc-header-toolbar .fc-left .btn.active,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .btn.active,
.fc .fc-toolbar .fc-left .btn.active,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .btn.active {
    background: #ebeef4;
    color: #4d6593;
    font-weight: 500
}

.fc .fc-header-toolbar .fc-center,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(2),
.fc .fc-toolbar .fc-center,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(2) {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 1rem 1.25rem
}

.fc .fc-header-toolbar .fc-right,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3),
.fc .fc-toolbar .fc-right,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) {
    padding: 1rem 1.25rem
}

.fc .fc-header-toolbar .fc-right .btn,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn,
.fc .fc-toolbar .fc-right .btn,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn {
    padding: .125rem .75rem;
    line-height: 1.25;
    font-size: .875rem;
    background: #fff;
    color: #4d6593;
    border-color: #a8b6d1
}

.fc .fc-header-toolbar .fc-right .btn:hover,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn:hover,
.fc .fc-toolbar .fc-right .btn:hover,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:hover {
    background: #ebeef4
}

.fc .fc-header-toolbar .fc-right .btn:focus,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn:focus,
.fc .fc-toolbar .fc-right .btn:focus,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn:focus {
    -webkit-box-shadow: 0 0 0 .2rem rgba(168, 182, 209, .5);
    box-shadow: 0 0 0 .2rem rgba(168, 182, 209, .5)
}

.fc .fc-header-toolbar .fc-right .btn.active,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn.active,
.fc .fc-toolbar .fc-right .btn.active,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn.active {
    background: #ebeef4;
    color: #4d6593;
    font-weight: 500
}

.fc .fc-header-toolbar .fc-right .btn.disabled,
.fc .fc-header-toolbar .fc-right .btn[disabled],
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn.disabled,
.fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .btn[disabled],
.fc .fc-toolbar .fc-right .btn.disabled,
.fc .fc-toolbar .fc-right .btn[disabled],
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn.disabled,
.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .btn[disabled] {
    color: #4d6593;
    border-color: #a8b6d1;
    background: #fff
}

.fc .fc-header-toolbar .h2,
.fc .fc-header-toolbar h2,
.fc .fc-toolbar .h2,
.fc .fc-toolbar h2 {
    font-size: 1.75rem
}

.fc .fc-view .fc-head .fc-head-container .fc-axis,
.fc .fc-view .fc-head .fc-head-container .fc-day-header {
    font-weight: 400;
    padding-bottom: .3125rem;
    font-size: 1rem;
    border-top: none;
    border-left: none;
    border-right: none
}

.fc .fc-view .fc-event,
.fc .fc-view .fc-event-dot {
    background-color: #869ac0;
    border-color: #869ac0
}

.fc .fc-view .fc-event-dot.fc-daygrid-dot-event,
.fc .fc-view .fc-event.fc-daygrid-dot-event {
    background-color: #ebeef4;
    border-color: #ebeef4
}

.fc .fc-view .fc-popover.fc-more-popover {
    border: none;
    -webkit-box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    box-shadow: 0 8px 16px rgba(134, 154, 192, .45)
}

.fc .fc-view .fc-popover.fc-more-popover .fc-header {
    background: 0 0;
    padding: .5rem .5rem .375rem;
    font-weight: 600;
    font-size: .8125rem
}

.fc .fc-view .alert.alert-info {
    background: #ebeef4
}

.fc .fc-view .alert.alert-info .fc-day-number {
    color: #212837;
    font-weight: 600
}

.fc .fc-view.fc-dayGrid-view .fc-day-number,
.fc .fc-view.fc-dayGrid-view .fc-daygrid-day-number,
.fc .fc-view.fc-dayGrid-view .fc-week-number,
.fc .fc-view.fc-dayGridMonth-view .fc-day-number,
.fc .fc-view.fc-dayGridMonth-view .fc-daygrid-day-number,
.fc .fc-view.fc-dayGridMonth-view .fc-week-number {
    padding: .125rem .25rem
}

.fc .fc-view.fc-dayGrid-view .fc-day-grid-event,
.fc .fc-view.fc-dayGrid-view .fc-daygrid-day-events,
.fc .fc-view.fc-dayGridMonth-view .fc-day-grid-event,
.fc .fc-view.fc-dayGridMonth-view .fc-daygrid-day-events {
    margin-top: .125rem
}

.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-bg table,
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-slats table {
    border: none
}

.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-bg table tbody tr:first-child td,
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-bg table thead tr:first-child td,
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-slats table tbody tr:first-child td,
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-slats table thead tr:first-child td {
    border-top: none
}

.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-content-skeleton table {
    border: none !important
}

.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-content-skeleton table td,
.fc .fc-view.fc-timeGrid-view .fc-time-grid .fc-content-skeleton table th {
    border: none !important
}

.fc .fc-view a {
    text-decoration: none
}

.fc td,
.fc th {
    border-width: 1px;
    border-color: #dae0ec
}

.fc a:not([href]) {
    color: inherit
}

.hljs {
    display: block;
    overflow-x: auto;
    color: #c9d2e3
}

.hljs-code,
.hljs-emphasis {
    font-style: italic
}

.hljs-tag {
    color: #4d6593
}

.hljs-name {
    color: #657eae
}

.hljs-selector-class,
.hljs-selector-id,
.hljs-template-variable,
.hljs-variable {
    color: #ade5fc
}

.hljs-bullet,
.hljs-string {
    color: #f4c403
}

.hljs-attr {
    color: #61c6f5
}

.hljs-attribute,
.hljs-built_in,
.hljs-builtin-name,
.hljs-quote,
.hljs-section,
.hljs-title,
.hljs-type {
    color: #ffa
}

.hljs-bullet,
.hljs-number,
.hljs-symbol {
    color: #d36363
}

.hljs-keyword,
.hljs-literal,
.hljs-selector-tag {
    color: #fcc28c
}

.hljs-code,
.hljs-comment,
.hljs-deletion {
    color: #888
}

.hljs-link,
.hljs-regexp {
    color: #c6b4f0
}

.hljs-meta {
    color: #fc9b9b
}

.hljs-deletion {
    background-color: #fc9b9b;
    color: #333
}

.hljs-addition {
    background-color: #a2fca2;
    color: #333
}

.hljs a {
    color: inherit
}

.hljs a:focus,
.hljs a:hover {
    color: inherit;
    text-decoration: underline
}

.hljs-container {
    background: #212837;
    position: relative;
    padding: 2rem 1rem
}

.hljs-container pre {
    border: none;
    padding: 0;
    margin: 0;
    background: 0 0;
    -webkit-border-radius: 0;
    border-radius: 0
}

.jvectormap-container {
    position: relative
}

.jvectormap-container .jvectormap-goback,
.jvectormap-container .jvectormap-zoomin,
.jvectormap-container .jvectormap-zoomout {
    left: 1rem;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0 0 .125rem;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 1rem;
    background: #fff;
    color: #212837;
    font-weight: 500;
    -webkit-box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.jvectormap-container .jvectormap-goback:hover,
.jvectormap-container .jvectormap-zoomin:hover,
.jvectormap-container .jvectormap-zoomout:hover {
    color: #1f6bff
}

.jvectormap-container .jvectormap-zoomin {
    top: 1rem
}

.jvectormap-container .jvectormap-zoomout {
    top: 2.5625rem
}

.jvectormap-container .jvectormap-legend {
    background: #fff;
    color: #212837;
    -webkit-box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    -webkit-border-radius: 6px;
    border-radius: 6px
}

.jvectormap-container .jvectormap-legend-title {
    font-size: .75rem;
    margin-bottom: .1875rem
}

.jvectormap-container .jvectormap-legend-cnt-v .jvectormap-legend {
    padding: .625rem;
    margin: 1rem 1rem 0 0
}

.jvectormap-container .jvectormap-legend-cnt-v .jvectormap-legend .jvectormap-legend-tick {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.jvectormap-container .jvectormap-legend-cnt-v .jvectormap-legend .jvectormap-legend-tick+.jvectormap-legend-tick {
    margin-top: 2px
}

.jvectormap-container .jvectormap-legend-cnt-v .jvectormap-legend .jvectormap-legend-tick-sample {
    width: 1.125rem;
    height: 1.125rem;
    -webkit-border-radius: 3px;
    border-radius: 3px
}

.jvectormap-container .jvectormap-legend-cnt-v .jvectormap-legend .jvectormap-legend-tick-text {
    padding-left: .3125rem
}

.jvectormap-tip {
    background: #212837 !important;
    padding: .25rem .5rem !important;
    border: none !important;
    font-family: inherit !important;
    font-size: .75rem !important;
    -webkit-border-radius: 6px !important;
    border-radius: 6px !important
}

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.pace .pace-progress {
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: .125rem;
    background: #3c4e71
}

.pace .pace-progress-inner {
    display: block;
    position: absolute;
    right: 0;
    width: 6.25rem;
    height: 100%;
    opacity: 1;
    -webkit-box-shadow: 0 0 .625rem #3c4e71;
    box-shadow: 0 0 .625rem #3c4e71
}

.pace .pace-activity {
    display: block;
    position: fixed;
    z-index: 2000;
    top: 75px;
    right: .9375rem;
    width: 1.125rem;
    height: 1.125rem;
    border: solid .125rem transparent;
    border-top-color: #3c4e71;
    border-left-color: #3c4e71
}

.pace.pace-inactive {
    display: none
}

@-webkit-keyframes pace-spinner {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-moz-keyframes pace-spinner {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-o-keyframes pace-spinner {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes pace-spinner {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

body .picker {
    display: block;
    border: 1px solid #c9d2e3;
    background-color: #fff;
    -webkit-border-radius: 6px;
    border-radius: 6px
}

body .picker>.pc-element {
    margin: .375rem 0 0 .75rem;
    padding: .125rem .625rem !important;
    border: none;
    background: #ebeef4;
    color: #3c4e71;
    -webkit-border-radius: 30px;
    border-radius: 30px
}

body .picker>.pc-element:hover {
    background: #ebeef4;
    color: #3c4e71
}

body .picker>.pc-element .pc-close {
    position: relative;
    margin: 0;
    right: 0;
    top: 0;
    margin-left: .5rem;
    color: #657eae;
    opacity: .5;
    font-size: .75rem;
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto
}

body .picker>.pc-element .pc-close:after {
    content: '\f00d' !important
}

body .picker>.pc-element .pc-close:hover {
    opacity: 1
}

body .picker>.pc-element+.pc-select {
    padding-top: .375rem
}

body .picker>.pc-element+.pc-select .pc-element {
    margin-top: -.375rem
}

body .picker .pc-select {
    display: block;
    width: 100%;
    max-width: 100%;
    min-width: initial
}

body .picker .pc-select .pc-element {
    display: block;
    margin: 0;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3c4e71;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%233c4e71' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    appearance: none;
    border: none;
    padding: .375rem 2.25rem .375rem .75rem
}

body .picker .pc-select .pc-list {
    border: none;
    padding: .5rem 0;
    top: 100%;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    box-shadow: 0 8px 16px rgba(134, 154, 192, .45)
}

body .picker .pc-select .pc-list input[type=search] {
    display: block;
    width: calc(100% - 20px);
    margin: 0 .625rem .3125rem;
    padding: .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3c4e71;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c9d2e3;
    appearance: none;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-border-radius: 6px;
    border-radius: 6px
}

body .picker .pc-select .pc-list input[type=search]:focus {
    color: #3c4e71;
    background-color: #fff;
    border-color: #528dff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .25rem rgba(31, 107, 255, .25);
    box-shadow: 0 0 0 .25rem rgba(31, 107, 255, .25)
}

body .picker .pc-select .pc-list ul>li {
    padding: 6px 20px;
    background: 0 0
}

body .picker .pc-select .pc-list ul>li:nth-child(even) {
    background: 0 0
}

body .picker .pc-select .pc-list ul>li:hover {
    background: #f0f2f6;
    color: #1e2432
}

.slimScrollBar {
    background: 0 0 !important;
    width: .5rem !important;
    right: 0 !important
}

.slimScrollBar:before {
    content: '';
    position: absolute;
    top: .125rem;
    left: 0;
    right: .125rem;
    bottom: .125rem;
    background: #3c4e71;
    -webkit-border-radius: 10px;
    border-radius: 10px
}

.app-sidebar .slimScrollBar:before {
    display: none
}

.sp-original-input-container .sp-add-on {
    min-width: 2.1875rem
}

.sp-original-input-container .sp-add-on .sp-colorize {
    position: relative;
    overflow: hidden
}

.sp-original-input-container .sp-add-on .sp-colorize:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 5px solid #dae0ec
}

.note-editor {
    background: #fff;
    font-family: var(--bs-font-sans-serif) !important
}

.note-editor.note-frame .note-toolbar {
    padding: .6875rem 1rem 1rem;
    background: 0 0;
    -webkit-border-radius: 0;
    border-radius: 0
}

.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn {
    line-height: 1.5;
    min-height: 2.1875rem;
    color: #3c4e71;
    background-color: #fff;
    border-color: #c9d2e3;
    -webkit-box-shadow: "";
    box-shadow: ""
}

.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn:hover {
    color: #3c4e71;
    background-color: #ebeef4;
    border-color: #c9d2e3
}

.btn-check:focus+.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn,
.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn:focus {
    color: #3c4e71;
    background-color: #ebeef4;
    border-color: #c9d2e3;
    box-shadow: 0 0 0 .25rem rgba(180, 190, 210, .5)
}

.btn-check:active+.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn,
.btn-check:checked+.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn,
.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn.active,
.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn:active,
.show>.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn.dropdown-toggle {
    color: #000;
    background-color: #fff;
    border-color: #ced7e6
}

.btn-check:active+.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn:focus,
.btn-check:checked+.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn:focus,
.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn.active:focus,
.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn:active:focus,
.show>.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(180, 190, 210, .5)
}

.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn.disabled,
.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn:disabled {
    color: #000;
    background-color: #fff;
    border-color: #c9d2e3
}

.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn.active {
    background: #dae0ec;
    -webkit-box-shadow: none;
    box-shadow: none
}

.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn .note-icon-caret:before {
    content: ''
}

.note-editor.note-frame .note-toolbar .note-btn-group>.note-btn>.note-icon-caret:first-child {
    margin-left: -.25rem
}

.note-editor.note-frame .note-toolbar .note-dropdown-menu {
    border: 0;
    padding: .5rem 0;
    -webkit-box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    box-shadow: 0 8px 16px rgba(134, 154, 192, .45);
    -webkit-border-radius: 6px;
    border-radius: 6px
}

.note-editor.note-frame .note-toolbar .note-dropdown-menu .note-dropdown-item {
    padding: 6px 20px
}

.note-editor.note-frame .note-toolbar .note-dropdown-menu .note-dropdown-item:hover {
    background: #f0f2f6;
    color: #1e2432
}

.note-editor.note-frame .note-toolbar .note-palette .note-color-palette .note-color-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.note-editor.note-frame .note-toolbar .note-palette .note-color-palette .note-color-row .note-color-btn {
    display: block;
    min-height: initial
}

.note-editor.note-frame .note-toolbar .note-para .note-dropdown-menu {
    min-width: 14.6875rem
}

.note-editor.note-frame .note-editing-area .note-editable {
    padding: 1rem 1rem !important
}

.note-editor .note-tooltip .note-tooltip-content {
    -webkit-border-radius: 3px;
    border-radius: 3px
}

.note-editor .note-modal .note-modal-content {
    border: 0;
    -webkit-border-radius: 9px;
    border-radius: 9px;
    -webkit-box-shadow: none;
    box-shadow: none
}

.note-editor .note-modal .note-modal-content .note-modal-header {
    border: 0;
    padding: 1rem;
    border-bottom: 1px solid #dae0ec
}

.note-editor .note-modal .note-modal-content .note-modal-header .note-modal-title {
    color: #212837;
    font-weight: 600;
    font-size: 1.09375rem
}

.note-editor .note-modal .note-modal-content .note-modal-header .close {
    color: #3c4e71
}

.note-editor .note-modal .note-modal-content .note-modal-body {
    padding: 1rem
}

.note-editor .note-modal .note-modal-content .note-modal-body .form-group {
    padding-bottom: 1rem
}

.note-editor .note-modal .note-modal-content .note-modal-body .form-group .note-form-label {
    margin-bottom: .5rem;
    font-size: .875rem;
    font-weight: 400;
    color: #212837;
    display: block;
    padding: 0
}

.note-editor .note-modal .note-modal-content .note-modal-body .form-group .note-form-control {
    border: 1px solid #c9d2e3;
    padding: .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5
}

.note-editor .note-modal .note-modal-content .note-modal-body .form-group .note-form-control:focus {
    border-color: #528dff
}

.note-editor .note-modal .note-modal-content .note-modal-body .checkbox label {
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.note-editor .note-modal .note-modal-content .note-modal-body .checkbox label input {
    margin-right: .5rem
}

.note-editor .note-modal .note-modal-content .note-modal-footer {
    border: 0;
    height: auto;
    padding: 1rem;
    border-top: 1px solid #dae0ec;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end
}

.note-editor .note-modal .note-modal-content .note-modal-footer .note-btn {
    border: none;
    -webkit-border-radius: 6px;
    border-radius: 6px
}

.note-editor .note-modal .note-modal-content .note-modal-footer .note-btn.btn.btn-primary {
    background: #1f6bff;
    display: block;
    line-height: 1.5;
    padding: .375rem .75rem
}

.note-editor .note-modal .note-modal-content .note-modal-footer .note-btn.btn.btn-primary:hover {
    background: #0047d2
}

body .note-modal-backdrop {
    background: #212837;
    opacity: .5
}

.card>.note-editor.note-frame {
    border: none
}

.card>form>.note-editor.note-frame {
    border: none
}

.tagit {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.tagit.form-control {
    height: auto;
    min-height: calc(1.5em + .75rem + 2px)
}

.tagit.ui-widget {
    margin-bottom: 0;
    font-family: inherit;
    box-shadow: none !important;
    border-color: #c9d2e3 !important;
    color: #212837 !important;
    position: relative !important;
    padding: .1875rem .75rem !important;
    line-height: 1.3125rem !important;
    -webkit-border-radius: 6px !important;
    border-radius: 6px !important;
    -webkit-transition: all .15s ease-in-out;
    -moz-transition: all .15s ease-in-out;
    -ms-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.tagit.ui-widget li {
    float: none
}

.tagit.ui-widget li.tagit-choice {
    font-size: .8125rem !important;
    font-weight: 500;
    padding: .125rem .625rem !important;
    margin-right: 4px !important;
    margin-top: .0625rem !important;
    margin-bottom: .0625rem !important;
    border: none;
    background: #dae0ec !important;
    color: #3c4e71;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-border-radius: 20px;
    border-radius: 20px
}

.tagit.ui-widget li.tagit-choice .tagit-close {
    position: relative;
    line-height: 1.3125rem;
    text-align: center;
    top: 0;
    margin: 0 -.3125rem -.1875rem .3125rem;
    height: 1.3125rem;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.tagit.ui-widget li.tagit-choice .tagit-close .ui-icon-close {
    font-size: .625rem;
    color: #869ac0;
    text-indent: 0;
    height: 100%;
    padding-top: .125rem;
    background-image: none;
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.tagit.ui-widget li.tagit-choice .tagit-close .ui-icon-close:after {
    content: '\f00d' !important
}

.tagit.ui-widget li.tagit-choice .tagit-close .ui-icon-close:hover {
    box-shadow: none !important;
    color: rgba(33, 40, 55, .5)
}

.tagit.ui-widget li.tagit-choice .tagit-close:focus .ui-icon-close:before,
.tagit.ui-widget li.tagit-choice .tagit-close:hover .ui-icon-close:before {
    color: rgba(0, 0, 0, .4)
}

.tagit.ui-widget li.tagit-new {
    margin: .125rem 0;
    height: 1.4375rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.tagit.ui-widget.inverse li.tagit-choice {
    background: #212837;
    color: #fff
}

.tagit.ui-widget.inverse li.tagit-choice .tagit-close:focus .ui-icon-close:before,
.tagit.ui-widget.inverse li.tagit-choice .tagit-close:hover .ui-icon-close:before {
    color: rgba(255, 255, 255, .4)
}

.tagit.ui-widget.primary li.tagit-choice {
    background: #1f6bff;
    color: #fff
}

.tagit.ui-widget.info li.tagit-choice {
    background: #30beff;
    color: #fff
}

.tagit.ui-widget.success li.tagit-choice {
    background: #20c997;
    color: #fff
}

.tagit.ui-widget.warning li.tagit-choice {
    background: #ff9500;
    color: #fff
}

.tagit.ui-widget.danger li.tagit-choice {
    background: #e6180d;
    color: #fff
}

.tagit-autocomplete {
    border: none !important;
    padding: .5rem 0;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)
}

.tagit-autocomplete .ui-menu-item .ui-menu-item-wrapper {
    padding: 6px 20px;
    border: none;
    color: inherit
}

.tagit-autocomplete .ui-menu-item .ui-menu-item-wrapper.ui-state-active {
    background: #f0f2f6;
    margin: 0
}

.calendar {
    min-height: 100%;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.calendar .calendar-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.calendar .calendar-body+.calendar-sidebar {
    border-left: 1px solid #dae0ec
}

.calendar .calendar-body .fc .fc-head-container th {
    border-bottom-width: 0
}

.calendar .calendar-body .fc table {
    border: none !important
}

.calendar .calendar-body .fc table tbody tr,
.calendar .calendar-body .fc table thead tr {
    border-width: 0
}

.calendar .calendar-body .fc table tbody tr td:first-child,
.calendar .calendar-body .fc table tbody tr th:first-child,
.calendar .calendar-body .fc table thead tr td:first-child,
.calendar .calendar-body .fc table thead tr th:first-child {
    border-left-width: 0 !important
}

.calendar .calendar-body .fc table tbody tr td:last-child,
.calendar .calendar-body .fc table tbody tr th:last-child,
.calendar .calendar-body .fc table thead tr td:last-child,
.calendar .calendar-body .fc table thead tr th:last-child {
    border-right-width: 0 !important
}

.calendar .calendar-body .fc table .fc-head-container {
    border: none
}

.calendar .calendar-sidebar {
    width: 13.75rem;
    padding: 1rem
}

@media (max-width:767.98px) {
    .calendar .calendar-sidebar {
        display: none
    }
}

.calendar .calendar-sidebar+.calendar-body {
    border-left: 1px solid #dae0ec
}

.calendar .calendar-sidebar .calendar-sidebar-title {
    font-weight: 600;
    padding: 0 0 .625rem 0;
    min-height: 3.4375rem;
    font-size: .8125rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: flex-end;
    align-items: flex-end
}

.coming-soon {
    min-height: 100%;
    padding: 1.875rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width:575.98px) {
    .coming-soon {
        padding: 1.25rem
    }
}

.coming-soon .coming-soon-img img {
    max-width: 100%
}

.coming-soon .coming-soon-content {
    position: relative;
    text-align: center;
    max-width: 32.5rem;
    margin: 0 auto;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.coming-soon .is-countdown {
    background: 0 0;
    border: none;
    margin: 0;
    padding: 0
}

.coming-soon .is-countdown:after,
.coming-soon .is-countdown:before {
    content: '';
    display: table;
    clear: both
}

.coming-soon .is-countdown .countdown-row {
    display: block;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.coming-soon .is-countdown .countdown-show4 .countdown-section {
    width: 5rem;
    background: #dae0ec;
    padding: .625rem;
    margin: 0 .3125rem;
    -webkit-border-radius: 9px;
    border-radius: 9px
}

@media (max-width:575.98px) {
    .coming-soon .is-countdown .countdown-show4 .countdown-section {
        width: 4.5rem
    }
}

.coming-soon .is-countdown .countdown-amount {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.25
}

.coming-soon .is-countdown .countdown-period {
    font-size: .8125rem;
    color: #4d6593;
    line-height: 1.5
}

.error-page {
    min-height: 100%;
    padding: 3.75rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.error-page .error-page-content {
    margin: 0 auto;
    position: relative;
    padding: 0 .9375rem;
    text-align: center
}

.error-page .error-page-content .error-img {
    position: relative;
    width: 22.5rem;
    margin: .9375rem auto
}

@media (max-width:575.98px) {
    .error-page .error-page-content .error-img {
        width: 20rem
    }
}

.error-page .error-page-content .error-img .error-img-code {
    position: absolute;
    color: #3c4e71;
    left: 0;
    right: 0;
    font-size: 7.25rem;
    text-align: center;
    padding-right: 2.1875rem;
    line-height: 1.25
}

@media (max-width:575.98px) {
    .error-page .error-page-content .error-img .error-img-code {
        font-size: 6.375rem
    }
}

.error-page .error-page-content .error-img img {
    max-width: 100%;
    display: block
}

.error-page .error-page-content .error-page-icon i {
    font-size: 8rem;
    color: #c9d2e3
}

.error-page .error-page-content .h1,
.error-page .error-page-content h1 {
    font-size: 4rem;
    font-weight: 700
}

@media (max-width:575.98px) {

    .error-page .error-page-content .h1,
    .error-page .error-page-content h1 {
        font-size: 3.25rem
    }
}

.error-page .error-page-content .h3,
.error-page .error-page-content h3 {
    font-size: 1.125rem;
    font-weight: 400
}

@media (max-width:575.98px) {

    .error-page .error-page-content .h3,
    .error-page .error-page-content h3 {
        font-size: 1rem
    }
}

.error-page .error-page-content .link-divider {
    width: .25rem;
    height: .25rem;
    display: inline-block;
    background: #c9d2e3;
    position: relative;
    top: -.125rem;
    margin: 0 .625rem;
    border-radius: .25rem
}

.gallery-menu-container {
    min-width: 13.75rem
}

@media (max-width:767.98px) {
    .gallery-menu {
        overflow: scroll;
        border-bottom: 1px solid #c9d2e3;
        position: sticky;
        top: 60px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}

.gallery-menu .gallery-menu-header {
    padding: .9375rem .9375rem .625rem;
    font-weight: 500;
    font-size: .8125rem
}

@media (max-width:767.98px) {
    .gallery-menu .gallery-menu-header {
        display: none
    }
}

.gallery-menu .gallery-menu-item .gallery-menu-link {
    padding: .3125rem .9375rem;
    display: block;
    color: #4d6593;
    text-decoration: none
}

@media (max-width:767.98px) {
    .gallery-menu .gallery-menu-item .gallery-menu-link {
        white-space: nowrap;
        padding: .9375rem 1.25rem .625rem
    }
}

.gallery-menu .gallery-menu-item .gallery-menu-link .fa {
    color: #869ac0
}

@media (max-width:767.98px) {
    .gallery-menu .gallery-menu-item .gallery-menu-link .fa {
        display: block;
        margin: 0 0 .1875rem !important;
        width: auto !important;
        font-size: 1.125rem
    }
}

.gallery-menu .gallery-menu-item .gallery-menu-link:hover {
    background: #dae0ec;
    color: #3c4e71
}

.gallery-menu .gallery-menu-item .gallery-menu-link.active {
    background-color: #1f6bff;
    color: #fff
}

.gallery-menu .gallery-menu-item .gallery-menu-link.active .fa {
    color: inherit
}

.gallery-content-container {
    width: 100%
}

@media (min-width:768px) {
    .gallery-content-container {
        border-left: 1px solid #dae0ec
    }
}

.gallery {
    padding: 1.25rem
}

.gallery+.gallery {
    border-top: 1px solid #dae0ec
}

.gallery .gallery-title {
    font-size: 1rem;
    color: #212837;
    font-weight: 500;
    margin-bottom: .9375rem
}

.gallery .gallery-title a {
    color: #212837;
    text-decoration: none
}

.gallery .gallery-title a i {
    font-size: .875rem;
    color: #4d6593;
    margin-left: .3125rem
}

.gallery .gallery-title .small,
.gallery .gallery-title small {
    display: block;
    color: #4d6593;
    font-size: .8125rem
}

.gallery .gallery-image-list {
    list-style-type: none;
    margin: -.125rem;
    padding: 0
}

.gallery .gallery-image-list:after,
.gallery .gallery-image-list:before {
    content: '';
    display: table;
    clear: both
}

.gallery .gallery-image-list>li {
    float: left;
    position: relative;
    padding-top: 12.5%;
    width: 16.6667%
}

@media (max-width:991.98px) {
    .gallery .gallery-image-list>li {
        width: 25%;
        padding-top: 18.75%
    }
}

@media (max-width:767.98px) {
    .gallery .gallery-image-list>li {
        width: 33.33%;
        padding-top: 33.33%
    }
}

@media (max-width:575.98px) {
    .gallery .gallery-image-list>li {
        width: 50%;
        padding-top: 50%
    }
}

.gallery .gallery-image-list>li:hover {
    z-index: 10
}

.gallery .gallery-image-list>li a {
    position: absolute;
    left: .125rem;
    right: .125rem;
    bottom: .125rem;
    top: .125rem;
    overflow: hidden;
    background: #212837;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (min-width:992px) {
    .gallery .gallery-image-list>li a:hover {
        left: -20%;
        right: -20%;
        top: -20%;
        bottom: -20%
    }
}

.gallery .gallery-image-list>li .img,
.gallery .gallery-image-list>li img {
    max-width: 100%
}

.gallery .gallery-image-list>li .img-portrait {
    height: 100%;
    width: auto;
    max-height: 100%;
    max-width: inherit
}

@media (max-width:767.98px) {
    .gallery .gallery-image-list>li .img-portrait-xs {
        height: 100%;
        width: auto;
        max-height: 100%;
        max-width: inherit
    }
}

.login {
    min-height: 100vh;
    padding: 3.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width:767.98px) {
    .login {
        padding: 1.25rem
    }
}

.login .login-content {
    max-width: 22.5rem;
    margin: 0 auto;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.mailbox {
    background: #fff;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.mailbox .mailbox-toolbar {
    padding: .625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media (max-width:991.98px) {
    .mailbox .mailbox-toolbar {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        background: #fff;
        border-bottom: 1px solid #dae0ec;
        z-index: 10;
        overflow: scroll;
        white-space: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}

.mailbox .mailbox-toolbar .mailbox-toolbar-text {
    color: #869ac0;
    padding: .3125rem
}

.mailbox .mailbox-toolbar .mailbox-toolbar-link {
    padding: .3125rem .625rem
}

.mailbox .mailbox-toolbar .mailbox-toolbar-item {
    padding: .3125rem
}

.mailbox .mailbox-toolbar .mailbox-toolbar-link {
    color: #212837;
    text-decoration: none;
    -webkit-border-radius: 6px;
    border-radius: 6px
}

.mailbox .mailbox-toolbar .mailbox-toolbar-link:hover {
    background: #ebeef4
}

.mailbox .mailbox-toolbar .mailbox-toolbar-link.active {
    background: #1f6bff;
    color: #fff
}

.mailbox .mailbox-body {
    border-top: 1px solid #dae0ec;
    overflow: hidden;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media (max-width:991.98px) {
    .mailbox .mailbox-body {
        padding-top: 3.125rem
    }
}

.mailbox .mailbox-body .mailbox-sidebar {
    max-width: 22.5rem;
    height: 100%
}

@media (max-width:991.98px) {
    .mailbox .mailbox-body .mailbox-sidebar {
        max-width: 100%;
        height: auto
    }
}

.mailbox .mailbox-body .mailbox-sidebar+.mailbox-content {
    border-left: 1px solid #dae0ec
}

@media (max-width:991.98px) {
    .mailbox .mailbox-body .mailbox-sidebar+.mailbox-content {
        border-left: none
    }
}

.mailbox .mailbox-body .mailbox-content {
    height: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.mailbox .mailbox-empty-message {
    min-height: 100%;
    width: 100%;
    padding: 1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center
}

.mailbox .mailbox-empty-message .mailbox-empty-message-img {
    width: 100%;
    text-align: center;
    margin-bottom: .9375rem
}

.mailbox .mailbox-empty-message .mailbox-empty-message-img img {
    width: 100%;
    height: 100%;
    max-width: 30rem;
    max-height: 30rem;
    opacity: .5
}

.mailbox .mailbox-empty-message .mailbox-empty-message-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #c9d2e3
}

.mailbox .mailbox-list {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.mailbox .mailbox-list .mailbox-list-item {
    padding: .9375rem 1.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.mailbox .mailbox-list .mailbox-list-item+.mailbox-list-item {
    border-top: 1px solid #dae0ec
}

.mailbox .mailbox-list .mailbox-list-item:hover {
    background-color: #f6f7fa
}

.mailbox .mailbox-list .mailbox-list-item.unread .mailbox-message .mailbox-title {
    font-weight: 600;
    color: #212837
}

.mailbox .mailbox-list .mailbox-list-item.active {
    background: #f5f9ff
}

.mailbox .mailbox-list .mailbox-list-item.has-attachment .mailbox-checkbox:after {
    content: '\f0c1';
    position: absolute;
    top: 1.5625rem;
    left: 0;
    text-align: center;
    color: #869ac0;
    width: 1.125rem;
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto
}

.mailbox .mailbox-list .mailbox-checkbox {
    position: relative
}

.mailbox .mailbox-list .mailbox-checkbox+.mailbox-message {
    padding-left: .3125rem
}

.mailbox .mailbox-list .mailbox-message {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.mailbox .mailbox-list .mailbox-message .mailbox-list-item-link {
    display: block;
    color: #212837;
    text-decoration: none;
    position: relative
}

.mailbox .mailbox-list .mailbox-message .mailbox-sender {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.mailbox .mailbox-list .mailbox-message .mailbox-sender .mailbox-sender-name {
    margin-right: auto;
    font-weight: 600;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.mailbox .mailbox-list .mailbox-message .mailbox-sender .mailbox-time {
    padding-left: .3125rem;
    font-size: .75rem;
    color: #657eae;
    font-weight: 500
}

.mailbox .mailbox-list .mailbox-message .mailbox-title {
    color: #3c4e71;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 18.125rem
}

.mailbox .mailbox-list .mailbox-message .mailbox-desc {
    position: relative;
    font-size: .8125rem;
    color: #869ac0;
    max-height: 2.625rem;
    overflow: hidden
}

.mailbox .mailbox-detail {
    padding: 1.875rem
}

@media (max-width:991.98px) {
    .mailbox .mailbox-detail {
        padding: 1.25rem
    }
}

.mailbox .mailbox-detail .mailbox-detail-header {
    border-bottom: 1px solid #dae0ec;
    padding: 0 0 1.25rem
}

.mailbox .mailbox-detail .mailbox-detail-content {
    padding: 1.25rem 0
}

.mailbox .mailbox-detail .mailbox-detail-body {
    font-size: .875rem
}

.mailbox .mailbox-detail .mailbox-detail-attachment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment {
    background: #dae0ec;
    width: 7.5rem;
    overflow: hidden;
    margin-right: .625rem;
    margin-bottom: .625rem;
    -webkit-border-radius: 6px;
    border-radius: 6px
}

.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment a {
    display: block;
    text-decoration: none
}

.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment .document-file {
    height: 3.75rem;
    overflow: hidden;
    text-align: center;
    background: #ebeef4
}

.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment .document-file i {
    display: block;
    line-height: 3.75rem;
    font-size: 2rem;
    color: #869ac0
}

.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment .document-file img {
    max-width: 100%
}

.mailbox .mailbox-detail .mailbox-detail-attachment .mailbox-attachment .document-name {
    color: #3c4e71;
    padding: .125rem .625rem;
    font-size: .8125rem;
    font-weight: 500;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center
}

.mailbox .mailbox-form {
    background: #f6f7fa
}

.mailbox .mailbox-form .mailbox-form-header {
    padding: 1.25rem
}

.mailbox .mailbox-form .note-editor {
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0
}

.mailbox .mailbox-form .note-editor .note-toolbar {
    border-top: 1px solid #dae0ec;
    border-bottom: 1px solid #dae0ec
}

.mailbox .mailbox-form .note-editor .note-editing-area {
    background: #fff
}

.profile .profile-header {
    position: relative
}

.profile .profile-header .profile-header-cover {
    background: url(images/profile-cover.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 10.625rem;
    position: relative
}

@media (max-width:767.98px) {
    .profile .profile-header .profile-header-cover {
        height: 6.25rem
    }
}

.profile .profile-header .profile-header-cover:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(33, 40, 55, .35)
}

.profile .profile-header .profile-header-content {
    position: relative;
    padding: 0 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: flex-end;
    align-items: flex-end
}

@media (max-width:767.98px) {
    .profile .profile-header .profile-header-content {
        display: block;
        padding: 0 20px
    }
}

.profile .profile-header .profile-header-content .profile-header-img {
    width: 12.5rem;
    height: 12.5rem;
    overflow: hidden;
    z-index: 10;
    margin-top: -8.75rem;
    padding: .1875rem;
    background: #fff;
    -webkit-border-radius: 9px;
    border-radius: 9px
}

@media (max-width:767.98px) {
    .profile .profile-header .profile-header-content .profile-header-img {
        width: 4.375rem;
        height: 4.375rem;
        margin: -3.75rem 0 0
    }
}

.profile .profile-header .profile-header-content .profile-header-img img {
    max-width: 100%;
    width: 100%;
    -webkit-border-radius: 6px;
    border-radius: 6px
}

.profile .profile-header .profile-header-content .profile-header-tab {
    position: relative;
    margin-left: 50px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

@media (max-width:767.98px) {
    .profile .profile-header .profile-header-content .profile-header-tab {
        margin: -.625rem -20px 0;
        padding: 0 20px;
        overflow: scroll;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}

.profile .profile-header .profile-header-content .profile-header-tab .nav-item .nav-link {
    padding: .8125rem .625rem .5625rem;
    text-align: center
}

@media (max-width:767.98px) {
    .profile .profile-header .profile-header-content .profile-header-tab .nav-item .nav-link {
        padding: .9375rem .625rem .3125rem
    }
}

.profile .profile-header .profile-header-content .profile-header-tab .nav-item .nav-link .nav-field {
    font-weight: 600;
    font-size: .8125rem
}

@media (max-width:767.98px) {
    .profile .profile-header .profile-header-content .profile-header-tab .nav-item .nav-link .nav-field {
        font-size: .6875rem;
        margin-bottom: -.125rem
    }
}

.profile .profile-header .profile-header-content .profile-header-tab .nav-item .nav-link .nav-value {
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: -.5px
}

@media (max-width:767.98px) {
    .profile .profile-header .profile-header-content .profile-header-tab .nav-item .nav-link .nav-value {
        font-size: 1.125rem
    }
}

.profile .profile-header .profile-header-content .profile-header-tab .nav-item .nav-link.active {
    color: #212837;
    border-color: #212837
}

@media (max-width:767.98px) {
    .profile .profile-header .profile-header-content .profile-header-tab .nav-item .nav-link.active {
        background: 0 0
    }
}

.profile .profile-header .profile-header-content .profile-header-tab .nav-item+.nav-item {
    margin-left: .9375rem
}

.profile .profile-container {
    padding: 30px 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media (max-width:767.98px) {
    .profile .profile-container {
        padding: 20px 20px
    }
}

.profile .profile-container .profile-sidebar {
    width: 13.75rem
}

@media (max-width:767.98px) {
    .profile .profile-container .profile-sidebar {
        display: none
    }
}

.profile .profile-container .profile-content {
    padding-left: 30px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

@media (max-width:767.98px) {
    .profile .profile-container .profile-content {
        padding-left: 0
    }
}

.profile .profile-img-list {
    list-style-type: none;
    margin: -.0625rem -1.0625rem;
    padding: 0
}

.profile .profile-img-list:after,
.profile .profile-img-list:before {
    content: '';
    display: table;
    clear: both
}

.profile .profile-img-list .profile-img-list-item {
    float: left;
    width: 25%;
    padding: .0625rem
}

.profile .profile-img-list .profile-img-list-item.main {
    width: 50%
}

.profile .profile-img-list .profile-img-list-item .profile-img-list-link {
    display: block;
    padding-top: 75%;
    overflow: hidden;
    position: relative
}

.profile .profile-img-list .profile-img-list-item .profile-img-list-link .profile-img-content,
.profile .profile-img-list .profile-img-list-item .profile-img-list-link img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.profile .profile-img-list .profile-img-list-item .profile-img-list-link .profile-img-content:before,
.profile .profile-img-list .profile-img-list-item .profile-img-list-link img:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgba(60, 78, 113, .15)
}

.profile .profile-img-list .profile-img-list-item.with-number .profile-img-number {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    color: #fff;
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1.625rem;
    margin-top: -.8125rem;
    text-align: center
}

.register {
    min-height: 100%;
    padding: 3.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width:767.98px) {
    .register {
        padding: 1.25rem
    }
}

.register .register-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 22.5rem;
    margin: 0 auto;
    position: relative
}

.search-result .search-input {
    position: relative;
    max-width: 45rem;
    margin-bottom: .9375rem
}

.search-result .search-close {
    position: absolute;
    right: .75rem;
    top: 50%;
    margin-top: -.75rem;
    width: 1.625rem;
    height: 1.625rem;
    text-align: center;
    font-size: 1.25rem;
    padding: 0 0 .125rem;
    color: #fff;
    background: #c9d2e3;
    text-decoration: none;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.search-result .search-close:focus,
.search-result .search-close:hover {
    text-decoration: none;
    background: #869ac0;
    color: #fff
}

.search-result .search-tab {
    list-style-type: none;
    margin: 0 -50px;
    padding: 0 50px;
    border-bottom: 1px solid #c9d2e3;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media (max-width:767.98px) {
    .search-result .search-tab {
        margin: 0 -.9375rem;
        padding: 0 .9375rem;
        overflow: scroll
    }
}

.search-result .search-tab .search-tab-item .search-tab-link {
    display: block;
    padding: .625rem;
    color: #212837;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    font-weight: 500;
    margin-bottom: -1px
}

.search-result .search-tab .search-tab-item .search-tab-link:focus,
.search-result .search-tab .search-tab-item .search-tab-link:hover {
    border-bottom-color: #869ac0
}

.search-result .search-tab .search-tab-item .search-tab-link.active {
    font-weight: 600;
    color: #1f6bff;
    border-bottom-color: #1f6bff
}

.search-result .search-tab .search-tab-item+.search-tab-item {
    margin-left: .625rem
}

.search-result .search-result-list {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.search-result .search-result-list .search-result-item {
    padding: 1.875rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.search-result .search-result-list .search-result-item:after,
.search-result .search-result-list .search-result-item:before {
    content: '';
    display: table;
    clear: both
}

.search-result .search-result-list .search-result-item:last-child {
    border-bottom: 1px solid #c9d2e3
}

.search-result .search-result-list .search-result-item+.search-result-item {
    border-top: 1px solid #c9d2e3
}

.search-result .search-result-list .search-result-item .search-result-media {
    width: 10rem;
    height: 10rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (max-width:767.98px) {
    .search-result .search-result-list .search-result-item .search-result-media {
        width: 5rem;
        height: 3.75rem
    }
}

.search-result .search-result-list .search-result-item .search-result-media img {
    max-width: 100%;
    max-height: 100%
}

.search-result .search-result-list .search-result-item .search-result-media+.search-result-content {
    padding-left: 1.875rem
}

.search-result .search-result-list .search-result-item .search-result-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.search-result .search-result-list .search-result-item .search-result-content .h3,
.search-result .search-result-list .search-result-item .search-result-content h3 {
    margin: 0 0 .3125rem;
    font-size: 1.25rem
}

.search-result .search-result-list .search-result-item .search-result-content .h3 a,
.search-result .search-result-list .search-result-item .search-result-content h3 a {
    color: #212837;
    text-decoration: none
}

.search-result .search-result-list .search-result-item .search-result-content .h3 a:hover,
.search-result .search-result-list .search-result-item .search-result-content h3 a:hover {
    text-decoration: underline
}

.setting-list {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.setting-list>li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #fff
}

@media (max-width:767.98px) {
    .setting-list>li {
        display: block
    }
}

.setting-list>li+li {
    border-top: 1px solid #d9d9d9
}

.setting-list>li.setting-title {
    background: #f2f2f2;
    color: #000;
    padding: .5rem .9375rem;
    font-weight: 700;
    font-size: .75rem;
    position: relative
}

.setting-list>li .action,
.setting-list>li .field,
.setting-list>li .value {
    padding: .9375rem .9375rem
}

.setting-list>li .action+div,
.setting-list>li .field+div,
.setting-list>li .value+div {
    border-left: 1px solid #d9d9d9
}

@media (max-width:767.98px) {

    .setting-list>li .action+div,
    .setting-list>li .field+div,
    .setting-list>li .value+div {
        border-left: none;
        padding-top: 0
    }
}

.setting-list>li .value {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 14px;
    font-weight: 600
}

.setting-list>li .field {
    width: 12.5rem;
    font-size: 14px
}

@media (max-width:767.98px) {
    .setting-list>li .field {
        width: auto;
        text-decoration: underline
    }
}

.widget-chat {
    margin: 0;
    padding: 0
}

.widget-chat .widget-chat-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: flex-end;
    align-items: flex-end
}

.widget-chat .widget-chat-item+.widget-chat-item {
    margin-top: .9375rem
}

.widget-chat .widget-chat-item>div+div {
    padding-left: .625rem
}

.widget-chat .widget-chat-date {
    font-size: .75rem;
    font-weight: 600;
    color: #212837;
    padding: .625rem .625rem;
    text-align: center
}

.widget-chat .widget-chat-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.widget-chat .widget-chat-name,
.widget-chat .widget-chat-status {
    font-size: .6875rem;
    font-weight: 500;
    color: #657eae;
    line-height: 1.25rem
}

.widget-chat .widget-chat-media {
    width: 1.75rem
}

.widget-chat .widget-chat-media img {
    max-width: 100%;
    display: block;
    -webkit-border-radius: 28px;
    border-radius: 28px
}

.widget-chat .widget-chat-message {
    background: #fff;
    padding: .5rem .9375rem;
    display: inline-block;
    position: relative;
    border-radius: 1.25rem
}

.widget-chat .widget-chat-message+.widget-chat-message {
    margin-top: .3125rem
}

.widget-chat .widget-chat-message.last:before {
    content: '';
    border: .3125rem solid transparent;
    border-bottom-color: #fff;
    border-right-color: #fff;
    position: absolute;
    left: -.25rem;
    bottom: .375rem
}

.widget-chat .reply .widget-chat-content {
    text-align: right
}

.widget-chat .reply .widget-chat-message {
    background: #1f6bff;
    color: #fff
}

.widget-chat .reply .widget-chat-message.last:before {
    border-color: transparent;
    border-bottom-color: #1f6bff;
    border-left-color: #1f6bff;
    left: auto;
    right: -.25rem
}

.widget-img-list {
    margin: -.0625rem;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.widget-img-list .widget-img-list-item {
    padding: .0625rem;
    width: 20%
}

.widget-img-list .widget-img-list-item a {
    display: block;
    text-decoration: none;
    padding-top: 100%;
    position: relative;
    overflow: hidden
}

.widget-img-list .widget-img-list-item a .img,
.widget-img-list .widget-img-list-item a img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%
}

.widget-img-list .widget-img-list-item a .img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.widget-reminder .widget-reminder-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.widget-reminder .widget-reminder-header+.widget-reminder-item,
.widget-reminder .widget-reminder-item+.widget-reminder-header,
.widget-reminder .widget-reminder-item+.widget-reminder-item {
    border-top: 1px solid #dae0ec
}

.widget-reminder .widget-reminder-content,
.widget-reminder .widget-reminder-time {
    padding: .9375rem
}

.widget-reminder .widget-reminder-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.widget-reminder .widget-reminder-time {
    width: 5rem;
    text-align: right;
    font-size: .8125rem
}

.widget-reminder .widget-reminder-divider {
    width: .1875rem;
    background: #dae0ec
}

.widget-reminder+.card-body,
.widget-reminder+.card-footer,
.widget-reminder+.card-header {
    border-top: 1px solid #dae0ec
}

.widget-user-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.widget-user-list .widget-user-list-item .widget-user-list-link {
    border: 2px solid #fff;
    overflow: hidden;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-border-radius: 40px;
    border-radius: 40px
}

.widget-user-list .widget-user-list-item .widget-user-list-link img {
    display: block;
    max-width: 100%;
    max-height: 100%
}

.widget-user-list .widget-user-list-item+.widget-user-list-item {
    margin-left: -1.25rem
}