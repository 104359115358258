/*** By Subbiah ***/
::-webkit-scrollbar {
    width: 0.375rem;
}

::-webkit-scrollbar-thumb {
    background-color: #00000040;
}

body {
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    ;
    font-weight: 500;
}

.app-header .menu-item .dropdown-toggle::after {
    display: none;
}

.app-header .menu .menu-item .menu-link {
    padding: 10px;
}

.app-header .menu-item .menu-text {
    color: #000;
}

.app-header .menu-item .dropdown>a {
    padding: 0px;
}

.app-header .dropdown-menu {
    margin-top: 15px;
}

.app-header .dropdown-menu .dropdown-item {
    padding: 3px 10px;
    font-size: 13px;
}

.app-header .dropdown-divider {
    margin: 3px 0;
}

.app-header .nav-left .dropstart .dropdown-menu {
    top: 56px;
    right: 10%;
}

.app-header .nav-left .dropstart .dropdown-menu:before {
    left: auto;
    right: 12px;
}

.app-header {
    height: 45px;
    /* left: 49px; */
    box-shadow: none;
    border-bottom: 1px solid #dee2e6;
}

.app-header .menu {
    margin-top: -7px;
}

.app-header .brand {
    width: auto;
    height: 0px;
    padding: 0 20px;
    border-right: 1px solid #efefef;
}

.app-header .brand .brand-logo {
    font-weight: 700;
    color: #212837;
    font-size: 20px;
    text-decoration: none;
    height: 0;
    width: 100%;
    padding: 0 0;
    display: flex;
    align-items: center;
    font-size: 17px;
}

.app-header .dropdown-menu:before {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    left: 10px;
    border-bottom: 11px solid #fff;
    top: -11px;
}

.app-header a.top-nav-active {
    background: linear-gradient(357deg, #d9d9d9, #f7f3f399);
    border-radius: 3px;
}

.app-content {
    padding: 5px 20px;
}

.page-headers {
    position: sticky;
    top: 55px;
    z-index: 1;
    background-color: #ebeef4;
    padding: 5px 0 10px 0;
}

.no-shadow {
    box-shadow: none;
}

/*** By Subbiah ***/


.modal-frame-top {
    position: absolute;
    max-width: 100%;
    width: 100%;
    margin: 0;
}

.modal-frame-top .modal-content {
    border-radius: 0;
}

.container {
    background-color: white;
}

.modal-title {
    font-size: 15px;
}

.modal-header {
    padding: 10px 15px;
}

.modal-content {
    border-radius: 3px;
}

.navbar {
    box-shadow: 0 0px 1px 0 rgb(0 0 0 / 50%), 0 1px 0px rgb(0 0 0 / 5%);
}

.container {
    max-width: 90%;
}

.navbar>.container {
    max-width: 100%;
}


.card-fixed-top {
    position: sticky;
    top: 45px;
    z-index: 9;
    border-top: 1px solid #dfecf9;
    /*box-shadow: 0 5px 5px rgb(143 164 185 / 3%);*/
    background-color: #fcfcfc;
    box-shadow: 0px 0px 20px 3px #dadada;
}

.card-fixed-top>.card-body {
    padding: 10px 10px;
}

.card-fixed-top .btn {
    text-transform: capitalize
}

.control-label {
    font-size: 12px;
}

.check-red.checked {
    background-color: #fd0d0d;
    border-color: #d80a0a;
}